@font-face {
    font-family: 'SF Pro';
    src: url('https://dev.junomoney.org/images/junoclient/SF-Pro.ttf')
        format('truetype');
}

@font-face {
    font-family: 'Stagnan';
    src: url('https://dev.junomoney.org/images/landingpage/Stagnan-Regular.otf') format("opentype");
}

:root {
    // --theme-color: #29e8c6;
    // --btn-background-hover: #29e8c6;
    --theme-color: #18181b;
    --btn-background-hover: #3f3f46;
    --stagnanFont: 'Stagnan', sans-serif;
    --opensansFont: 'Open Sans', sans-serif;

}

.juno-client-theme {
    --textFont: 'Inter', sans-serif;
    --titleFont: 'SF Pro', sans-serif;
    --modal-body-background: #fff;
    --icon-color: #667085;
    --input-background: #f2f4f7;
    --input-border-color: #eaecf0;
    --input-placeholder-color: #344054;
    --modal-text-color: #344054;
    --modal-light-text-color: #667085;
    --input-border-radius: 25px;
    --input-font-size: 14px;
    --modal-title-color: #171717;
    --btn-background: #6942e2; //todo:kris delete

    .MuiLinearProgress-barColorPrimary {
        background: var(--theme-color);
    }

    .MuiLinearProgress-colorPrimary {
        background-color: rgb(235, 235, 235);
    }
}

//14.16.0
//3.1.13
//todo:delete
//JunoClientTabs
//JunoClientAccountLayout
//JunoClientNav
//.jnA-account-password-msg
//.jncModal__btnDivider

//todo: change class name theme-modal-field-input

//todo: move to keyframes file
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.max-991 {
    @media screen and (min-width: 992px) {
        display: none !important;
    }
}

.min-992 {
    @media screen and (max-width: 991px) {
        display: none !important;
    }
}

.max-767 {
    @media screen and (min-width: 768px) {
        display: none !important;
    }
}

.min-768 {
    @media screen and (max-width: 767px) {
        display: none !important;
    }
}

.min-516 {
    @media screen and (max-width: 515px) {
        display: none !important;
    }
}

.max-515 {
    @media screen and (min-width: 516px) {
        display: none !important;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.no-wrap {
    white-space: nowrap;
}

.w-100 {
    width: 100%;
}
