@import './_mixins.scss';

.faq_container{
    overflow-x: hidden;
    .faqs{
        padding-top: 90px;
        .faq_heading{
            height: 96px;
            @include flexBoxSpaceBetween;
            background-color: #171723;        
            h1{
                color: #{$mainTextColor};
                font-size: 32px;
                font-weight: bold;
                letter-spacing: 0.08px;
                line-height: 35px;
                margin:0 auto;
            }
        }
        .faq_content{    
            min-height: 100vh;
            margin-left: auto;
            margin-right: auto;
            background-color: #FFFFFF;
            .content_title{
                padding: 37px 0px;
                color: #000000;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0.06px;
                line-height: 26px;
            }
            
            .content_card_container{
                overflow-x: hidden;
                padding-bottom: 25px;
                @include flexBox;;
                color: #000000;
                cursor: pointer;
                .content_card{
                    box-sizing: border-box;
                    height: 161px;
                    width: 22vw;
                    border-radius: 4px;
                    background-color: rgba(216,216,216,0.18);
                    .card_img{
                        padding: 25px  0px 19px 25px;
                        img{
                            height: 45px;
                            width: 55px;
                        }
                    }
                    .card_heading{
                        padding-left: 25px;
                        font-size: 17px;
                        font-weight: 600;
                        letter-spacing: 0.04px;
                        line-height: 18px;
                        padding-bottom: 10px;
                    }
                    .card_para{
                        padding-left: 25px;
                        font-size: 14px;
                        letter-spacing: 0.04px;
                        line-height: 19px;                        
                    }
                }
                .content_card:hover{
                    border: 1px solid #009CFF;
                }
                .content_card:nth-child(2){
                    margin: 0 46px;
                }
            }

            .faq_question_section{
                padding-bottom: 100px;
                overflow-x: hidden;

                .faq_question{
                    @include flexBoxSpaceBetween;
                    border-bottom: 1px solid rgba(0,0,0,0.18);
                    padding: 10px;
                    .question{
                        color: #000000;
                        font-size: 18px;
                        letter-spacing: 0.05px;
                        line-height: 24px;
                    }
                    .right_arrow{
                        font-size: 22px;
                        color: #000000;
                    }
                }
            }
        }
    }
}

.faq_content_container{
    margin: 0 auto;
    width: 940px;
}

@media only screen and (max-width: 820px) {
    .faq_container{
        .faqs{
            .faq_content{         
                background-color: #FFFFFF;

                .content_card_container{
                    .content_card{
                        height: 180px;
                        width: 33%;
                        .card_img{
                            padding: 25px  0px 19px 25px;
                            img{
                                height: 45px;
                                width: 55px;
                            }
                        }
                        .card_heading{
                            font-size: 15px;
                            line-height: 17px;
                        }
                        .card_para{
                            line-height: 16px;                        
                        }
                    }
                    .content_card:nth-child(2){
                        margin: 0 10px;
                    }

                }
    
                .faq_question_section{
                    .faq_question{
                        .question{
                            font-size: 14px;
                            letter-spacing: 0.04px;
                            line-height: 16px;
                        }
                        .right_arrow{
                            font-size: 22px;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
    .faq_content_container{
        padding:0px 10px;
        width: 730px;
    }
}

@media screen and (max-device-width: 480px){
    .faq_container{
        .faqs{
            padding-top: 70px;
            .faq_heading{
                height: 52px;
                h1{
                    font-size: 19px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                }
            }
            .faq_content{
                .content_title{
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 0.04px;
                    line-height: 17px;
                    padding: 10px 0px;
                }     
                .content_card_container{
                    flex-direction: column;
                    margin: 0;
                    padding-bottom: 0px;
                    .content_card{
                        @include flexBox;
                        height: 101px;
                        width: 100%;
                          margin:10px 0px;
                        .card_img{
                            padding: 25px  0px 19px 25px;
                            img{
                                height: 45px;
                                width: 55px;
                            }
                        }
                        .card_heading{
                            font-size: 15px;
                            line-height: 17px;
                        }
                        .card_para{
                            line-height: 16px;                        
                        }
                    }
                    .content_card:nth-child(2){
                        // margin:10px 0px;
                    }

                }
    
                .faq_question_section{
                    .faq_question{
                        .question{
                            font-size: 14px;
                            letter-spacing: 0.04px;
                            line-height: 16px;
                        }
                        .right_arrow{
                            font-size: 22px;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
    .faq_content_container{
        padding:0px 10px;
        margin: 0;
        width: 100%;
    }
}

