@import './_mixins.scss';

.b2c-settings {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
  min-height: 100vh;
  background-color: var(--wallets-history-details-bottomBackground);
  .b2c-settings-header{
    width: 262px;
    color: var(--content-text-color);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05px;
    line-height: 27px;
    padding-top: 100px;
  }
  .b2c-settings-header2{
    color: var(--content-text-color);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05px;
    line-height: 27px;
    padding: 45px;
    padding-left: unset;
  }
  .b2c-settings-header3{
    color: var(--content-text-color);
    font-size: 15px;
    letter-spacing: 0.04px;
    line-height: 20px;
  }

  .setting-input-Container{
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }

  .settings-input-label{
    width: 100%;
    color: var(--content-text-color);
    font-size: 15px;
    letter-spacing: 0.04px;
    line-height: 20px;
  }

  .settings-input{
    @include flexBoxSpaceBetween;
    min-width: 300px;
    box-sizing: border-box;
    height: 51px;
    width: 402px;
    border: 1px solid var(--profile-menu-bg-color);
    border-radius: 25px;
    color: var(--content-text-color);
    background-color: var(--signup-model-bg);
    padding:20px
  }

  .settings-input::placeholder {
    font-size: 15px;
  }

  .settings-table{
    width: 50%;
    table, th, td {
      border: 1px solid rgba(0,0,0,0.1);
      border-collapse: collapse;
      box-sizing: border-box;
      border-radius: 4px;
    }
    th{
      background-color: var(--balance-table-odd-row);
      color: var(--settings-table-header);
      font-size: 13px;
      padding: 15px 15px 15px 30px;
    }
    td{
      background-color: var(--signup-model-bg);
      color: var(--content-text-color);
      font-size: 14px;
      padding: 15px 15px 15px 30px;
    }

  }
  .container2{
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }
  .change{
    top: 14px;
    right: 35px;
  }
  
}

.change-password-heading{
   height: 30px;
  width: 188px;
  color: #{$mainTextColor};
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
}

.change-Password-input-label{
  width: 100%;
  color: var(--content-text-color);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.change-password-input{
  box-sizing: border-box;
  height: 51px;
  width: 100%;
  border-radius: 25px;
  color: var(--content-text-color);
  background-color: var(--change-password-input);
  padding:20px;
  border: 1px solid var(--change-password-input-border);
}

.change-password-input::placeholder {
  color: #808080;
  font-size: 15px;
}

.change-password-modal-header{
  width: 463px;
  padding: 1rem 2rem;
  overflow-y: hidden;
  background-color: #111117;
}

.change-password-disabled-button{
    border-radius: 30px;
    background-color: #828282;
    width: 100%;
    height: 50px;
    color: #{$mainTextColor};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    border: 0px;
}

.change-password-enabled-button{
  border-radius: 30px;
  background-color: #{$mainColor};
  width: 100%;
  height: 50px;
  color: #{$mainTextColor};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  border: 0px;
}

.visibility-icon{
  position: absolute;
  top: 242px;
  color: #808080;
  right: 44px;
}

.email-sent-text1{
  color: var(--signup-text-color);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
.email-sent-text2{
  color: var(--signup-text-color);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.icon-close2{
  color: #{$mainTextColor};
}

.left-margin {
  margin-left:76px;
}

@media only screen and (min-width: 350px) and (max-width: 767px) {
 
  .b2c-settings{
    .left-margin {
      margin:20px;
    }
    .setting-input-Container{
      border-bottom: none;
      display: block;
    }
    .single-input{
      display: block !important;
      max-width:none !important;
    }
    .row{
      display: block !important;
    }
    .settings-input{
     width: 85vw;
     height: 50px;
     margin-top: 10px; 
    }
    .b2c-settings-header{
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.05px;
      line-height: 24px;
      padding-top: 75px;
    }
    .settings-input-label{
      max-width: none;
    }
    .b2c-settings-header2 {
      padding: 30px;
    }
    
    .settings-table{
      width: auto;
    }
  }

  .change-password-modal-header{
    width: auto;
  }
  .mobile-height{
    height: 100vh;
  }
}