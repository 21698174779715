.jncRepeatBtn {
	width: 44px;
	height: 44px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	rect {
		transition: .15s;
	}
	&:hover {
		rect {
			fill: var(--theme-color);
		}
		svg path {
            fill: white;
        }
	}
}

.jncRepeatModal {
	&__label {
		margin-bottom: 4px;
		color: #667085;
	}
	&__field {
		font-size: 16px;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	&__text {
		color: #344054;
		font-weight: 600;
	}
	.alert {
		margin-top: 15px !important;
	}
}