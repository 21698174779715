*, p {
	margin: 0;
	padding: 0;
}

button {
	border: 0;
	background: transparent;
	&:focus {
		outline: none;
	}
}

input, textarea {
	&:focus {
		outline: none;
	}
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.cursor-pointer {
	cursor: pointer;
}
