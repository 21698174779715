@import './_mixins.scss';

.enable-authenticator-header {
  @include flexBoxSpaceBetween;
  background-color: #111117;
  color: #ffff;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  padding: 1rem 1.5rem;
}
.enable-authenticator-content {
  background-color: var(--google-authenticator-bg-clr);
  max-height: 550px;
  overflow-y: auto;
  padding: 1rem 1.5rem;
}
.authenticator-scanner {
  text-align: center;
  margin: 2rem 2rem 1rem 1rem;
  margin-top: 2rem !important;
}
download-app-here,
.authenticator-text {
  color: var(--signup-text-color);
  font-size: 14px;
  letter-spacing: 1.24px;
  line-height: 25px;
}
.download-app-here {
  color: #0091ff;
}
.authenticate-alert-model {
  position: fixed;
  height: 52px;
  width: 394px;
  border-radius: 4px;
  background-color: var(--alert-success-authetucator);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--alert-success-authetucator-text-clr);
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 21px;
  padding: 10px;
  font-weight: 400;
}
.idenity-verify-model {
  background-color: var(--identity-verify-bg);
  color: var(--signup-text-color);
}
.alert-model-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.wallet_status_alert_model_icon {
  background-color: #70a72d;
  padding: 5px;
  border-radius: 100%;
}
.authenticator-close-icon {
  color: var(--alert-success-authetucator-text-clr);
  cursor: pointer;
}
.idenity-verify-model-close-icon {
  color: var(--signup-text-color);
  cursor: pointer;
}
.receive-crypto-text {
  text-align: center;
  width: 85%;
  margin: auto;
}
.receive-crypto-text-header {
  text-align: center;
  padding: 0rem 1.5rem;
}
.receive-wallet-text {
  padding-top: 10px;
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}
.forgot-password-body {
  color: var(--signup-text-color);
  font-weight: 400;
  text-align: left;
}
.forgot-password-content {
  padding: 2rem 2.5rem;
}
.forgot-password-btn {
  position: absolute;
  bottom: 0;
  width: 80%;
  margin-bottom: 23px;
}
.receive-wallet-header {
  color: var(--signup-text-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.06px;
  line-height: 30px;
}
.receive-wallet-header-address {
  color: var(--signup-text-color);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 19px;
}
.receive-wallet-amount {
  @include flexBoxJustifyCenter;
  height: 50px;
  width: 201px;
  color: #065f46;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05px;
  line-height: 27px;
  border-radius: 25px;
  background-color: var(--receive-wallet-amount-bg-clr);
  margin: auto;
}
.receive-wallet-status {
  color: var(--receive-wallet-amount-clr);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 19px;
}
.receive-wallet-container {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 12px;
  }
}
.receive-wallet-bottom {
  background-color: var(--wallets-history-details-bottomBackground);
  margin-bottom: -10px;
}
.copy-text {
  position: relative;
}
.copy-text::before {
  content: 'copied';
  position: absolute;
  top: -44px;
  right: -13px;
  background-color: #497cf9;
  padding: 4px 18px;
  color: white;
  border-radius: 32px;
  display: none;
}
.copy-text::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: -19px;
  background-color: #497cf9;
  right: 20px;
  transform: rotate(45deg);
  display: none;
}
.copy-text.active::after,
.copy-text.active::before {
  display: block;
}

.send__lable{
  padding-left: 1.5rem;
  color: var(--signup-text-color); 
  padding-top:1rem;
  padding-bottom: 0.5rem  ;
  background-color: var(--mobile-menu-BG);
}
.selection__input{
  border: none;
  outline: none;
  background: none !important;
  color: var(--signup-text-color); 
  width: 100%;
}
.selection__input::placeholder{
  color: var(--signup-text-color);
}

.select__to__address{
  max-height: 200px !important;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--signup-text-color); 
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}
.select__to__address::-webkit-scrollbar {
  width: 5px;
}
.select__to__address::-webkit-scrollbar-track {
  background: transparent;
}
.select__to__address::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #{$mainColor};
}
.select__to__address::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.you__send{
  @include flexBoxSpaceBetween;
  width: 402px;
    .you__send__left{
      @include flexBox;
      padding-left: 10px;
      color: var(--signup-text-color); 
      width: 170px;
      border-radius: 25px;
      height: 50px;
      background: none;
      border: 1px solid var(--transaction-filter-box-border);
      background-color: var(--wallets-history-details-bottomBackground);
      
      input{
        width: 145px;
        color: var(--signup-text-color);
        background-color: var(--wallets-history-details-bottomBackground);
        outline: none; 
        border: none;
      }
    }
    input[type='number'] {
      -moz-appearance:textfield;
    }  
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
  
    ::placeholder{
      color: var(--signup-text-color);
    }
}
.send__continue{
  margin: 1.5rem 0px;
  cursor: pointer;
  box-sizing: border-box;
  @include flexBoxJustifyCenter;
  border-radius: 30px;
  color: #ffff; 
  background-color: #{$mainColor};
  border: 1px solid var(--transaction-filter-box-border);
  height: 51px;
  width: 402px; 
  padding: 10px;
}
.send__continue__opacity{
  background-color: #828282 !important;
  color: #B9B9B9 !important;
}
.send__status__model{
  width: 460px;
}
.status__imgs{ 
    position: absolute;
    left: 42%;
    top: 4%;
    width: 80px;
    height: 80px;
}

.send__crypto__status {
  .css-uhb5lp {
    background-color: transparent;
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-height: none;
    background-color: transparent;
    z-index: 1500;
  }
}
.crypto__status__content{
  border-radius: 10px;
  width: auto;
}
.status__img__content{
  padding-top: 50px;
}

.status__title{
  color: var(--signup-text-color);
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center; 
}
.status__msg{
  color: var(--signup-text-color);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-left: 68px;
  margin-right: 68px;
  margin-top: 12px;
}

.status__msg__buy_crypto {
  color: var(--signup-text-color);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  width: 80%;
  margin: auto;
}
.status__msg__buy_crypto_value {
  color: var(--signup-text-color);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}
.status__button{
  @include flexBoxJustifyCenter;
  padding: 10px;
  background: var(--transaction-filter-search-background) !important;
}
.status__go_back {
  background: var(--transaction-filter-search-background) !important;
  padding-bottom: 2rem;
}


.forgot-error-msg {
  color: red;
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
  text-align: center;
}
.error__msg{
  @include flexBoxJustifyCenter;
  color: red;
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
  
}
.send-buy-model-label {
  @include flexBoxSpaceBetween;
  background-color: var(--mobile-menu-BG);
}
.buy-right-side-label {
  margin-right: 30%;
}

.buy-currency-field {
  color: var(--buy-currency-content);
}
.buy-currency-border-bottom {
  border-bottom: var(--buy-currency-border);
}
.buy-crypto-add-now-btn-hght {
  height: 40px !important;
}
.background-filter {
 backdrop-filter: blur(20px);
}
.delete-icon-clr {
  color: var(--signup-text-color);
}

.gas-fee-container {
  display: flex;
  color: white;
  margin-top: 15px;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
}

.gas-fee-child-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.send-cryp-error {
  color: red;
  font-size:18px;
  text-align: center;
}
.withdraw_gasFee{
  margin: 5px 25px;
}