@import './_mixins.scss';

.CP_footer_main {
  width: 100%;
  background: linear-gradient(to right, #0F1029 , #000000);
  box-shadow: 0 -2px 29px 0 rgba(0,0,0,0.08);
  .CP_footer_content_wrap {
    max-width: 1440px;
    min-height: 300px;
    padding: 0 10px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    @include flexBoxJustifyCenter;
    flex-direction: column;
    .CP_copyright_wrap {
      margin-top: 20px;
      text-align: center;
      width: 100%;
      border-top: 1px solid rgba(0,0,0,0.1);
      .CP_copyright_text {
        margin-top: 10px;
        color: #626262;
        font-size: 16px;
        letter-spacing: 0.04px;
        line-height: 22px;
        width: 100%;
      }
    }
      .CP_footer_logo {
        align-items: start;
      }
  }
}