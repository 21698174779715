.jncModal {
    .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
        font-family: var(--textFont);
    }
    .MuiDialogActions-root {
        padding: 16px 24px;
    }
    .custom-date-picker,
    .react-datepicker {
        width: 100%;
    }
    .react-datepicker__month-container {
        float: initial;
        width: 100%;
    }
    .react-datepicker {
        border-color: var(--input-border-color);
        border-radius: var(--input-border-radius);
    }
    .react-datepicker__header {
        background: var(--theme-color);
        color: #fff;
        font-weight: 400;
    }
    .react-datepicker__navigation *::before {
        border-color: #fff;
    }
    .react-datepicker__month .react-datepicker__month-text,
    .react-datepicker__month .react-datepicker__quarter-text {
        width: 40px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .MuiPaper-root {
        border-radius: 12px;
        max-width: 620px;
        width: 100%;
        background-color: #fff;
        margin: 10px;
    }
    .MuiDialogContent-root {
        padding: 0;
        border-top: 0;
    }
    .MuiDialogContent-root::-webkit-scrollbar {
        display: none;
    }
    
    .MuiDialogContent-root {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .juno-client-btn {
        min-width: 140px;
    }
}

.jncModal_amount_input {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 15px;

    .readOnly {
        justify-content: center;
    }
}
.jncModal {
    &.is-fullscreen {
        .MuiPaper-root {
            max-width: initial !important;
            margin: 0;
        }
    }
    &.is-delete {
        .MuiPaper-root {
            border-top: 3px solid #f04438;
        }
    }
    .theme-modal-field-input {
        box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.15);
        background: var(--input-background);
        text-align: left;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-bottom: 24px !important;
        padding-top: 24px !important;
        &-left {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 18px;
            align-items: center;
        }
        &.back-step {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 30px;
            align-items: center;
        }
    }
    &__title {
        color: var(--modal-title-color);
        font-size: 24px;
        line-height: 110%;
        padding-right: 10px;
        font-family: var(--textFont);
    }
    &__close {
        flex-shrink: 0;
        flex-grow: 0;
        margin-left: 15px;
        cursor: pointer;
        svg path {
            fill: var(--icon-color);
        }
    }
    &__body {
        padding: 24px;
    }
    &__resp {
        svg {
            width: 64px;
            height: 64px;
            margin: 0 auto 24px;
            display: block;
        }
        p {
            text-align: center;
            color: #344054;
        }
        .success-style {
            background: #12b76a;
        }

        .juno-client-btn {
            margin-top: 48px;
        }
        .data-loader {
            border-width: 3px;
            margin: 0 auto 24px;
        }
        &-title {
            //jn-client-modal-resp-title
            text-align: center;
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: 700;
            color: #344054;
        }
        &-btns {
            //jn-client-modal-resp-btns
            .juno-client-btn {
                margin-top: 0;
            }
        }
    }
    &__text {
        //jncModal__text
    }

    &__btns {
        //jn-client-modal-btns
        grid-gap: 12px;
        // &:not(.no-border) { //todo:kris delete
        // 	border-top: 1px solid #D0D5DD;
        // }
        &.justify-end {
            display: flex;
            justify-content: flex-end;
        }
        &:not(.justify-end) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        &.top-margin {
            margin-top: 28px;
        }
        &.full-width {
            .jncButton {
                width: 100%;
            }
        }
    }
    &__successBtns {
        //jn-client-modal-successBtns
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 15px;
    }
    &__field {
        @media screen and (min-width: 551px) {
            .jncAlert {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
        &.mb {
            margin-bottom: 30px;
        }
        &.mt {
            margin-top: 30px;
        }
        &:not(.no-grid) {
            display: grid;
            grid-template-columns: 150px 1fr;
            grid-gap: 0 30px;
            align-items: center;
            .errorMessage {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
        &.no-grid {
            .jncModal__label {
                margin-bottom: 10px;
            }
        }
        &.disabled-field {
            .theme-modal-field-input {
                display: flex;
                align-items: center;
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }
        }
    }
    &__label {
        font-size: var(--input-font-size);
        span {
            color: var(--input-placeholder-color);
        }
        span.required {
            color: red;
        }
    }
    &__textarea {
        color: var(--modal-text-color);
        font-size: var(--input-font-size);
        background: var(--input-background);
        resize: none;
        border: 1px solid var(--input-border-color);
        border-radius: var(--input-border-radius);
        height: 120px;
        width: 100%;
        padding: 11px 14px;
        box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.15);
        &::placeholder {
            color: var(--input-placeholder-color);
        }
    }
    &__qr {
        max-width: 150px;
        width: 100%;
        margin: 40px auto;
    }
    &__checkbox {
        margin-top: 17px;
        input[type='checkbox'] {
            display: none;
        }
        &.checked {
            .jncModal__checkbox-icon {
                border-color: 1px solid #00a78a;
                background: #e5fffa;
            }
            svg {
                opacity: 1;
            }
        }
        &-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            width: auto;
        }
        &-icon {
            width: 20px;
            height: 20px;
            border: 1px solid #d0d5dd;
            border-radius: 6px;
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 10px;
            position: relative;
            transition: 0.2s;
        }
        svg {
            opacity: 0;
            transition: 0.2s;
            position: absolute;
            width: 80%;
            height: 80%;
            object-fit: contain;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    &__btnDivider {
        //todo:kris delete
        margin: 24px 0;
        background: #d0d5dd;
        width: 100%;
        height: 1px;
    }
    &__actions {
    }
    &__actionDelete {
        display: flex;
        width: 44px;
        height: 44px;
        border: 1px solid #d0d5dd;
        padding: 10px;
        border-radius: 50%;
        transition: 0.15s;
        &:hover {
            background: #fee4e2;
            border-color: #fee4e2;
        }
        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.deleteTwoBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    &-right {
        display: flex;
        align-items: flex-end;
        gap: 10px;
    }
}

.validationModal {
    &-title{
        margin-top: 32px;
        color: #26272B;
        font-size: 20px ;
        line-height: 28px;
        font-weight: 600;
    }
    &-subtitle{
        color: #3F3F46;
        font-size: 16px ;
        line-height: 24px;
        font-weight: 400;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .jncModal:not(.margin-x) .MuiPaper-root {
        max-width: initial;
        margin: 0;
    }
    .jncModal-bottom {
        align-items: flex-end !important;
        .MuiPaper-root {
            margin: 0 !important;
            max-width: 100% !important;
        }
    }
    .jncModal .deposit{
        margin: 10px !important;
    }
}

@media screen and (max-width: 550px) {
    .jncModal__title {
        font-size: 18px;
    }
    .jncModal__body,
    .jncModal .MuiDialogActions-root,
    .jncModal .MuiDialogTitle-root {
        padding: 20px 15px;
    }

    .jncModal__header,
    .jncAccountModal__header {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .jncModal__field:not(.no-grid) {
        grid-template-columns: 1fr;
        grid-gap: 10px; //todo:kris add label margin bottom value
    }
    .jncModal__field.mb {
        margin-bottom: 20px;
    }
    .jncModal__field.mt {
        margin-top: 20px;
    }
    .jncModal__btns.grid-on-mobile {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

//todo!kris заменить классы
@media screen and (max-width: 440px) {
    .theme-modal-authenticator {
        max-width: 220px;
    }
    .authCode-container {
        grid-gap: 6px;
    }
    .authCode {
        margin-top: 20px;
        input {
            height: 14vw;
        }
    }
}

.merchant-modal .MuiPaper-root::-webkit-scrollbar {
    display: none;
}

.merchant-modal .MuiPaper-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
}