.cl-mobileMenu {
	transform: translate3d(100%, 0, 0);
	max-width: 350px;
	background: #fff;
	width: 100%;
	position: fixed;
	bottom: 0;
	overflow-y: auto;
	height: calc(100% - 60px);
	z-index: 11;
	right: 0;
	transition: transform 0.2s ease-in-out;
	backface-visibility: hidden;
	padding: 30px 20px;
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	
	&.active {
		transform: translate3d(0, 0, 0);
		@media screen and (min-width: 992px) {
			transform: translate3d(100%, 0, 0);
		}
	}
	&-top {
		margin-top: 100px;
	}
	&-avatar {
		width: 80px;
		height: 80px;
		margin: 0 auto 20px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}
	&-account {
		margin: 30px 0;
		padding: 12px 10px;
		text-align: center;
		background: #F2F4F7;
		font-size: 14px;
		color: #475467;
	}
	&-name {
		color: #344054;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	}
	&-email {
		color: #98A2B3;
		font-size: 16px;
		text-align: center;
	}
	&-menu {
		&-divider {
			width: 100%;
			height: 1px;
			background: rgba(0, 0, 0, 0.12)
		}
	}
	&-link {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 10px;
		font-size: 14px;
		color: #344054;
		padding: 14px 0;
		width: 100%;
		align-items: center;
		div {
			display: flex;
			align-items: center;
		}
		&-arrow {
			width: 6px;
			height: auto;
		}
		&-icon {
			width: 16px;
			height: 16px;
			margin-right: 10px;
		}
	}
}

@media screen and (max-height: 740px) {
	.cl-mobileMenu-top {
		margin-top: 40px;
	}
}

@media screen and (max-width: 450px) {
	.cl-mobileMenu {
		max-width: 100%;
	}
	.cl-mobileMenu-top {
		margin-top: 40px;
	}
}

