.jn-mng {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	&-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 24px;
	}
	&-block {
		border-radius: 16px;
		background: rgba(255, 255, 255, 0.06);
		padding: 32px;
		&.mb {
			margin-bottom: 24px;
		}
	}
	&-num {
		width: 64px;
		height: 48px;
		margin-bottom: 24px;
		border: 1px solid rgba(255, 255, 255, 0.12);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		span {
			color: #28E7C5;
		}
	}
}

@media screen and (max-width: 1300px) {
	.jn-mng-grid {
		grid-gap: 20px;
	}
}

@media screen and (max-width: 991px) {
	.jn-mng-grid {
		grid-template-columns: repeat(2, 1fr);
	}
	.jn-mng-grid-col {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		grid-column-start: 1;
		grid-column-end: 3;
	}
	.jn-mng-block.mb {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 640px) {
	.jn-mng-grid {
		grid-template-columns: 1fr;
	}
	.jn-mng-grid-col {
		grid-template-columns: 1fr;
		grid-column-start: initial;
		grid-column-end: initial;
	}
}

@media screen and (max-width: 560px) {
	.jn-mng-block {
		padding: 24px;
	}
}