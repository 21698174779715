.jn-invest {
	.junoClient-title {
		text-align: left;
	}
	&-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 40px;
	}
	&-num {
		width: 48px;
		height: 48px;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.12);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 24px;
	}
	&-item {
		padding: 32px;
		border-radius: 16px;
		background: #091324;
		height: 100%;
		&-border {
			background: linear-gradient(180deg, rgba(56,218,139,.4) 0%, rgba(128,87,255,.4) 100%);
			border-radius: 16px;
			padding: 1px;
			box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.40);
		}
	}
}

@media screen and (max-width: 1300px) {
	.jn-invest-grid {
		grid-gap: 20px;
	}
}

@media screen and (max-width: 1058px) {
	.jn-invest-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 640px) {
	.jn-invest-grid {
		grid-template-columns: 1fr;
	}
	.jn-invest-num {
		width: 40px;
		height: 40px;
	}
	.jn-invest .junoClient-title {
		text-align: center;
	}
}

@media screen and (max-width: 560px) {
	.jn-invest-item {
		padding: 24px;
	}
}