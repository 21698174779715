@import './_mixins.scss';

.b2c-header-boder {
  position: fixed;
  width: 100%;
  border-style: solid none;
  border-width: 2px;
  border-image: var(--header-border-bottom);
  /* height: 50px; */
  line-height: 50px;
  color: white;
  text-align: center;
  z-index: 40;
  // backdrop-filter: blur(7px);
  top: -5px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--theme-header-background);
  animation-name: headerAnimation;
  animation-duration: 0.8s;
}

.b2c-navbar {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto !important;
  @include flexBoxSpaceBetween;
  padding-left: 1rem;
  //   border: 1px solid red;
  height: 80.5px;
  position: relative;
  // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  align-items: center;
  z-index: 1;
  // background-color: var(--theme-header-background);
  margin-right: -2px
}
.navbar-left {
  @include flexBoxSpaceBetween;
}
.navbar-left > div {
  margin: 0rem 1rem;
}
.common-btn {
  @include flexBoxJustifyCenter;
  box-sizing: border-box;
  width: 125px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  height: 40px;
  border-radius: 25px;
  cursor: pointer;
}
.btn-outline {
  color: var(--btn-filled-color);
  border: 2px solid #{$mainColor};
  background-color: var(--btn-filled-bg-color);
}
.btn-filled {
  color: #{$mainTextColor};
  //   border-radius: 25px;
  border: 2px solid #{$mainColor};
  background-color: #{$mainColor};
}
.moon-theme {
  @include flexBoxJustifyCenter;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: #{$mainColor};
  border: var(--moon-theme-border);
  color: #{$mainTextColor};
  cursor: pointer;
}

.wallet-icon {
  background: var(--user-profile-bg-color);
}

.user-profile {
  @include flexBoxSpaceBetween;
  padding: 0.2rem 0.6rem;
  border-radius: 30px;
  background-color: var(--user-profile-bg-color);
  border: var(--user-profile-border);
  cursor: pointer;
  height: 50px;
}
.user-profile > div {
  margin: 0px 0.5rem;
}

.user-profile-name {
  color: var(--user-profile-name-color);
  font-size: 16px;
  letter-spacing: 0.05px;
  line-height: 22px;
}
.user-profile-icon {
  color: #{$mainTextColor};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.05px;
  line-height: 24px;
  text-align: center;
  width: 40px;
  height: 38px;
}

.profile-menu-dropdown {
  // height: 360px;
  width: 261px;
  margin-top: -32%;
  border-radius: 4px;
  background-color: var(--profile-menu-bg-color);
  left: -20%;
  box-shadow: var(--profile-memu-box-shadow);
}

.profile-text {
  color: var(--profile-menu-text-color);
  font-size: 15px;
  letter-spacing: 0.04px;
  line-height: 20px;
  margin-bottom: 10px;
}
.profile-user-name {
  text-align: center;
}
.horizontal-line {
  border-color: var(--profile-horizontal-line) !important;
  margin: 10px 0px !important;
}
.b2c-logout {
  @include flexBoxJustifyCenter;
  height: 30px;
  // width: 241px;
  color: #{$mainTextColor};
  font-size: 15px;
  letter-spacing: 0.04px;
  line-height: 20px;
  margin: 10px 0px;
  background-color: #{$mainColor};
  border-radius: 4px;
}
.dark-toggle {
  @include flexBoxSpaceBetween;
}
.b2c-forgot-password {
  color: #0091ff;
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
  font-weight: normal;
}
.b2c-navbar-img{
  width: 200px;
  max-height: 55px;
}

// 
.navbar-left-mobile{
    display: none;
}
.menu_container{
  display: none;
}

// MOBILE VIEW

@media only screen and (min-width: 350px) and (max-width: 767px) {
 
  .b2c-navbar{
    height: 70px;
  }
  .b2c-navbar-img{
    width: 134.79px;
    max-height: 50px;
  }
  .navbar-left{
    display: none;
  }
  .navbar-left-mobile > div {
    margin-right: 20px;
    margin-left: 22px;
    // margin: 0rem 1rem;
  }
  .navbar-left-mobile{
    display: block;
    @include flexBoxSpaceBetween;
    width: 230px;
    .common-btn{
      height: 32px;
      width: 110px;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
    .hamburger-img{
    }
  }

  .menu_container{
    display: block;
    position: absolute;
    min-height: 100vh;
    width: 100%;
    background: var(--mobile-menu-BG);
    z-index: 30;
    
    .menu_content_container{
      margin: 24px 23px;
      .upper_menu{
        margin-bottom: 70px;
        .dark-toggle{
          font-size: 22px;
          letter-spacing: 0.06px;
          line-height: 30px;
          color:var(--user-profile-name-color);
          margin-bottom: 25px;
          .change_mode{
          }
        }

      }
      .middle_menu{
        @include flexBoxSpaceBetween;
        margin: 20px 0;
        padding: 20px 0;
        border-top: 1px solid var(--mobile-menu-button-div-border );
        border-bottom: 1px solid var(--mobile-menu-button-div-border );
        .common-btn{
          height: 44px;
          width: 100%;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;

          span{
            margin-left: 10px;
          }
        }
      }
      .lower_menu{
        position: fixed;
      width: 90%;
      bottom: 30px;
      }
    }
  }
}

.CP_calendar_modal > .modal-dialog {
    max-width: unset;
    margin-left: auto !important;
    margin-right: auto !important;
    border: none !important;
    background-color: transparent !important;
}