.junoClient {
	font-family: var(--textFont);
}


//--Gold version
$orange-color: #EC906C;

.juno {
	font-family: var(--textFont);
}