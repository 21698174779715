//todo:kris refactor

.jncTable-hover {
	.rdt_TableRow {
		&:hover {
			background: #fafafa;
		}
	}
}

.jncTableExpandFont {
	font-family: var(--opensansFont);
}

@media screen and (min-width: 992px) {
	.jncTable-addresses {
		.rdt_TableCol {
			flex-grow: 0 !important;
		}
		.rdt_TableCell {
			flex-grow: 0 !important;
		}
		.rdt_TableHeadRow, .rdt_TableRow {
			display: grid !important;
			grid-template-columns: 1fr 1fr 1fr 1fr 122px;
		}
	}
}

@media screen and (max-width: 1300px) {
	.jncTable-status {
		font-size: 13px;

	}
}