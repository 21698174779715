.jncButton {
    border-radius: 99px;
    padding: 11px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s;
    min-width: 120px;
    cursor: pointer;
    &.danger-style {
        background: #f04438 !important;
        p {
            color: #fff !important;
        }
        &:hover {
            background: #f34e42 !important;
        }
        .jncButton__loader {
            border-top-color: #fff !important;
        }
    }
    p {
        text-align: center;
        font-size: 16px;
        letter-spacing: 0.6px;
    }
    &.filled-style {
        background: var(--theme-color);
        p {
            color: white;
        }
        &:hover {
            background: var(--btn-background-hover);
        }
        svg {
            path {
                fill: #fff;
            }
        }
    }
    svg {
        margin-right: 6px;
    }
    &.outlined-style {
        border: 1px solid #d0d5dd;
        &:hover {
            background: #ebedf0;
        }
        p {
            color: #344054;
        }
    }
    &:disabled {
        pointer-events: none;
    }
    &.full-width {
        width: 100%;
    }
    &__loader {
        border: 1.5px solid transparent;
        border-top: 1.5px solid #000;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
        margin-right: 6px;
    }
    &:disabled {
        opacity: 0.4;
    }
}

.jncInputView {
    background: var(--input-background);
    border: 1px solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    padding: 12px 15px;
    font-size: var(--input-font-size);
    color: var(--modal-text-color);
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-items: self-end;
    p {
        &.is-ellipsis {
            width: 100%;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    &-copy {
        margin-left: 10px;
        border-radius: 99px;
        background: #fff;
        box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.24);
        color: #000;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        height: 24px;
        padding: 0 16px;
        text-transform: uppercase;
    }
    @media screen and (max-width: 767px) {
        box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.15);
        border: 0;
    }
}

.jncInput {
    background: var(--input-background);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    padding: 12px 15px;
    height: 50px;
    font-size: var(--input-font-size);
    color: var(--modal-text-color);
    width: 100%;
    border: 1px solid transparent;
    &.has-error {
        box-shadow: 0px 0px 0px 4px #fee4e2,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-color: #fda29b;
    }
    &::placeholder {
        color: #344054;
    }
}
.savedSearchInput{
    border-radius: 8px;
    font-size: 16px;
}
.jncSelect {
    background: var(--input-background);
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.15);
    border-radius: var(--input-border-radius);
    padding: 12px 15px;
    height: 50px;
    font-size: var(--input-font-size);
    color: var(--modal-text-color);
    width: 100%;
    &::before {
        border-bottom: none !important;
    }
    &::after {
        border-bottom: none !important;
    }
}
.typeahead-custom {
    position: relative;
    &-img {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 24px;
        height: 24px;
        z-index: 1;
        left: 14px;
    }
    &.has-value {
        .rbt-input {
            padding-left: 46px;
        }
    }
    &.has-error {
        .rbt-input {
            box-shadow: 0px 0px 0px 4px #fee4e2,
                0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            border-color: #fda29b;
        }
    }
}

.crypto-header-logo {
		width: auto;
		pointer-events: none;
}

@media screen and (max-width: 550px) {
    .jncButton {
        padding: 11px 15px;
        &.full-on-mobile {
            width: 100%;
        }
    }
}

@media screen and (max-width: 991px) {
    .jncButton {
        padding: 11px 15px;
        &.full-on-tablet {
            width: 100%;
        }
    }
}
