.jn-deposit {
	background: #0c152d;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	.junoClient-title {
		text-align: left;
	}
	&-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 40px;
		align-items: flex-end;
	}
	&-li {
		padding: 40px;
		border-radius: 8px;
		background: rgba(255, 255, 255, 0.06);
		border-left: 3px solid transparent;
		&.blue-border {
			border-left-color: #28E7C5;
		}
		&.orange-border {
			border-left-color: #FDB022;
		}
		&.violet-border {
			border-left-color: #B692F6;
		}
		&-title {
			color: #FFF;
			font-size: 24px;
			font-weight: 500;
			margin-bottom: 10px;
			line-height: 110%;
		}
		&-text {
			color: #EAECF0;
			font-size: 16px;
			line-height: 125%;
		}
		&.mb {
			margin-bottom: 24px;
		}
	}
	&-img {
		display: block;
		width: 100%;
		height: auto;
	}
}

@media screen and (max-width: 991px) {
	.jn-deposit-li-title {
		font-size: 20px;
	}
	.jn-deposit-grid {
		grid-template-columns: 1fr;
	}
	.jn-deposit-img {
		max-width: 600px;
		margin: 0 auto;
	}
	.jn-deposit {
		padding-bottom: 0;
	}
	.jn-deposit .junoClient-title {
		text-align: center;
	}
	.jn-deposit {
		background-position: center;
	}
}

@media screen and (max-width: 500px) {
	.jn-deposit-li-title {
		font-size: 18px;
	}
	.jn-deposit-li.mb {
		margin-bottom: 20px;
	}
	.jn-deposit-li {
		padding: 24px;
	}
}