.hero {
	height: 100vh;
	min-height: auto;
	display: flex;
	align-items: center;
	background-color: #000;
	position: relative;
	overflow: hidden;
	.content-container {
		position: relative;
		z-index: 2;
		height: calc(100% - 140px);
		display: flex;
		align-items: center;
	}
	&__main {
		&.on-desktop {
			position: absolute;
			top: 0;
			right: -15%;
			height: auto;
			width: 60%;
			bottom: 0;
			margin: auto 0;
			z-index: 2;
			@media screen and (max-width: 1058px) {
				display: none;
			}
		}
		&_m {
			display: none;
			margin: 40px 0;
		}
		&.on-mobile {
			max-width: 800px;
			width: 100%;
			height: auto;
			margin: 0 auto;
		}
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('https://dev.junomoney.org/images/upd/icons/grid.svg');
		background-size: 200px 200px;
		background-repeat: repeat;
		opacity: .2;
	}
	&__gradient {
		background-image: url('https://dev.junomoney.org/images/upd/hero-radial.webp');
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	&__content {
		max-width: 700px;
		width: 100%;
	}
	&__title {
		font-family: 'Inter', sans-serif;
		font-size: 54px;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 24px;
	}
	&__cursor {
		content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
		top: 0;
		left: 0;
    pointer-events: none;
		z-index: 1;
		background-size: 50% 50%;
	}
	&__coin {
		position: absolute;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		border: 1px solid rgba(255, 255, 255, .1);
		position: absolute;
		&:before {
			content: '';
			background-position: center;
			background-repeat: no-repeat;
			background-size: 40px 40px;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 50%;
		}
		&.coin-1 {
			top: 20px;
			left: -30px;
			&:before {
				background-image: url('https://dev.junomoney.org/images/upd/icons/coins/coin-4.webp');
				box-shadow: 0 2px 24px rgba(0, 119, 194, 1);
			}
		}
		&.coin-2 {
			bottom: 20px;
			left: -30px;
			&:before {
				background-image: url('https://dev.junomoney.org/images/upd/icons/coins/coin-5.webp');
				box-shadow: 0 2px 24px rgba(83, 174, 148, .5);
			}
		}
		&.coin-3 {
			bottom: 10%;
			left: -20%;
			right: 0;
			margin: 0 auto;
			&:before {
				
				background-image: url('https://dev.junomoney.org/images/upd/icons/coins/coin-6.webp');
				box-shadow: 0 2px 24px rgba(255, 227, 241, 1);
			}
		}
		&.coin-4 {
			top: 20px;
			right: -30px;
			&:before {
				background-image: url('https://dev.junomoney.org/images/upd/icons/coins/coin-1.webp');
				box-shadow: 0 2px 24px rgba(0, 197, 138, 5);
			}
		}
		&.coin-5 {
			bottom: 20px;
			right: -30px;
			&:before {
				box-shadow: 0 2px 24px rgba(91, 128, 242, .5);
				background-image: url('https://dev.junomoney.org/images/upd/icons/coins/coin-3.webp');
			}
		}
		&.coin-6 {
			top: 14%;
			right: 35%;
			&:before {
				background-image: url('https://dev.junomoney.org/images/upd/icons/coins/coin-2.webp');
				box-shadow: 0 2px 24px rgba(255, 189, 14, 1);
			}
		}
	}
}

@media screen and (max-width: 1550px) {
	.hero__content {
		max-width: 600px;
	}
}

@media screen and (max-width: 1300px) {
	.hero__title {
		font-size: 36px;
	}
	.hero__content {
		max-width: 500px;
	}
}

@media screen and (max-width: 1130px) {
	.hero__coin.coin-1, .hero__coin.coin-2 {
		left: 0;
	}
	.hero__coin.coin-4, .hero__coin.coin-5 {
		right: 0;
	}
}

@media screen and (max-width: 1058px) {
	.hero__coin {
		display: none;
	}
	.hero__content {
		max-width: initial;
	}
	.hero {
		padding: 120px 0 30px;
	}
	.hero .content-container {
		height: 100%;
	}
	.hero__main_m {
		display: block;
	}
	.hero__title {
		text-align: center;
	}
	.hero__content {
		.section-text {
			text-align: center;
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}
		.theme-btn-block {
			justify-content: center;
		}
	}
	.hero {
		padding: 90px 0 30px;
	}
}

@media screen and (max-height: 840px) {
	.hero__main.on-mobile {
		max-width: 450px;
	}
	.hero__coin {
		width: 40px;
		height: 40px;
	}
	.hero__coin:before {
		background-size: 28px 28px;
	}
}

@media screen and (max-height: 611px) {
	.hero__main_m {
		display: none;
	}
	.hero {
		min-height: 430px;
	}
}


// @media screen and (max-width: 500px) {
// 	.hero__content {
// 		display: flex;
// 		flex-direction: column;
// 		justify-content: center;
// 	}
// 	.hero {
// 		min-height: 520px;
// 	}
// }

// @media screen and (max-height: 600px) {
// 	.hero__main_m {
// 		display: none;
// 	}
// 	.hero {
// 		min-height: 100vh;
// 		height: auto;
// 	}
// }


@media screen and (max-width: 450px) {
	.hero__title {
		font-size: 30px;
	}
}
