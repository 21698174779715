.jnAm {
	.pd-y-tb {
		padding-top: 85px;
		padding-bottom: 85px;
	}
	.pd-y-t {
		padding-top: 85px;
	}
	&-section {
		min-height: 100vh;
		overflow: hidden;
		@media screen and (min-height: 1370px) {
			height: 1200px;
			min-height: initial;
		}
	}
	&-btn {
		padding: 10px 22px;
		text-decoration: none !important;
		background: var(--accent-color);
		border-radius: 4px;
		font-size: 16px;
		text-align: center;
		cursor: pointer;
		color: #fff;
		display: block;
		width: max-content;
		&:hover {
			color: #fff;
		}
		&.header-style {
			font-size: 14px;
			padding: 10px 14px;
		}
	}
	&-title {
		font-size: 40px;
		font-weight: 600;
		line-height: 105%;
		&.accent-color {
			color: var(--accent-color);
		}
		&.white-color {
			color: #fff;
		}
		&.black-color {
			color: #001D0B;
		}
	}
	&-text {
		color: #E3E8EF;
		font-size: 20px;
		line-height: 132%;
		letter-spacing: 0.3px;
	}
	&-bottomDeco {
		background: #141D29;
		position: relative;
		height: 30px;
		z-index: 1;
		width: 100%;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 79%;
			height: 100%;
			background: #fff;
		}
	}
	.data-loader-block {
		background: transparent;
	}
	.data-loader {
		width: 30px;
		height: 30px;
		border-width: 2px;
		border-color: rgba(255, 255, 255, .5);
		border-top-color: #fff;
	}
}

@media screen and (max-width: 430px) {
	.jnAm-text {
		font-size: 16px;
	}
	.jnAm-title {
		font-size: 35px;
	}
}