@import './_mixins.scss';

.light {
  --theme-header-background: #{$mainTextColor};
  --btn-filled-bg-color: #{$mainTextColor};
  --btn-filled-color: #{$mainColor};
  --user-profile-bg-color: #efefef;
  --user-profile-border: '';
  --user-profile-name-color: #000000;
  --wallet-icon-bg-color: #efefef;
  --footer-bg-color: #{$mainTextColor};
  --content-bg-color: #{$mainTextColor};
  --content-text-color: #{$contentTextColor};
  --content-text-color-1: black;
  --banner-text-color: black;
  --profile-menu-bg-color: #{$mainTextColor};
  --profile-menu-text-color: #000000;
  --profile-memu-box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.08);
  --profile-horizontal-line: lightgray;
  --signup-model-bg: #{$mainTextColor};
  --signup-text-color: #000000;
  --signup-input-field-bg-clr: #ecf0f9;
  --signup-input-field-border-clr: #fbfbfb;
  --accept-box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.18);
  --close-icon-clr: #000000;
  --content-merchent-text-color: #{$merchentTextColorDark};
  --wallets-Histoty-borderBottom-color: #e6e6e6;
  --wallets-history-details-bottomBackground: #f7f7f7;
  --wallets-history-details-addressTitle: rgba(0, 0, 0, 0.6);
  --gaineLoseTrand-percentages-clr: #70a72d;
  --balance-table-odd-row: #f9fafb;
  --balance-table-heading: #666666;
  --balance-table-send-background: #ffff;
  --balance-table-send-border: #e1e1e1;
  --table-switch-button-background: #ebf2f7;
  --header-border-bottom: linear-gradient(to left, white 1%, #008cff 40%, white 100%) 0 0 100% 0/3px 0 3px 0 stretch;
  --input-outline-clr: 1px solid #d1d0d0;
  --create-button-clr: lightgray;
  --wallets-Histoty-hover: #f5f8f9;
  --transaction-filter-box: #ffff;
  --transaction-filter-dataBox-border: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
  --transaction-filter-search-background: #f7f7f7;
  --transaction-filter-search-lebals: black;
  --transaction-filter-box-border: #f5f5f5;
  --wallets-Histoty-hover: #f5f8f9;
  --google-authenticator-bg-clr: #ffffff;
  --wallets-Histoty-hover: #f5f8f9;
  --settings-table-header: #5a5a5a;
  --moon-theme-border: 1px solid #{$mainColor};
  --alert-success-authetucator: #ebf2e3;
  --alert-success-authetucator-text-clr: #70a72d;
  --transaction-filter-calendar-month: rgba(0, 0, 0, 0.54);
  --transaction-filter-calendar-date: rgba(0, 0, 0, 0.38);
  --wallets-Histoty-hover: #f5f8f9;
  --settings-table-header: #5a5a5a;
  --app-theme-background-color: #ffff;
  --wallets-theme-background-color: radial-gradient(circle, #f7f7f70d 0%, #0091ff0d 100%);
  --kyc-header-bg-color: #f9fafb;
  --kyc-content-text: #606686;
  --kyc-continue-btn-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
  --kyc-vertical-line: #ededed;
  --receive-wallet-amount-bg-clr: #d1fae5;
  --receive-wallet-amount-clr: #006144;
  --identity-verify-bg: #ffcbcb;
  --buy-currency-content: #4f4f4f;
  --wallet-portfolio-border: rgba(33, 35, 46, 0.12);
  --buy-currency-border: 1px solid rgba(0, 0, 0, 0.1);
  --address-button-color: #000000;
  --address-input-background: #fbfbfb;
  --submit-button-address: #fff;
  --no-address-image-background: #ababab;
  --address-modal-submit: #fff;
  --trending-dropdown-background: whitesmoke;
  --mobile-home-sectionB-BG: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
  --mobile-home-BG: '';
  --mobile-menu-BG: #fafafa;
  --mobile-menu-button-div-border: rgba(0, 0, 0, 0.12);
  --grey-bg-hover-bg: rgba(0, 0, 0, 0.06);
  --modal-body-background: #fff;
  --modal-outer-background: #fff;
  --input-label-color: #667085;
  --icon-color: #667085;
  --input-background: #eaecf0;
  --input-border-color: ##d0d5dd;
  --input-placeholder-color: #98a2b3;
  --modal-text-color: #344054;
  --modal-light-text-color: #667085;
  --input-border-radius: 8px;
  --input-font-size: 16px;
}

.dark {
  --grey-bg-hover-bg: rgba(255, 255, 255, 0.1);
  --theme-header-background: #{$mainBgColor};
  --btn-filled-bg-color: #{$mainBgColor};
  --btn-filled-color: #{$mainTextColor};
  --user-profile-bg-color: rgba(255, 255, 255, 0.06);
  --user-profile-name-color: #{$mainTextColor};
  --wallet-icon-bg-color: linear-gradient(180deg, rgba(0, 156, 255, 0.1) 0%, rgba(0, 145, 255, 0) 100%);
  --footer-bg-color: radial-gradient(circle, #0f1029 0%, #000000 100%);
  --user-profile-border: '';
  --content-bg-color: '';
  --content-text-color: #{$mainTextColor};
  --content-text-color-1: white;
  --banner-text-color: white;
  --profile-menu-bg-color: #111117;
  --profile-menu-text-color: #{$mainTextColor};
  --profile-memu-box-shadow: 0 2px 17px 0 rgba(255, 255, 255, 0.08);
  --profile-horizontal-line: #4d4d4db8;
  --signup-model-bg: #{$signupModelbackGroundDark};
  --signup-text-color: #ffffff;
  --signup-input-field-bg-clr: #252525;
  --accept-box-shadow: '';
  --signup-input-field-border-clr: #252525;
  --close-icon-clr: #ffffff;
  --content-merchent-text-color: #{$mainTextColor};
  --wallets-Histoty-borderBottom-color: #31323a;
  --wallets-history-details-bottomBackground: #21232e;
  --wallets-history-details-addressTitle: #b9b9b9;
  --gaineLoseTrand-percentages-clr: #9dff27;
  --input-outline-clr: 'none';
  --create-button-clr: #252525;
  --balance-table-odd-row: #111117;
  --balance-table-heading: #c2c2c2;
  --balance-table-send-background: rgba(255, 255, 255, 0);
  --balance-table-send-border: #5a5a5a;
  --table-switch-button-background: #303b51;
  --header-border-bottom: linear-gradient(to left, rgba(0, 0, 0, 1) 1%, rgb(0, 140, 255) 50%, rgba(0, 0, 0, 1) 100%) 0 0 100% 0/3px 0 3px 0 stretch;
  --wallets-Histoty-hover: #21232e;
  --transaction-filter-box: #21232e;
  --transaction-filter-box-border: #3e3e3e;
  --transaction-filter-dataBox-border: 0 5px 34px 0 rgba(130, 135, 165, 0.34);
  --transaction-filter-search-background: #111117;
  --transaction-filter-search-lebals: white;
  --wallets-Histoty-hover: #21232e;
  --google-authenticator-bg-clr: #191a23;
  --wallets-Histoty-hover: #21232e;
  --settings-table-header: #5a5a5a;
  --change-password-input: #21232e;
  --change-password-input-border: #3e3e3e;
  --moon-theme-border: '';
  --alert-success-authetucator: #29322c;
  --alert-success-authetucator-text-clr: #ffffff;
  --transaction-filter-calendar-month: #ffffff;
  --transaction-filter-calendar-date: #ffffff;
  --app-theme-background-color: radial-gradient(circle, #0f1029 0%, #000000 100%);
  --wallets-theme-background-color: linear-gradient(#2a364e 0%, #21232e 100%);
  --kyc-header-bg-color: #21232e;
  --kyc-content-text: #a7abc0;
  --kyc-continue-btn-shadow: '';
  --kyc-vertical-line: #2f3148;
  --receive-wallet-amount-bg-clr: #acffd4;
  --receive-wallet-amount-clr: #acffd4;
  --identity-verify-bg: #3e2020;
  --buy-currency-content: #a7abc0;
  --wallet-portfolio-border: #21232e;
  --buy-currency-border: 1px solid rgba(255, 255, 255, 0.1);
  --address-button-color: #fff;
  --address-input-background: #21232e;
  --submit-button-address: #000000;
  --no-address-image-background: #ababab;
  --address-modal-submit: #fff;
  --trending-dropdown-background: #222531;
  --mobile-home-sectionB-BG: #000000;
  --mobile-home-BG: #000000;
  --mobile-menu-BG: #191a23;
  --mobile-menu-button-div-border: rgba(255, 255, 255, 0.12);
  --modal-body-background: #090b10;
  --modal-outer-background: rgba(255, 255, 255, 0.1);
  --input-label-color: #d0d5dd;
  --icon-color: #fff;
  --input-background: #1d2939;
  --input-border-color: #344054;
  --input-placeholder-color: #667085;
  --modal-text-color: #fff;
  --modal-light-text-color: #d0d5dd;
  --input-border-radius: 8px;
  --input-font-size: 16px;
}
