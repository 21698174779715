@import './_mixins.scss';

.onboard-kyc-container {
  background-color: var(--signup-model-bg);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
:is(.onboard-kyc-header, .onboard-kyc-content) {
  padding: 1rem 2.5rem;
}

:is(.onboard-kyc-header, .onboard-kyc-content, .verfiy-onboard-pictures, .kyc-select-options) {
  @include flexBoxSpaceBetween;
}
.onboard-kyc-header {
  background-color: var(--kyc-header-bg-color);
  border-radius: 4px 4px 0 0;
}
.onboard-kyc-heading {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}
.kyc-progress {
  color: #009cff;
}
.kyc-content-heading {
  font-size: 14px;
}
.kyc-content-sub-heading {
  color: var(--kyc-content-text);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  font-weight: 400;
}
.verify-now {
  font-weight: 600;
  font-size: 14px;
}
.confirm-mail {
  color: #10b981;
  font-weight: 600;
  font-size: 14px;
}
:is(.kyc-buy-send-btns-container, .confirm-mail-box-container, .confirm-mail-conatainer) {
  @include flexBox;
}
.kyc-btns {
  border: 1px solid #{$mainColor};
  border-radius: 20px;
  color: #{$mainColor};
  height: 40px;
  width: 100px;
  font-size: 14px;
  @include flexBoxJustifyCenter;
}
.verify-onboard-heading {
  color: var(--signup-text-color);
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.06px;
  line-height: 26px;
}
.verify-onboard-sub-heading {
  color: var(--signup-text-color);
  font-size: 16px;
  letter-spacing: 1.24px;
  line-height: 25px;
  width: 90%;
}
.kyc-continue-on-phn {
  color: #2a348f;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  background-color: #ffffff;
  box-shadow: var(--kyc-continue-btn-shadow);
}
.kyc-upload {
  width: 100%;
  height: 50px;
}
.kyc-vertical-divider {
  box-sizing: border-box;
  height: 135px;
  width: 1px;
  border-left: 1px solid var(--kyc-vertical-line);
}
.crypto-assests-box > img {
  margin-right: 15px;
}

.kyc-select-options {
  box-sizing: border-box;
  height: 51px;
  width: 402px;
  border: 1px solid var(--transaction-filter-box-border);
  background-color: var(--wallets-history-details-bottomBackground);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.kyc-select {
  border-radius: 25px;
}
.kyc-seleted-options {
  border-radius: none;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.kyc-options {
  padding: 1rem;
  border-bottom: 1px solid var(--transaction-filter-box-border);
}
.kyc-options-container {
  max-height:200px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  position: absolute;
  border: 1px solid var(--transaction-filter-box-border);
  border-top: none;
  width: 402px;
  background-color: var(--signup-model-bg);
}
.kyc-options-container::-webkit-scrollbar {
  width: 5px;
}
.kyc-options-container::-webkit-scrollbar-track {
  background: transparent;
}
.kyc-options-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #{$mainColor};
}
.kyc-options-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.kyc-continue-btn {
  position: absolute;
  bottom: 0px;
  width: 90%;
}
.card-bg {
  background-color: var(--signup-model-bg);
}
.kyc-authentication {
    height: 55px;
    right: 32px;
    top: 110px;
}
