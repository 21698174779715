@import './_mixins.scss';

.faq_detail_container{
    .faq_detail_subContainer{
        min-height: 100vh;
        color: #000000;
        background-color: #FFFFFF;
        padding-top: 90px;
        .faq_detail{
                padding-top: 42px;       
                width: 85%;
                margin: 0 auto;
                display: flex;
                .question_list{
                    padding-bottom: 30px;
                    width: 25%;
                    .back_arrow{
                        padding-bottom: 35px;
                        i{
                            cursor: pointer;
                            font-size: 22px;
                        }
                        span{
                            padding-left: 20px;
                            font-size: 20px;
                            font-weight: 600;
                            letter-spacing: 0.05px;
                            line-height: 22px;
                        }    
                    }
                    .question{
                        font-size: 16px;
                        letter-spacing: 0.04px;
                        line-height: 22px;
                        border-bottom: 1px solid rgba(0,0,0,0.08);
                        padding: 10px 0px;
                        cursor: pointer;
                        color: rgba(86,88,103,0.62);
                    }
                    .question_active{
                        color: #000000 !important;
                    }
                }
                .question_content{
                    padding-bottom: 30px;
                    width: 70%;
                    padding-left: 8%;
                    .content_title{
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 0.05px;
                        line-height: 22px;
                        padding-bottom: 35px;
                    }
                    .question_steps{
                        padding-bottom: 55px;
                        .step_content{
                            color: #565867;
                            font-size: 16px;
                            font-style: italic;
                            letter-spacing: 0.04px;
                            line-height: 17px;
                            border-bottom: 1px solid rgba(0,0,0,0.21);
                            padding-bottom: 15px;
                            span{
                               font-weight: bold;
                               color: #000000;
                            }
                        }
                        .step_img{
                            padding-top: 15px;
                            img{
                                height: 403px;
                                width:  100%;
                            }

                        }
                    }
                }
        }
    }
}

@media screen and (max-device-width: 480px){
    .question_list{
        margin-top: -20px;
        width: 100%;
        padding-bottom: 50px;
        .back_arrow{
            color: #{$mainTextColor};
            height: 52px;
            padding-bottom: 35px;
            background-color: #171723;
            i{
                padding-left: 11px;
                cursor: pointer;
                font-size: 22px;
                position: relative;
                top: 80%;               
            }
            span{
                left: 34%;
                font-size: 19px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 21px;
                text-align: center;
                position: relative;
                top: 80%;
            }    
        }
        .question{
            width: 95%;
            margin: 0 auto;
            font-size: 14px;
            letter-spacing: 0.04px;
            line-height: 16px;
            border-bottom: 1px solid rgba(0,0,0,0.08);
            padding: 15px 0;
            cursor: pointer;
            color: #000000;
        }
    }
    .sub_header{
        font-size: 14px;
        letter-spacing: 0.04px;
        line-height: 16px;
        color: rgba(86,88,103,0.62);
        width: 95%;
        margin: 0 auto;
        padding: 15px 0;
        .heading{
          cursor: pointer;
        }
        .right_arrow{
            padding: 0 15px;
            font-size: 16px;
        }
        .topic_name{
            color: #000000;
        }
    }
    .question_content{
        padding-bottom: 30px;
        width: 95%;
        margin: 0 auto;
        .content_title{
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.05px;
            line-height: 22px;
            padding-bottom: 35px;
        }
        .question_steps{
            padding-bottom: 55px;
            .step_content{
                color: #565867;
                font-size: 16px;
                font-style: italic;
                letter-spacing: 0.04px;
                line-height: 17px;
                border-bottom: 1px solid rgba(0,0,0,0.21);
                padding-bottom: 15px;
                span{
                   font-weight: bold;
                   color: #000000;
                }
            }
            .step_img{
                padding-top: 15px;
                img{
                    height: 403px;
                    width:  100%;
                }

            }
        }
    }
    .botttom_border{
        border-bottom: 1px solid rgba(0,0,0,0.21);
        padding-top: 15px;
    }

    .topic_name_not_active{
        color: rgba(86,88,103,0.62);
    }
}


@media (min-device-width: 481px) and (max-device-width: 899px){
    .question_list{
        width: 100%;
        padding-bottom: 50px;
        .back_arrow{
            margin-top: 20px;
            height: 52px;
            padding-bottom: 35px;
            i{
                padding-left: 11px;
                cursor: pointer;
                font-size: 22px;             
            }
            span{
                padding-left: 30px;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.05px;
                line-height: 20px;
                text-align: center;
            }    
        }
        .question{
            @include flexBoxSpaceBetween;
            width: 95%;
            margin: 0 auto;
            font-size: 14px;
            letter-spacing: 0.04px;
            line-height: 16px;
            border-bottom: 1px solid rgba(0,0,0,0.08);
            padding: 15px 0;
            cursor: pointer;
            color: #000000;
        }
    }
    .sub_header{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.05px;
        line-height: 20px;
        color: rgba(86,88,103,0.62);
        width: 95%;
        margin: 0 auto;
        padding: 15px 0;
        .heading{
          cursor: pointer;
        }
        .right_arrow{
            padding: 0 15px;
            font-size: 16px;
        }
        .topic_name{
            color: #000000;
        }
    }
    .question_content{
        padding-bottom: 30px;
        width: 95%;
        margin: 0 auto;
        .content_title{
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.05px;
            line-height: 22px;
            padding-bottom: 35px;
        }
        .question_steps{
            padding-bottom: 55px;
            .step_content{
                color: #565867;
                font-size: 16px;
                font-style: italic;
                letter-spacing: 0.04px;
                line-height: 17px;
                border-bottom: 1px solid rgba(0,0,0,0.21);
                padding-bottom: 15px;
                span{
                   font-weight: bold;
                   color: #000000;
                }
            }
            .step_img{
                padding-top: 15px;
                img{
                    height: 403px;
                    width:  100%;
                }

            }
        }
    }
    .botttom_border{
        border-bottom: 1px solid rgba(0,0,0,0.21);
        padding-top: 15px;
    }

    .topic_name_not_active{
        color: rgba(86,88,103,0.62);
    }
    .question_rightArrow{
        color: #565867;
        font-size: 20px;
        font-weight: bold;

    }
}