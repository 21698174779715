.jnAm-footer {
	background: #202939;
	padding: 120px 0;
	&-socials {
		display: flex;
		align-items: center;
		a {
			display: flex;
			align-items: center;
			&:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
	&-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.divider {
		margin: 80px 0;
		width: 100%;
		height: 1px;
		background: rgba(255, 255, 255, 0.10);
	}
	&-text {
		color: #98A2B3;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.6px;
		span {
			color: #fff;
		}
	}
	&-links {
		display: flex;
		align-items: center;
		a {
			text-decoration: none;
			transition: .2s;
			&:hover {
				color: #fff;
			}
		}
		.line {
			width: 1px;
			height: 19px;
			background: rgba(255, 255, 255, 0.20);
			margin: 0 18px;
		}
	}
	&-block {
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@media screen and (max-width: 780px) {
	.jnAm-footer .divider {
		margin: 34px 0;
	}
	.jnAm-footer-block {
		flex-direction: column;
	}
	.jnAm-footer-copyright {
		margin-bottom: 50px;
	}
	.jnAm-footer {
		padding: 105px 0;
	}
	.jnAm-footer-text {
		text-align: center;
	}
}

@media screen and (max-width: 494px) {
	.jnAm-footer-links {
		flex-direction: column;
	}
	.jnAm-footer-links .line {
		width: 103px;
		height: 1px;
		margin: 18px 0;
	}
}