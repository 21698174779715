@import './_mixins.scss';

.TrackingDeposit_wrap {
    width: 100%;
    @include flexBoxJustifyCenter;
    flex-direction: column;
    .TrackingDeposit_bg_topBlock {
        height: 400px;
        background-color: #F0F0F0;
        overflow: hidden;
        width: 100%;
        .TrackingDeposit_bg_topBlock_bt {
            height: 1px;
            width: 100%;
            background-color: lightgrey;
            box-shadow: 0px -3px 11px 1px rgba(0,0,0,0.44)
        }
    }
    .TrackingDeposit_modal_wrap {
        max-width: 1400px;
        width: 100%;
        @include flexBoxSpaceBetween;
        transform: translateY(-50%);
        align-self: center;
        padding: 0 20px;
        .TrackingDeposit_deposit_tracking_modal, .TrackingDeposit_deposit_status_modal {
            max-width: 565px;
            min-height: 460px;
            width: 100%;
            border-radius: 4px;
            background-color: #FFFFFF;
            box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
        }
        .TrackingDeposit_deposit_status_modal {
            margin-left: 20px;
        }
    }
}