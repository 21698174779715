$bg-color: #ffffff;

* {
	box-sizing: border-box;
}

.fullPageLoading {
	z-index: 2000;
	background-color: rgb(0, 0, 0);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	letter-spacing: 2px;
	text-transform: lowercase;
	color: #fff;
	font-family: "Inter";
}

html, body {
  overflow-x: hidden;
}

html, body {
	font-family: var(--stagnanFont);
	color: #fff;
	font-size: 16px;
	line-height: 130%;
}

body {
	padding-right: 0 !important;
	min-height: 100vh;
	// background-color: #030515;
}

.projectApp {
  background: var(--app-theme-background-color);
  min-height: 100vh
}

img {
	display: block;
}

input {
	&:focus {
		outline: none;
	}
}

.content-container {
  width: 1480px;
  max-width: 100%;
	margin: 0 auto;
	position: relative;
	@media screen and (max-width: 1550px) {
		width: 1200px;
	}
	@media screen and (max-width: 1300px) {
		width: 1000px;
	}
	@media screen and (max-width: 1090px) {
		width: 1000px;
	}
	@media screen and (max-width: 1058px) {
		width: 950px;
	}
	@media screen and (max-width: 991px) {
		width: 92%;
	}
}

.orange-text {
	color: #FABE65;
}
.blue-text {
	color: #0091FF;
}
.grey-text {
	color: #E0E0E0;
}

.t-center {
	text-align: center;
}

.grey-bg-hover {
	background: var(--grey-bg-hover-bg);
	transition: .15s;
	&:hover {
		background: var(--grey-bg-hover-bg);
	}
}

.flex-col-between {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.logo {
	cursor: pointer;
	img {
		width: 100%;
		height: auto;
	}
}
.login-logo {
	width: 108.19px !important;
	height: 26px !important;
}

.section {
	padding: 95px 0;
	@media screen and (max-width: 1090px) {
		padding: 65px 0;
	}
	&-border {
		background: linear-gradient(90deg, rgba(0, 145, 255, 0) 0%, #0091FF 51.04%, rgba(0, 145, 255, 0) 100%);
		width: 100%;
		height: 1px;
	}
	&-text {
		font-size: 18px;
		line-height: 154%;
		@media screen and (max-width: 1300px) {
			font-size: 16px;
		}
		@media screen and (max-width: 780px) {
			font-size: 14px;
		}
	}
	&-upperTitle {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 12px;
		p {
			color: #FABE65;
			padding: 10px 20px;
			background: rgba(255, 255, 255, 0.04);
			border: 1px solid rgba(255, 255, 255, 0.2);
			border-radius: 12px;
			@media screen and (max-width: 991px) {
				font-size: 14px;
			}
		}
	}
	&-title {
		font-family: 'Inter', sans-serif;
		font-weight: 600;
		font-size: 48px;
		color: #fff;
		text-align: center;
		line-height: 130%;
		&.mb {
			margin-bottom: 64px;
		}
		&.mb-subtitle {
			margin-bottom: 20px;
		}
		@media screen and (max-width: 1300px) {
			font-size: 32px;
			&.mb {
				margin-bottom: 40px;
			}
		}
		@media screen and (max-width: 480px) {
			font-size: 28px;
		}
	}
}


//-----


.container {
  max-width: 1700px;
  padding-left: 16px;
  padding-right: 16px;
}

.subContainer {
  width: calc(100vw - 366.68px);
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  min-width: fit-content;
}
.app-content-loading {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  display: block;
  color: #0a0b1b;
  // background-color: rgba(132, 132, 132, 0.7);
  backdrop-filter: blur(1px);
  z-index: 1450;
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}

.loading_main {
  // background-color: rgba(132, 132, 132, 0.7);
  background-color: transparent;
  position: fixed;
  max-width: 500px;
  max-height: 500px;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  transform: translateX(-46%);
  // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
}
.loading_pay_main {
  // background-color: rgba(132, 132, 132, 0.7);
  background-color: transparent;
  position: fixed;
  max-width: 500px;
  max-height: 500px;
  top: 30%;
  left: 50%;
  transform: translateX(-40%);
  display: flex;
  // box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
  .loading_pay_main_title {
	color: #FFFFFF;
	position: absolute;
	top: 135px;
	transform: translateX(-30%);
    width: 350px;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
  }
}
.loading_text {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05px;
  line-height: 22px;
  margin-top: 65px;
}

.font-inter{
	font-family: "Inter";
}