@import './_mixins.scss';
.calendar-icon {
  width: 28px;
  height: 27px;
  cursor: pointer;
}
.currencyImg{
  display:inline-block;
  border-radius: 100%;
}
.nohistorycomponent {
  width: 100%;
  @include flexBoxJustifyCenter;
  flex-direction: column;
  margin-top: 90px;
  .nohistoryicon {
    width: 56.5px;
    height: 62px;
    margin-bottom: 16px;
  }
  .nohistorytext {
    color: #ababab;
    height: 22px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.04px;
    line-height: 17px;
    text-align: center;
  }
}

.walletDetailPage {
  background: var(--wallets-theme-background-color) !important;
}
.outter__container {
  max-width: 1605px;
  margin-left: auto;
  margin-right: auto;
}
.wallet-container {
  overflow-x: hidden;
  padding-top: 110px !important;
  padding: 30px 20px;
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;
}
.wallet {
  @include flexBoxSpaceBetween;

  .donought-chart-wrap {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    .donout-chart-text {
      top: initial;
      position: absolute;
    }
  }
  .donought-chart-default {
    z-index: 0;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    .dcd_outter_crl {
      z-index: 1;
      position: relative;
      background: linear-gradient(to bottom, #c1e8fd, #{$mainColor});
      height: 190px;
      width: 190px;
      margin-top: 10px;
      border-radius: 50%;
      .dcd_inner_crl {
        z-index: 2;
        position: absolute;
        background-color: var(--signup-model-bg);
        height: 170px;
        width: 170px;
        left: 10px;
        top: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .topZero {
          top: 0;
        }
      }
    }
  }

  .wallet__portfolio {
    background-color: var(--signup-model-bg);
    width: 30vw;
    height: 387.5px;
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    padding: 1rem 2rem;
  }

  .assest {
    font-weight: bold;
    color: var(--signup-text-color);
  }
  .in-portfolio {
    color: #969696;
  }
  .parent-asset {
    position: relative;
    left: 20px;
  }
  .wallet-assests,
  .wallet-buttons {
    @include flexBoxSpaceBetween;
    margin-bottom: 20px;
    //
    width: 100%;
  }
  .wallet-assests {
    margin-top: -20px;
    position: relative;
  }
  .wallet-btns {
    @include flexBoxJustifyCenter;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #{$mainColor};
    color: #ffff;
    padding: 0rem 0.5rem;
    height: 50px;
    // width: 110px;
    width: inherit;
    margin-right: 10px;
    border: 2px solid #{$mainColor};
    border-radius: 25px;
    span {
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.05px;
      line-height: 22px;
      text-align: center;
    }
  }
  .wallet-btns:last-child {
    margin-right: 0px;
  }
  .wallet__history {
    background-color: var(--signup-model-bg);
    width: 30vw;
    height: 387.5px;
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    margin: 0px 25px;
  }
  .wallet__transaction__history {
    height: 387.5px;
    width: 30vw;

    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--signup-model-bg);
    .title {
      color: var(--signup-text-color);
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.05px;
      line-height: 24px;
      padding: 10px;
      padding-bottom: 10px;
    }
  }
}
// }
.transaction__history {
  padding: 7px 10px;

  @include flexBoxSpaceBetween;
  cursor: pointer;
  .history__coin {
    @include flexBox;
    .status__img {
      height: 30px;
      width: 30px;
    }

    .status__Address {
      padding-left: 15px;

      .address {
        color: var(--signup-text-color);
        font-size: 14px;
        letter-spacing: 0.04px;
        line-height: 19px;
      }

      .status {
        margin-top: -2px;
        color: #8287A5;
        font-size: 10px;
        letter-spacing: 0.03px;
        line-height: 14px;
      }
    }
  }
  .history__coin__value {
    @include flexBox;
    padding: 10px 0px;
    .coin__amount {
      color: #969696;
      font-size: 14px;
      letter-spacing: 0.04px;
      line-height: 19px;
    }
    span {
      padding-left: 10px;
      img {
        height: 19px;
        width: 19px;
        border-radius: 100%;
      }
    }
    .forward__arrow {
      padding-left: 20px;
      i {
        width: 24px;
        height: 24px;
        opacity: 0.2;
        font-size: 25px;
        color: var(--signup-text-color);
      }
    }
  }
}
.transaction__history:hover {
  .history__coin__value > .forward__arrow > i {
    opacity: 10;
  }
}
.transaction__history:nth-child(even) {
  background-color: var(--balance-table-odd-row);
}

.transaction__Detail__model {
  .details__model {
    border-radius: 10px;
    padding: 1rem 2rem;
    overflow-y: hidden;

    .details__close__icon {
      @include flexBoxJustifyEnd;
    }

    .icon__close {
      color: var(--close-icon-clr);
      // color: black;
      cursor: pointer;
    }

    .details__date {
      padding-top: 10px;
      color: #9e9e9e;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
    }
    .details__status {
      padding-top: 10px;
      padding-bottom: 15px;
      color: var(--signup-text-color);
      // color: black;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.06px;
      line-height: 30px;
      text-align: center;
    }
    .details__address{
      color: #858585;
      font-size: 14px;
      letter-spacing: 0.04px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 15px;
    }
    .details__amount {
      width: max-content;
      margin: 0px auto;
      color: #06565f;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.05px;
      line-height: 17px;
      text-align: center;
      border-radius: 25px;
      padding: 10px 25px;
    }
    .details__amount__send__BG{
      background-color: #ffe9ee;
    }
    .details__amount__BG{
      background-color: #d1faf4;
    }
    .detail__Orignal__amount {
      padding-top: 10px;
      color: #858585;
      font-size: 14px;
      letter-spacing: 0.04px;
      line-height: 17px;
      text-align: center;
    }
    .transaction__Details__status {
      @include flexBoxJustifyCenter;
      color: #065f46;
      font-size: 14px;
      letter-spacing: 0.04px;
      line-height: 19px;
    }
    
  }
  .details__model_mobile_view {
    width: 461px;
  }
  .transaction__Details {
    height: 340px;
    width: 100%;
    background-color: var(--wallets-history-details-bottomBackground);

    
    .copied__text {
      position: relative;
      float: right;
      margin-right: 14px;
      font-size: 14px;
      opacity: 0.8;
      margin-top: -25px;
    }
    .from__address {
      padding-left: 20px;
      padding-top: 10px;
      h1 {
        color: var(--wallets-history-details-addressTitle);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
      }
      .details__row {
        @include flexBoxSpaceBetween;
        h1 {
          color: var(--signup-text-color);
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 22px;
          span {
            color: #969696;
          }
        }
        .copy__icon {
          cursor: pointer;
          font-size: 16.67px;
          padding-right: 20px;
          color: var(--signup-text-color);
          i {
            opacity: 0.2;
          }
          i:hover {
            opacity: 10;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 350px) and (max-width: 480px) {
  .css-hz1bth-MuiDialog-container{
    height: auto;
  }
}
@media only screen and (min-width: 350px) and (max-width: 768px) {
  .transaction__Detail__model{
    width: 100%;
    height: 100%;
 .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: var(--signup-model-bg);
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 700px;
    height: 100vh;
}
.details__model{
  overflow-y: visible;
}
}

}


.sectionTable {
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;

  padding-bottom: 30px;
  .switchAndTable {
    margin: 0px 20px;
    height: fit-content;
    border-radius: 4px;
    background-color: var(--signup-model-bg);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);

    .switchAndSearch {
      padding: 20px;
      @include flexBoxSpaceBetween;
      .switchButton {
        height: 50px;
        width: 300px;
        @include flexBox;
        border-radius: 25px;
        background-color: var(--table-switch-button-background);
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
        color: var(--signup-text-color);
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        .balance {
          height: 45px;
          width: 150px;
          padding: 15px 15px;
          opacity: 0.5;
        }
        .transaction {
          height: 45px;
          width: 150px;
          padding-left: 10px;
          padding: 15px 15px;
          opacity: 0.5;
        }
        .activeButton {
          height: 50px;
          width: 150px;
          cursor: default;
          border-radius: 23.75px;
          color: #ffff;
          background-color: #{$mainColor};
          box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
          opacity: 1;
        }
      }
      .searchInput {
        width: 90%;
        height: 50px;
        width: 324px;
        border-radius: 25px;
        background-color: var(--table-switch-button-background);
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
        padding: 4px 15px;
        @include flexBoxSpaceBetween;

        input {
          color: var(--signup-text-color);
          border: none;
          outline: none;
          font-size: 14px;
          letter-spacing: 1.26px;
          line-height: 27px;
          background-color: var(--table-switch-button-background);
        }
        input::placeholder {
          color: var(--transaction-filter-search-lebals);
        }
      }
      .searchInputDark {
        input[type='text']::-webkit-input-placeholder {
          color: #ffffff;
        }
      }
      .searchInputDark {
        input[type='text']::-webkit-input-placeholder {
          color: #ffffff;
        }
      }
      .searchInputDark {
        input[type='text']::-webkit-input-placeholder {
          color: #ffffff;
        }
      }
    }
  }
}
.serchIcon {
  font-size: 18px;
  color: var(--signup-text-color);
}

.gaineLoseTrand {
  @include flexBoxSpaceBetween;
  margin: 30px 10px;

  .BiggestGainers {
    background-color: var(--signup-model-bg);
    height: 351px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    margin: 0px 10px;
    overflow-y: scroll;
    .title {
      @include flexBoxSpaceBetween;
      padding: 10px 20px;
      color: var(--signup-text-color);
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.24px;
      line-height: 26px;
      .title__rignt {
        span {
          i {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.dataRow {
  @include flexBoxSpaceBetween;
  padding: 12px 20px;
  .data__row__left {
    @include flexBox;
    .dataRow__counter {
      color: var(--signup-text-color);
      font-size: 16px;
      letter-spacing: 0.04px;
      line-height: 22px;
      padding-right: 10px;
    }
    img {
      height: 32px;
      width: 32px;
      border-radius: 100%;
    }
    .dataRow__name {
      color: var(--signup-text-color);
      padding-left: 10px;
      font-size: 16px;
      letter-spacing: 0.04px;
      line-height: 22px;
      span {
        color: #969696;
      }
    }
  }
  .data__row__right {
    align-items: center;
    i {
      font-size: 15px;
      color: var(--gaineLoseTrand-percentages-clr);
    }
    span {
      padding-left: 5px;
      color: var(--gaineLoseTrand-percentages-clr);
      font-size: 16px;
      letter-spacing: 0.24px;
      line-height: 22px;
      text-align: right;
    }
  }
}

.buyReceiveSend {
  @include flexBoxSpaceBetween;
  margin: 0px 10px;

  .buy {
    background-color: var(--signup-model-bg);
    max-height: 195px;
    height: fit-content;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    margin: 0px 10px;

    .buy__content {
      padding: 20px;
      .content__image {
        height: 50px;
        width: 50px;
        align-items: center;
        border-radius: 50%;
        img{
          height: 50px;
          width: 50px;
        }
        .image {
          padding: 8px;
          img {
            height: 33px;
            width: 33px;
          }
        }
      }
      h1,.buyText {
        padding: 8px 0px;
        color: var(--signup-text-color);
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.05px;
        line-height: 24px;
      }
      p,.buyDesc {
        opacity: 0.5;
        color: var(--signup-text-color);
        font-size: 14px;
        letter-spacing: 0.04px;
        line-height: 19px;
      }
      .content__promotion {
        @include flexBox;
        margin: 10px 0px;
        .promotion {
          margin-right: 10px;
          color: #000000;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 0.03px;
          line-height: 14px;
          text-align: center;
          border-radius: 12px;
          background-color: #f1f5f6;
          padding: 5px 10px;


          img {
            width: 13px;
            height: 13px;
            position: relative;
            bottom:2px;
          }
        }
      }
    }
  }
}


.ScrollBarStyle {
  max-height: 274px;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: -30px;
  margin-right: -30px;
}

.ScrollBarStyle::-webkit-scrollbar {
  width: 10px;
}

.ScrollBarStyle::-webkit-scrollbar-track {
  background: transparent;
  background-color: transparent;
}

.ScrollBarStyle::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 6px;
  background-color: #{$mainColor};
}

.ScrollBarStyle::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Balance Table CSS */

.BalanceTableMain {
  .BalanceTable_heading {
    border-top: none;

    height: 50px;
    color: var(--balance-table-heading);
    font-size: 12px;
    letter-spacing: 0.03px;
    line-height: 17px;
    background-color: var(--balance-table-odd-row);
    .BalanceTable_heading_sortIcon {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .BalanceTable_item {
    min-height: 60px;
    color: var(--signup-text-color);
    font-size: 16px;
    letter-spacing: 0.04px;
    line-height: 22px;
    border-top: none;

    .USTImages {
      height: 30px;
      width: 30px;
      display: inline-block;
      border-radius: 100%;
    }
    span {
      padding-left: 6px;
      span {
        color: #969696;
      }
    }
    .lastColumn {
      @include flexBoxJustifySpaceEvenly;
    }

    .sendReceiveBuy {
      cursor: pointer;
      color: var(--signup-text-color);
      height: 37.5px;
      width: 100px;
      border: 1px solid;
      border-color: var(--balance-table-send-border);
      border-radius: 20px;
      background-color: var(--balance-table-send-background);
      text-align: center;
      @include flexBoxJustifyCenter;
      img {
        height: 17px;
        width: 17px;
      }
      div {
        padding-left: 5px;
        color: var(--signup-text-color);
        font-size: 14px;
        letter-spacing: 0.04px;
        line-height: 19px;
      }
    }
  }
  .reciveIcon__dark{
    margin-top: 0px !important;
    height: 38px !important;
    width: 35px !important;
  }
  .reciveTitle__dark{
    margin-left: -10px;
  }

  .Balance_TableRow {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .Balance_TableRow:nth-child(odd) {
    background-color: var(--balance-table-odd-row);
  }
  .Balance_TableRow:nth-child(even) {
    background-color: var(--signup-model-bg);
  }
  .Balance_TableRow:last-child {
    border-bottom: none;
  }
}

.barParant {
  height: 6px;
  width: 170px;
  background-color: rgba(216, 216, 216, 0.52);
  border-radius: 8px;
}
.barChild {
  height: 100%;
  text-align: right;
  border-radius: 8px;
  background-color: #{$mainColor};
}
.loss__color {
  color: #ff0000 !important;
}
.profit__color {
  color: var(--gaineLoseTrand-percentages-clr) !important;
}
.font-bold {
  font-weight: bold !important;
}
.donout-chart-text {
  position: relative;
  top: -33%;

  letter-spacing: 0.3px;
  line-height: 26px;
  text-align: center;
}
.donout-chart-heading,
.wallet-history-chart-heading {
  color: var(--signup-text-color);
}
.wallet-history-chart-heading {
  font-size: 18px;
  font-weight: 400;
}
.donout-chart-heading {
  font-size: 25px;
  font-weight: bold;
}
.donout-chart-sub-heading {
  color: #7f7f7f;
  font-size: 16px;
}
.wallet-history-header {
  @include flexBoxSpaceBetween;
  padding: 1rem 2rem;
}
/* Transaction Table CSS */
.TR_table {
  background: var(--wallets-theme-background-color) !important;
  height: min-content;
  min-height: 90vh;
}

.transactionTable__container {
  max-width: 1550px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);

  .table__heading {
    @include flexBoxSpaceBetween;
    padding: 20px;
    border-radius: 4px 4px 0 0;
    background-color: var(--signup-model-bg);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .heading {
      color: var(--signup-text-color);
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 19px;
      i {
        cursor: pointer;
      }
      span {
        padding-left: 20px;
      }
    }
    .searchInput {
      height: 50px;
      width: 324px;
      border-radius: 25px;
      background-color: var(--table-switch-button-background);
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
      padding: 4px 15px;
      @include flexBoxSpaceBetween;

      input {
        width:100%;
        color: var(--signup-text-color);
        border: none;
        outline: none;
        font-size: 14px;
        letter-spacing: 1.26px;
        line-height: 27px;
        background-color: var(--table-switch-button-background);
      }
      input::placeholder {
        color: var(--transaction-filter-search-lebals);
      }

      img {
        height: 24.14px;
        width: 24.14px;
      }
    }
    .searchInputDark {
      input[type='text']::-webkit-input-placeholder {
        color: #ffffff;
      }
    }
  }
}

.seeAll__TR{
  background-color: var(--balance-table-odd-row);
  @include flexBoxJustifyCenter;
  cursor: pointer;
  color: #0091FF;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.05px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.text__color{
  padding-bottom: 15px;
  color: var(--signup-text-color) !important;
}
.TransactionTable {
  color: var(--signup-text-color);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 19px;
  border-top: none;
  .singleRow__data {
    .borderBottomNone {
      border-bottom: none !important;
    }
    .transactionRow {
      @include flexBoxSpaceBetween;
      height: 60px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0px 10px;
      cursor: pointer;

      .transaction_item_name {
        width: 12%;
        .USTImages {
          height: 30px;
          width: 30px;
          border-radius: 100%;
        }
        span {
          padding-left: 6px;
          span {
            color: #969696;
          }
        }
      }
      .transaction_item_status {
        width: 14%;
        .status__div {
          @include flexBox;
          .statusImageBackground {
            img {
              height: 30px;
              width: 30px;
            }
          }
          .status__type {
            padding-left: 15px;
            color: var(--signup-text-color);
            font-size: 14px;
            letter-spacing: 0.04px;
            line-height: 19px;
          }
        }
      }
      .transaction_item_amount {
        width: 18%;
      }
      .transaction_item__amountInUsd {
        width: 11%;
      }
      .transaction_item_date {
        width: 18%;
      }
      .transaction_item_state {
        width: 10%;
        .process__state {
          @include flexBox;
          align-items: center;

          img {
            height: 15px;
            width: 15px;
          }
          .process__state__name {
            padding-left: 5px;
            font-size: 14px;
            letter-spacing: 0.04px;
            line-height: 19px;
            text-align: center;
            text-transform: capitalize;
          }
          .completed {
            color: #239740;
          }
          .rejected {
            color: #bc3a3a;
          }
          .progress {
            color: #6e6e6e;
          }
          .verifiying {
            color: #ffa200;
          }
        }
      }
      .transaction_item_next {
        .next__arrow {
          height: 24px;
          width: 24px;
          opacity: 0.2;
          font-size: 25px;
          color: var(--signup-text-color);
        }
      }
    }

    .transactionRow:hover {
      background-color: var(--wallets-Histoty-hover);
      .transaction_item_next > .next__arrow {
        opacity: 10;
      }
    }
    .singleTR__row {
      padding: 0px 20px;
      color: var(--signup-text-color);
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background-color: var(--balance-table-odd-row);

      .title {
        padding: 10px 0px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.05px;
        line-height: 24px;
        span {
          font-size: 18px;
          font-weight: normal;
        }
      }
      .TR__address {
        @include flexBoxSpaceBetween;
        padding: 10px 0px;
        .from__address {
          .address__title {
            color: #848484;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
          }
          .address__data {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 22px;
            span {
              padding-left: 10px;
              height: 20px;
              width: 20px;

              color: #0091ff;
              cursor: pointer;
              i {
                font-weight: 900;
              }
            }
            span:hover{
              opacity: 0.6;
            }
          }
        }
      }
      .balanceAfterTR {
        padding: 20px 0px;
        .balanceAfterTR__heading {
          color: #848484;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 19px;
        }
        .balanceAfterTR__data {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 22px;
          span {
            opacity: 0.6;
          }
          i {
            padding-left: 10px;
            height: 20px;
            width: 20px;
            font-weight: 900;
            color: #0091ff;
            cursor: pointer;
          }
        }
      }
    }
  }
  .singleRow__data:last-child {
    border-bottom: none;
  }

  .singleRow__data:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
}
.singleRow__data {
}
.singleRow__data:nth-child(even) {
  background-color: var(--balance-table-odd-row);
}
.singleRow__data:nth-child(odd) {
  background-color: var(--signup-model-bg);
}
.singleRow__data:last-child {
  border-bottom: none;
}

/* B2C Transaction Filters */
.mercjhantTransactionBorder{
  border: 1px solid #f5f5f5 !important;
}
.b2ctransactionFilter {
  background-color: var(--balance-table-odd-row);
  @include flexBox;
  padding: 20px;
  height: 70px;

  .date__filter {
    padding-left: 28px;
    cursor: pointer;
    box-sizing: border-box;
    height: 51px;
    width: 301px;
    border: 1px solid var(--transaction-filter-box-border);
    border-radius: 25px;
    background-color: var(--transaction-filter-box);
    @include flexBoxSpaceBetween;
    padding: 0px 10px;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
    .filter__heding {
      color: var(--signup-text-color);
      font-size: 16px;
      letter-spacing: 0.04px;
      line-height: 22px;
    }
    .showFilter__arrow {
      color: var(--signup-text-color);
      margin-top: -10px;
      font-size: 24px;
      height: 24px;
      width: 24px;
      i {
        font-weight: 900;
      }
    }
  }
  .date__select {
    position: absolute;
    margin-top: 400px;
    margin-left: -10px;
    z-index: 1;

    .css-4l7j15 {
      background-color: var(--profile-menu-bg-color);
      box-shadow: 0 5px 24px 0 rgba(130, 135, 165, 0.34);
      border-radius: 4px;
      height: 312px;
    }
    .css-1tape97:not(:last-of-type) {
      border-right: 1px solid #d8d8d8;
    }
    .css-f7iyql {
      span {
        color: var(--transaction-filter-calendar-month);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
      }
    }
    .css-1n2mv2k {
      span {
        color: var(--transaction-filter-calendar-date);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
      }
    }

    button {
      color: var(--signup-text-color);
    }

    .css-1snvurg-MuiPickerStaticWrapper-root {
      background-color: var(--profile-menu-bg-color);
      box-shadow: 0 5px 24px 0 rgba(130, 135, 165, 0.34);
      border-radius: 4px;
      height: 312px;
      color: #ffff;
      .css-1tape97:not(:last-of-type) {
        border-right: 1px solid #d8d8d8;
      }
      .css-1n2mv2k {
        span {
          color: var(--transaction-filter-calendar-date);
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 14px;
          text-align: center;
        }
      }

      .css-f7iyql {
        span {
          color: var(--transaction-filter-calendar-month);
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center;
        }
      }

      button {
        color: var(--signup-text-color);
      }
    }
  }
  .coins__filter {
    margin-left: 20px;
    box-sizing: border-box;
    height: 51px;
    width: 301px;
    border: 1px solid var(--transaction-filter-box-border);
    border-radius: 25px;
    background-color: var(--transaction-filter-box);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    .heading___icon {
      cursor: pointer;
      @include flexBoxSpaceBetween;
      padding: 12px 10px;
      .heding {
        color: var(--signup-text-color);
        font-size: 16px;
        letter-spacing: 0.04px;
        line-height: 22px;
        padding-left: 10px;
      }
      .arrow {
        margin-top: -8px;
        color: var(--signup-text-color);
        font-size: 24px;
        height: 24px;
        width: 24px;
        i {
          font-weight: 900;
        }
      }
    }

    .showDropdown {
      border-radius: 25px 25px 0 0;
      background-color: var(--profile-menu-bg-color);
      box-shadow: var(--transaction-filter-dataBox-border);
      z-index: 1;
      position: relative;
      margin-top: -49px;
      .showCoinDropdown__heading {
        cursor: pointer;
        @include flexBoxSpaceBetween;
        padding: 15px 10px;
        .heading__name {
          color: var(--signup-text-color);
          font-size: 16px;
          letter-spacing: 0.04px;
          line-height: 22px;
          padding-left: 10px;
        }
        .dropdown__icon {
          margin-top: -11px;
          font-size: 24px;
          height: 24px;
          width: 24px;
          color: var(--signup-text-color);
          i {
            font-weight: 900;
          }
        }
      }
      .search__box {
        @include flexBoxSpaceBetween;
        height: 40px;
        width: 100%;
        border-radius: 4px;
        background-color: var(--transaction-filter-search-background);
        padding: 0px 20px;
        color: var(--signup-text-color);

        input {
          color: var(--signup-text-color);
          background-color: var(--transaction-filter-search-background);
          font-size: 14px;
          letter-spacing: 0.04px;
          line-height: 19px;
          border: none;
          outline: none;
        }
        i {
          
        }
      }
      .allCrypto__rows {
        overflow-y: scroll;
        .coins__rowData {
          @include flexBoxSpaceBetween;
          height: 30px;
          padding: 25px 20px;
          cursor: pointer;
          .rowData__name__image {
            img {
              height: 30px;
              width: 30px;
              display: inline-block;
              border-radius: 100%;
            }
            span {
              padding-left: 10px;
              color: var(--signup-text-color);
              font-size: 14px;
              letter-spacing: 0.04px;
              line-height: 19px;
            }
          }
          .CryptoCheckBox {
            height: 14px;
            width: 14px;
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
      }
      .allCrypto__rows::-webkit-scrollbar {
        width: 10px;
      }
      .TransactionDtls_cards_Scroll::-webkit-scrollbar-track {
        background: transparent;
      }
      .allCrypto__rows::-webkit-scrollbar-thumb {
        width: 10px;
        border-radius: 6px;
        background-color: #{$mainColor};
      }
      .allCrypto__rows::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .refresh__and__selectAll {
        height: 46px;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
        .refreshSelectAll {
          @include flexBoxSpaceBetween;
          padding: 2px 20px;
          .refresh {
            cursor: pointer;
            box-sizing: border-box;
            height: 34px;
            width: 34px;
            border: 1px solid #{$mainColor};
            border-radius: 17px;
            background-color: #{$mainColor};
            color: #ffff;
            align-items: center;
            margin-right: 5px;
            .refresh__icon {
              padding-left: 8px;
              padding-top: 5.5px;
              height: 15px;
              width: 19.29px;
            }
            i {
            }
          }
          .selectAll {
            cursor: pointer;
            box-sizing: border-box;
            height: 34px;
            width: 230px;
            border: 2px solid #0091ff;
            border-radius: 25px;
            background-color: var(--profile-menu-bg-color);
            color: var(--signup-text-color);
            align-items: center;

            h1 {
              margin-top: 5px;
              font-size: 13px;
              letter-spacing: 0.04px;
              line-height: 18px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.send__coins{
  color: var(--signup-text-color) !important;
}
.recive__coin{
  color:#39AD00 !important;
}
.reject__coin{
  color: #888888 !important;
}

.dropdown-container {
  position:absolute;
  background-color:var(--trending-dropdown-background);
  border-radius: 9px;
  cursor: pointer;
}

.dropdown-chosen {
  cursor: pointer;
}

.dropdown-child {
  border-bottom: 1px solid black;
  padding:  0px 12px;
  text-align: center;
  height: 30px;
}

.negative-value-icon-style {
  transform: rotate(180deg);
  color:red !important;
  position: relative;
  bottom: 5px;
}
.negative-value-color {
  color:red !important
}
.wallets-history-date-select {
  position: absolute;
  // margin-top: -300px;
  // margin-left: -75px;
  // z-index: 1;

  .css-4l7j15 {
    background-color: var(--profile-menu-bg-color);
    box-shadow: 0 5px 24px 0 rgba(130, 135, 165, 0.34);
    border-radius: 4px;
    height: 312px;
  }
  .css-1tape97:not(:last-of-type) {
    border-right: 1px solid #d8d8d8;
  }
  .css-f7iyql {
    span {
      color: var(--transaction-filter-calendar-month);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
  }
  .css-1n2mv2k {
    span {
      color: var(--transaction-filter-calendar-date);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
    }
  }

  button {
    color: var(--signup-text-color);
  }

  .css-1snvurg-MuiPickerStaticWrapper-root {
    background-color: var(--profile-menu-bg-color);
    box-shadow: 0 5px 24px 0 rgba(130, 135, 165, 0.34);
    border-radius: 4px;
    height: 312px;
    color: #ffff;
    .css-1tape97:not(:last-of-type) {
      border-right: 1px solid #d8d8d8;
    }
    .css-1n2mv2k {
      span {
        color: var(--transaction-filter-calendar-date);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
      }
    }

    .css-f7iyql {
      span {
        color: var(--transaction-filter-calendar-month);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
      }
    }

    button {
      color: var(--signup-text-color);
    }
  }
}
.merchant_name{
  padding-left: 15px;
  opacity: 0.5;
  font-size: 12px;
}
.balancesMobileViewDailogue {
  display: none;
}
.balances-mobile-view {
  color: var(--signup-text-color);
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
}
.balancesDataRow {
  height: 63px;
  padding: 0px;
  border-bottom: 2px solid var(--wallet-portfolio-border);
  @include flexBoxSpaceBetween;
}
.sendReceiveBuyMobileView {
  cursor: pointer;
  color: var(--signup-text-color);
  height: 37.5px;
  border: 1px solid;
  border-color: var(--balance-table-send-border);
  border-radius: 20px;
  background-color: var(--balance-table-send-background);
  text-align: center;
  padding: 22px;
  margin: 20px;
  @include flexBoxJustifyCenter;
  img {
    height: 17px;
    width: 17px;
  }
  div {
    padding-left: 5px;
    color: var(--signup-text-color);
    font-size: 14px;
    letter-spacing: 0.04px;
    line-height: 19px;
  }
}
@media only screen and (min-width: 350px) and (max-width: 1024px) {
  .balancesMobileViewDailogue {
    display: block;
  }
  .wallet-container {
    padding: 0;
    padding-top: 76px !important;
    padding-left: 6.5px;
    padding-right: 6.5px;
    .wallet {
      width: 100%;
      display: block;
      padding: 0;
    }
    .wallet__portfolio, .wallet__history, .wallet__transaction__history {
      width: 100%;
      margin: 0;
      margin-bottom: 16px;
      height: auto;
    }
    .wallet__portfolio {
      padding: 20px 14px;
      .wallet-assests {
          margin-top: 19.5px;
          position: relative;
          padding-top: 0px !important;
        }
    }
    .donought-chart-wrap {
      width: 100px;
      height: 100px;
    }
    .walletPorfolioContent {
      @include flexBoxSpaceBetween;
      width: 100%;
      padding-bottom: 19.5px;
      .balanceTextWrap {
        @include flexBox;
        flex-direction: column;
        align-items: start;
        letter-spacing: 0;
        width: 100%;
        .totalBalanceTitle {
          height: 22px;
          color: var(--address-button-color);
          font-size: 18px;
          line-height: 22px;
        }
        .totalBalanceUsd {
          color: var(--address-button-color);
          height: 40px;
          font-size: 33px;
          font-weight: bold;
          line-height: 40px;
        }
        .totalBalanceBtc {
          height: 19px;
          color: #7C7C7C;
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
        }
      }
      border-bottom: 1px solid var(--wallet-portfolio-border);
    }
  }
  .sectionTable {
    padding-left: 6.5px;
    padding-right: 6.5px;
    padding-bottom: 50px;
    max-width: 100vw;
    margin: 0px;
    .switchAndTable {
      margin: 0px;
      margin-bottom: 16px;
    }
  }
  .gaineLoseTrand { 
    display: block;
    margin: 0px;
    margin-bottom: 16px;
    .BiggestGainers {
      margin: 0px;
      height: auto;
    }
  }
  .gaineLoseTrand > :not(:last-child) { 
    margin-bottom: 16px;
  }
  .buyReceiveSend {
    display: block;
    margin: 0px;
    .buy {
      margin: 0px;
      height: 100px;
      width: 100%;
      border-radius: 4px;
      .buy__content {
        @include flexBoxSpaceBetween;
        padding: 20px 19px 20px 13px;
        .content__image {
          margin-right: 10px;
        }
        .buyConentTextWrap {
          @include flexBoxJustifyStart;
          width: 100%;
          flex-grow: 1;
          flex-direction: column;
          align-items: flex-start;
          .buyText {
            padding: 0;
            margin-bottom: 7px;
          }
        }
        .OpenArrow {
          height: 24px;
          width: 24px;
          color: #8287A5;
          @include flexBoxJustifyCenter;
          font-size: 25px;
          opacity: 0.8;
        }
      }
    }
  }
  .buyReceiveSend > :not(:last-child) { 
    margin-bottom: 16px;
  }
  .ScrollBarStyle {
    max-height: fit-content;
    padding-left: 0px;
    padding-right: 0px;
  }
  .transaction__history .history__coin__value .forward__arrow {
    margin-left: 5px;
    width: 24px;
    height: 24px;
    padding-left: 0px;
    @include flexBoxJustifyCenter;
    i {
      margin-top: 8px;
      width: initial;
      height: initial;
      opacity: 0.2;
      font-size: 25px;
      color: var(--signup-text-color);
    }
  }
  .transaction__history .history__coin__value {
    padding-bottom: 17px;
    margin-top: -5px;
  }
  .switchAndTable {
    padding-left: 13.5px;
    padding-right: 13.5px;
    justify-content: center;
  }
  .SingleBalancesRowWrap {
    width: 100%;
    @include flexBoxSpaceBetween;
    border-bottom: 1px solid var(--wallet-portfolio-border);
    cursor: pointer;
      .SingleBalancesRow {
        width: 100%;
        color: var(--signup-text-color);
        height: 90.5px;
        margin-top: 12.5px;
        .balancesFirstRow {
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 19px;
          text-align: right;
          @include flexBoxSpaceBetween;
          .bicoinIconWrap {
            @include flexBoxJustifyStart;
            .USTImages {
              height: 30px;
              width: 30px;
              margin-right: 10px;
              border-radius: 100%;
            }
          }
          .price {
            flex-grow: 1;
          }
        }
        .balancesSecondRow {
          width: 100%;
          @include flexBoxSpaceBetween;
          margin-top: 5px;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
        }

        .balancesThirdRow {
          width: 100%;
          @include flexBoxSpaceBetween;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
      .forward__arrow {
        padding-left: 30px;
        i {
          width: 24px;
          height: 24px;
          opacity: 1;
          font-size: 25px;
          color: var(--signup-text-color);
        }
      }
  }
  .transaction__history {
    padding-left: 20px;
    padding-right: 7px;
  }
  .wallet-history-header { 
    padding: 14px;
  }
  .sectionTable .switchAndTable .switchAndSearch {
    justify-content: center;
  }
  .TransactionTable {
    .transaction__history {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .gaineLoseTrandWrap {
    width: 100%;
    border-radius: 4px;
    background-color: var(--signup-model-bg);
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.2);
    padding: 20px 15px;
    margin-bottom: 16px;
    .switchButtonWrap {
      width: 100%;
      max-width: 322px;
      height: 40px;
      @include flexBoxJustifyCenter;
      background-color: var(--table-switch-button-background);
      box-shadow: 0 0 7px 0 rgba(0,0,0,0.2);
      border-radius: 20px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 9px;
      .switchBtn {
        height: 40px;
        @include flexBoxJustifyCenter;
        width: 100%;
        border-radius: 20px;
        color: var(--signup-text-color);
        background-color: transparent;
        cursor: pointer;
      }
      .activeBtn {
        color: #ffff;
        background-color: #0091FF;
      }
    }
  }
  .sectionTable .switchAndTable .switchAndSearch .switchButton {
    height: 40px;
    border-radius: 20px;
  }
  .sectionTable .switchAndTable .switchAndSearch .switchButton .activeButton {
    height: 40px;
    border-radius: 20px;
  }

  .nohistorycomponent {
    padding-bottom: 50px;
  }
  .dataRow {
    height: 63px;
    padding: 0px;
    border-bottom: 1px solid var(--wallet-portfolio-border);
    @include flexBox;
  }
  .transaction__Detail__model {
    .details__model_mobile_view {
      width: unset;
    }
    .transaction__Details {
      height: 100vh;
    }
  }
  .balances-mobile-view {
    height: 100vh;
  }
 

 

  .wallets-history-date-select{
    // right: 5%;
    // top: 470px;
    // margin-left:0px;
    // margin-top: 0px;
    .css-1f7g2m7{
      display: none;
    }
    .css-4l7j15{
      color:var(--signup-text-color);
    }
  }

  .transaction_mobile{
    .heading{
      font-size: 16px !important;
    }
  }
  .transaction_filters{
    color:var(--signup-text-color);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    i{
      font-size: 14px;
    }

    span{
      padding-left: 20px;
    }
  }

  .mobile_transaction_content{
    margin-top: 25px;
    
    .b2ctransactionFilter{
      padding: 0px;
      background-color: transparent;
      display: block;

      .date__filter, .coins__filter{
        box-shadow: none;
        width: 100% !important;
        margin-left: 0px;
        margin: 20px 0px;
      }
    }
    .mobile_date_filter{
      color:var(--signup-text-color);
      @include flexBoxJustifyCenter;
    }
    .calendar_img{
      position: absolute;
      background: lightblue;
      padding: 5px 10px;
      border-radius: 25px;
      i{
        color: blue;
      }
    }
    .mobile_date_select {
      top: 214px !important;
      right: auto;
    }

    .mobile_status_filter{
      margin-top: 15px;
      color:var(--signup-text-color);
      h1{
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      // margin-bottom: 20px;
      }

      .name_wrap{
        @include flexBoxSpaceBetween;
        flex-wrap: wrap;
        margin-top: 10px;

        .status_name{
          margin-top: 10px;
          text-transform: capitalize;
          border-radius: 8px;
          background-color: var(--signup-model-bg);
          box-shadow: 0 0 7px 0 rgba(0,0,0,0.2);
          padding: 10px 15px;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 17px;
          margin-right: 5px;
        }
      }
    }
    .filter_button{
      margin-top: 35px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      color:#ffff;
      height: 42px;
      border-radius: 25px;
      background-color: #0091FF;
      @include flexBoxJustifyCenter;
    }

    .activeFilterBtn{
      background-color: #0091FF !important;
      box-shadow: 0 0 7px 0 rgba(0,0,0,0.2) !important;
    }
  }
  .showMore_mobile_wrap{
    background-color: var(--balance-table-odd-row);
    height: 60px;
    @include flexBoxJustifyCenter;
    color: #0091FF;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    .showMore_mobile{

    }
  }
  .transactionTable__container{
    margin-bottom: 0px;
  }
  .clear_transaction_filter{
    position: absolute;
    right: 20px;
    border: 1px solid #0091FF;
    border-radius: 4px;
    text-align: center;
    padding: 5px 18px;
    margin-top: -5px;
    font-size: 14px;
    letter-spacing: 0.04px;
    line-height: 19px;
  }

}