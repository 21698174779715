.getStarted {
	background-color: rgba(3, 5, 21, 1);
	background-image: url('https://dev.junomoney.org/images/upd/stars.webp');
	background-size: 170px 170px;
	background-repeat: repeat;
	background-attachment: fixed;
	&__grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 130px;
		margin-bottom: 60px;
	}
	&__block {
		.section-text {
			opacity: .7;
			min-height: 90px;
		}
	}
	&__num {
		width: 60px;
		height: 60px;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 100%;
		border: 1px solid rgba(255, 255, 255, 0.1);
		border-radius: 30px;
		color: #0091FF;
		margin: 0 auto 32px;
		font-weight: 600;
		font-family: "Inter", sans-serif;
	}
	&__title {
		font-family: "Inter", sans-serif;
		font-size: 24px;
		line-height: 130%;
		text-align: center;
		margin-bottom: 13px;
	}
	&__card {
		margin-top: 32px;
		width: 100%;
		height: auto;
	}
}

@media screen and (max-width: 1300px) {
	.getStarted__title {
		font-size: 21px;
	}
	.getStarted__block .section-text {
		min-height: 77px;
	}
	.getStarted__grid {
		grid-gap: 90px;
	}
}

@media screen and (max-width: 991px) {
	.getStarted__grid {
		grid-gap: 50px;
		grid-template-columns: 1fr;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
	.getStarted__block .section-text {
		min-height: initial;
	}
	.getStarted__num {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 600px) {
	.getStarted__title {
		font-size: 19px;
		margin-bottom: 8px;
	}
}