.jn-cmt {
	position: relative;
	padding-top: 140px;
	z-index: 2;
	&-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 24px;
	}
	&-item {
		border-radius: 16px;
		box-shadow: 0px 0px 64px 0px rgba(255, 255, 255, 0.24) inset, 0px 2px 4px 0px rgba(255, 255, 255, 0.24) inset;
		background: rgba(255, 255, 255, 0.08);
		padding: 64px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: relative;
		backdrop-filter: blur(20px);
		p {
			color:#EAECF0;
			text-align: center;
			font-size: 24px;
			font-weight: 500;
			line-height: 120%;
		}
	}
	&-icon {
		height: 80px;
		width: auto;
		margin: 0 auto 32px;
	}
}

@media screen and (max-width: 1300px) {
	.jn-cmt-item {
		padding: 45px 40px;
	}
	.jn-cmt-item p {
		font-size: 21px;
	}
}

@media screen and (max-width: 991px) {
	.jn-cmt-item p {
		font-size: 18px;
	}
	.jn-cmt {
		padding-top: 65px;
	}
	.jn-cmt-item {
		padding: 45px 35px;
	}
}

@media screen and (max-width: 860px) {
	.jn-cmt-grid {
		grid-template-columns: repeat(2, 1fr);
	}
	.jn-cmt-item {
		padding: 32px 24px;
		p {
			br {
				display: none;
			}
		}
	}
	.jn-cmt-icon {
		height: 48px;
		margin-bottom: 24px;
	}
}

@media screen and (max-width: 760px) {
	.jn-cmt {
		background-size: 760px auto;
	}
}

@media screen and (max-width: 600px) {
	.jn-cmt {
		background-size: 600px auto;
		background-position-y: top;
		background-position-x: 68%;
	}
}

@media screen and (max-width: 510px) {
	.jn-cmt-grid {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 430px) {
	.jn-cmt {
		background-position-x: 84%;
	}
}