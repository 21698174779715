@import './_mixins.scss';

.merchant__home__lightMood {
  background-color: var(--content-bg-color);
}

.CP_Home_mainBG {
  // background: radial-gradient(circle, #0F1029 0%, #000000 100%);
  color: var(--content-merchent-text-color);
}
.CP_Home_main_bg {
  min-height: calc(100vh - 240px);
  width: 100%;
  // background-image: linear-gradient(to right, rgba(0, 145, 255, 0.1), rgba(255, 0, 0, 0));
  padding-bottom: 75px;
}
.CP_Home_all_content {
  position: relative;
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}
.CP_Home_main {
  color: var(--content-merchent-text-color);
  min-height: calc(100vh - 240px);
  background-position-y: -215px;
  background-repeat: no-repeat;
  padding-top: 90px;
  .CP_home_content_wrap {
    color: var(--content-merchent-text-color);
    padding-top: 103px;
    @include flexBox;
    align-items: flex-start;
    width: calc(100vw - 15%);
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    .CP_home_left_partition {
      @include flexBox;
      flex-direction: column;
      max-width: 690px;
      margin-top: 33px;
      min-height: 600px;
      z-index: 1;
      margin-left: -60px;

      .CP_paymentModeHeading {
        min-height: 126px;
        max-width: 588px;
        color: #010001;
        margin-bottom: 16px;
        color: var(--content-merchent-text-color);
        font-size: 52px;
        font-weight: bold;
        letter-spacing: 0.14px;
        line-height: 63px;
        .CP_paymentModeHeading_wrap {
          @include flexBoxJustifyStart;
          color: var(--content-merchent-text-color);
          .CP_paymentModeHeading_bitcoin {
            color: #{$mainColor};
            display: inline-block;
            padding-right: 15px;
            min-width: 263px;
            @include flexBoxJustifyStart;
            .dynamic-txts {
              height: 63px;
              overflow: hidden;
              margin-bottom: 0;
              padding-left: 0;
              margin-top: 3px;
              animation-name: typingHide;
              animation-duration: 3s;
              animation-iteration-count: infinite;
              white-space: nowrap;
              animation-timing-function: steps(11, end);
            }
            @keyframes typingHide {
              0% {
                width: 7px;
              }
              40% {
                left: calc(100% + 30px);
              }
              50% {
                width: 290px;
              }
              60% {
                left: calc(100% + 30px);
              }
              80% {
                left: calc(100% + 30px);
              }
              100% {
                left: 0;
                width: 7px;
              }
            }
            @keyframes typingBorder {
              0%,
              5% {
                border-right: 7px solid rgba(0, 145, 255, 0);
              }
              10%,
              15% {
                border-right: 7px solid;
              }
              20%,
              25% {
                border-right: 7px solid rgba(0, 145, 255, 0);
              }
              30%,
              35% {
                border-right: 7px solid;
              }
              40%,
              45% {
                border-right: 7px solid rgba(0, 145, 255, 0);
              }
              50%,
              55% {
                border-right: 7px solid;
              }
              60%,
              65% {
                border-right: 7px solid rgba(0, 145, 255, 0);
              }
              70%,
              75% {
                border-right: 7px solid;
              }
              80%,
              85% {
                border-right: 7px solid rgba(0, 145, 255, 0);
              }
              90%,
              95% {
                border-right: 7px solid;
              }
              100% {
                border-right: 7px solid rgba(0, 145, 255, 0);
              }
            }
            .dynamic-txts li {
              list-style: none;
              position: relative;
              top: 0;
              max-width: fit-content;
              border-right: 7px solid;
              animation-name: slide, typingBorder;
              border-color: rgb(0, 145, 255);
              animation-duration: 12s, 4s;
              animation-iteration-count: infinite;
              animation-timing-function: steps(4), steps(11, end);
            }
            @keyframes slide {
              100% {
                top: -252px;
              }
            }
            .dynamic-txts li span {
              position: relative;
              line-height: 63px;
              overflow: hidden;
            }
          }
        }
      }
      .CP_advantages_list_wrap {
        @include flexBoxJustifyStart;
        flex-direction: column;
        width: 100%;
        margin-bottom: 35px;
        .home_single_advantage_wrap {
          @include flexBoxJustifyStart;
          width: 100%;
          margin-bottom: 5px;
          .home_single_advantage_icom {
            height: 18px;
            width: 18px;
            font-family: FontAwesome;
            font-size: 20px;
            letter-spacing: 0.05px;
            line-height: 18px;
            margin-right: 12.25px;
          }
          .home_single_advantage_name,
          .home_single_advantage_description {
            color: var(--content-merchent-text-color);
            font-size: 16px;
            letter-spacing: 1.35px;
            line-height: 31px;
          }
          .home_single_advantage_name {
            font-weight: bold;
          }
          animation-duration: 6s;
        }
        @keyframes listItemUp0 {
          0% {
            transform: translateY(30px);
            opacity: 0;
          }
          20% {
            transform: translateY(30px);
            opacity: 0;
          }
          70% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        @keyframes listItemUp1 {
          0% {
            transform: translateY(30px);
            opacity: 0;
          }
          30% {
            transform: translateY(30px);
            opacity: 0;
          }
          80% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        @keyframes listItemUp2 {
          0% {
            transform: translateY(30px);
            opacity: 0;
          }
          40% {
            transform: translateY(30px);
            opacity: 0;
          }
          90% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        @keyframes listItemUp3 {
          0% {
            transform: translateY(30px);
            opacity: 0;
          }
          50% {
            transform: translateY(30px);
            opacity: 0;
          }
          100% {
            transform: translateY(0px);
            opacity: 1;
          }
        }
      }
    }
    .CP_home_right_partition {
      max-width: 1000px;
      min-width: 500px;
      height: 600px;
      width: 53%;
      position: absolute;
      right: 0;
      z-index: 0;
      margin-right: 30px;
      .CP_home_rightImage {
        max-width: 1000px;
        min-width: 500px;
        width: 100%;
        height: 600px;
        background-position-x: 0px;
        background-repeat: no-repeat;
        background-size: 900px;
        animation-name: SlideInRight1;
        animation-duration: 3s;
        margin-left: 26%;
      }
      @keyframes SlideInRight1 {
        0% {
          transform: translateX(200px);
          opacity: 0;
        }
        20% {
          transform: translateX(200px);
          opacity: 0;
        }
        100% {
          transform: translateX(0px);
          opacity: 1;
        }
      }
    }
  }
  .whyPaymentPayWrap {
    max-width: 1440px;
    width: calc(100vw - 15%);
    @include flexBox;
    flex-direction: column;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    .WCP_aboutUs {
      min-height: 17px;
      width: 100%;
      color: #{$mainColor};
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.04px;
      line-height: 17px;
      text-align: center;
      @include flexBoxJustifyCenter;
      margin-bottom: 18px;
    }
    .WCP_heading {
      min-height: 38px;
      width: 100%;
      color: var(--content-merchent-text-color);
      font-size: 32px;
      font-weight: bold;
      letter-spacing: 0.08px;
      line-height: 38px;
      @include flexBoxJustifyCenter;
      margin-bottom: 61px;
      text-transform: uppercase;
    }
    .WCP_cryptoFeatureListWrap {
      @include flexBoxJustifyCenter;
      width: 100%;
      .home_single_cryptoFeature_wrap {
        padding: 26px 30px 22px 32.5px;
        min-height: 227px;
        max-width: 384px;
        width: 100%;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.08);
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 2px 14px 0 rgba(0, 0, 0, 0.14);
        .home_single_cryptoFeature_icon {
          height: 85px;
          width: 85px;
          margin-left: -21px;
          margin-bottom: -28px;
        }
        .home_single_cryptoFeature_name {
          min-height: 21px;
          margin-top: 13px;
          margin-bottom: 17px;
          width: 100%;
          color: var(--content-merchent-text-color);
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
          @include flexBoxJustifyStart;
        }
        .home_single_cryptoFeature_description {
          min-height: 76px;
          width: 100%;
          color: var(--content-merchent-text-color);
          font-size: 16px;
          letter-spacing: 1.24px;
          line-height: 19px;
        }
        animation-duration: 2s;
        animation-name: cardUp1;
      }
      @keyframes cardUp1 {
        0% {
          transform: translateY(150px);
          opacity: 0;
        }
        50% {
          transform: translateY(150px);
          opacity: 0;
        }
        100% {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
    .WCP_cryptoFeatureListWrap > .home_single_cryptoFeature_wrap:not(:last-child) {
      margin-right: 24px;
    }
  }
  .homeDeveloperToolWrap {
    @include flexBoxSpaceBetween;
    width: 100%;
    max-width: 1440px;
    width: calc(100vw - 15%);
    margin-top: 165px;
    margin-left: auto;
    margin-right: auto;
    .HDTLeftPartionWrap {
      @include flexBox;
      flex-direction: column;
      width: 100%;
      max-width: 385px;
      min-height: 449px;
      margin-right: 10px;
      z-index: 1;
      .HDT_aboutSDK {
        min-height: 27px;
        width: 100%;
        color: #{$mainColor};
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.35px;
        line-height: 27px;
        @include flexBoxJustifyStart;
      }
      .HDT_heading {
        min-height: 38px;
        width: 100%;
        color: var(--content-merchent-text-color);
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0.08px;
        line-height: 38px;
        @include flexBoxJustifyStart;
        margin-top: 14px;
      }
      .HDT_descryption {
        min-height: 81px;
        width: 100%;
        color: var(--content-merchent-text-color);
        font-size: 18px;
        letter-spacing: 1.35px;
        line-height: 27px;
        margin-bottom: 30px;
        margin-top: 17px;
      }
    }
    .HDTRightPartionWrap {
      width: 100%;
      flex-grow: 1;
      max-width: fit-content;
      @include flexBox;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      right: 0px;
      .HDTRightPartion_Img {
        height: 449px;
        margin-top: -15px;
      }
      animation-name: SlideInRight2;
      animation-duration: 2.5s;
      z-index: 0;
    }
    @keyframes SlideInRight2 {
      0% {
        transform: translateX(200px);
        opacity: 0;
      }
      60% {
        transform: translateX(200px);
        opacity: 0;
      }
      100% {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }
  .GST_main {
    max-width: 1440px;
    width: calc(100vw - 15%);
    margin-left: auto;
    margin-right: auto;
    .GST_main_wrap {
      margin-top: 104px;
      min-height: 213px;
      max-width: 1440px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #{$mainColor};
      background: radial-gradient(circle, #0f1029 0%, #000000 100%);
      padding: 29px 48px 43px 45px;
      background-repeat: no-repeat;
      background-position-y: bottom;
      background-size: 41%;
      background-position-x: right;
      @include flexBoxSpaceBetween;
      .GST_left_wrap {
        @include flexBoxJustifyStart;
        flex-direction: column;
        width: 100%;
        max-width: 771px;
        .GST_daily_transactions {
          min-height: 27px;
          width: 100%;
          color: #{$mainColor};
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1.35px;
          line-height: 27px;
          @include flexBoxJustifyStart;
        }
        .GST_heading {
          min-height: 38px;
          width: 100%;
          color: var(--content-merchent-text-color);
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 0.08px;
          line-height: 38px;
          margin-top: 7px;
          margin-bottom: 15px;
          text-transform: uppercase;
          @include flexBoxJustifyStart;
        }
        .GST_description {
          min-height: 54px;
          width: 100%;
          color: var(--content-merchent-text-color);
          font-size: 18px;
          letter-spacing: 1.35px;
          line-height: 27px;
          @include flexBoxJustifyStart;
        }
      }
    }
    animation-duration: 3s;
    animation-name: cardUp2;
  }
  @keyframes cardUp2 {
    0% {
      transform: translateY(200px);
      opacity: 0;
    }
    80% {
      transform: translateY(200px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .CP_home_getStarted_wrap {
    height: 60px;
    @include flexBoxJustifyStart;
    width: 100%;
    .CP_home_getStarted {
      @include flexBoxJustifyCenter;
      height: 60px;
      width: 187px;
      border-radius: 30px;
      white-space: nowrap;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      color: #{$mainColor};
      cursor: pointer;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 1.35px;
      line-height: 27px;
      text-align: center;
    }
    .CP_home_getStarted:hover,
    .CP_home_getStarted:focus {
      background-color: #{$mainHoverColor};
      box-shadow: 0 0 24px 0 rgba(0, 145, 255, 0.5);
      text-shadow: 0px 2px 17px #ffffff;
    }
  }
}

.HDT_mobileImage {
  display: none;
}

@media only screen and (max-width: 1780px) {
  .HDTRightPartion_Img {
    margin-right: -76px;
  }
}

@media only screen and (max-width: 1600px) {
  .subContainer {
    width: calc(100vw - 15%) !important;
  }
}

@media only screen and (max-width: 1500px) {
  .whyPaymentPayWrap,
  .CP_home_content_wrap,
  .homeDeveloperToolWrap,
  .GST_main {
    width: calc(100vw - 10%) !important;
  }
  .subContainer {
    width: calc(100vw - 10%) !important;
  }
}

@media only screen and (max-width: 1400px) {
  .whyPaymentPayWrap,
  .CP_home_content_wrap,
  .homeDeveloperToolWrap,
  .GST_main,
  .subContainer {
    width: calc(100vw - 60px) !important;
  }
}

@media only screen and (max-width: 480px) {
  .CP_Home_main {
    padding-top: 70px;
    background-position-y: -222px;
    background-position-x: center;
    .CP_home_content_wrap {
      padding-top: 40px;
      .CP_home_left_partition {
        .CP_paymentModeHeading {
          .CP_paymentModeHeading_wrap {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
  .CP_home_rightImage {
    display: none;
  }
  .whyPaymentPayWrap,
  .CP_home_content_wrap,
  .homeDeveloperToolWrap,
  .GST_main,
  .subContainer {
    width: calc(100vw - 32px) !important;
  }
  .CP_Home_main .CP_home_content_wrap .CP_home_left_partition .CP_advantages_list_wrap {
    margin-bottom: 50px;
  }
  .CP_Home_main .CP_home_content_wrap .CP_home_left_partition {
    align-items: flex-start;
  }
  .CP_Home_main .CP_home_content_wrap .CP_home_left_partition .CP_advantages_list_wrap .home_single_advantage_wrap .home_single_advantage_name,
  .CP_Home_main .CP_home_content_wrap .CP_home_left_partition .CP_advantages_list_wrap .home_single_advantage_wrap .home_single_advantage_description {
    font-size: 14px;
    letter-spacing: 1.18px;
    line-height: 31px;
  }
  .CP_Home_main .CP_home_content_wrap .CP_home_left_partition .CP_paymentModeHeading {
    font-size: 48px;
    letter-spacing: 0.13px;
    line-height: 58px;
  }
  .CP_Home_main
    .CP_home_content_wrap
    .CP_home_left_partition
    .CP_paymentModeHeading
    .CP_paymentModeHeading_wrap
    .CP_paymentModeHeading_bitcoin
    .dynamic-txts
    li
    span {
    line-height: 58px;
  }
  @keyframes slide {
    100% {
      top: -232px;
    }
  }
  .CP_Home_main .CP_home_content_wrap .CP_home_left_partition .CP_paymentModeHeading .CP_paymentModeHeading_wrap .CP_paymentModeHeading_bitcoin .dynamic-txts {
    height: 58px;
  }
  .CP_Home_main
    .CP_home_content_wrap
    .CP_home_left_partition
    .CP_paymentModeHeading
    .CP_paymentModeHeading_wrap
    .CP_paymentModeHeading_bitcoin
    .dynamic-txts
    li {
    border-right: 6px solid;
  }
  @keyframes typingBorder {
    0%,
    5% {
      border-right: 6px solid rgba(0, 145, 255, 0);
    }
    10%,
    15% {
      border-right: 6px solid rgba(0, 145, 255, 1);
    }
    20%,
    25% {
      border-right: 6px solid rgba(0, 145, 255, 0);
    }
    30%,
    35% {
      border-right: 6px solid rgba(0, 145, 255, 1);
    }
    40%,
    45% {
      border-right: 6px solid rgba(0, 145, 255, 0);
    }
    50%,
    55% {
      border-right: 6px solid rgba(0, 145, 255, 1);
    }
    60%,
    65% {
      border-right: 6px solid rgba(0, 145, 255, 0);
    }
    70%,
    75% {
      border-right: 6px solid rgba(0, 145, 255, 1);
    }
    80%,
    85% {
      border-right: 6px solid rgba(0, 145, 255, 0);
    }
    90%,
    95% {
      border-right: 6px solid rgba(0, 145, 255, 1);
    }
    100% {
      border-right: 6px solid rgba(0, 145, 255, 0);
    }
  }
  .CP_Home_main .CP_home_content_wrap .CP_home_left_partition .CP_paymentModeHeading {
    margin-bottom: 20px;
  }
  .CP_Home_main .whyPaymentPayWrap .WCP_aboutUs {
    justify-content: flex-start;
  }
  .CP_Home_main .whyPaymentPayWrap .WCP_cryptoFeatureListWrap > .home_single_cryptoFeature_wrap:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 45px;
  }
  .CP_Home_main .whyPaymentPayWrap .WCP_cryptoFeatureListWrap .home_single_cryptoFeature_wrap {
    max-width: initial;
  }
  .CP_Home_main .whyPaymentPayWrap .WCP_cryptoFeatureListWrap {
    flex-direction: column;
  }
  .CP_Home_main .homeDeveloperToolWrap .HDTRightPartionWrap {
    display: none;
  }
  .CP_Home_main .whyPaymentPayWrap .WCP_heading {
    margin-bottom: 35px;
    @include flexBoxJustifyStart;
  }
  .CP_Home_main .homeDeveloperToolWrap {
    margin-top: 100px;
  }
  .CP_Home_main .homeDeveloperToolWrap .HDTLeftPartionWrap .HDT_descryption {
    margin: 20px 0px 47px;
  }
  .CP_Home_main .homeDeveloperToolWrap .HDTLeftPartionWrap {
    margin-right: 0px;
    max-width: initial;
  }
  .CP_footer_main .CP_footer_content_wrap .CP_copyright_wrap .CP_copyright_text {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
  .CP_Home_main .GST_main .GST_main_wrap {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 565px;
    padding: 20px;
    background-size: 165%;
    background-position-x: -221px;
  }
  .CP_Home_main .GST_main .GST_main_wrap .GST_left_wrap .GST_description {
    margin-bottom: 30px;
  }
  .CP_Home_main .GST_main .GST_main_wrap .GST_left_wrap .GST_heading {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .HDT_mobileImage {
    display: block;
    width: 100%;
    max-width: 480px;
    min-height: 630px;
    position: relative;
    margin-left: auto;
    background-repeat: no-repeat;
    margin-right: auto;
    background-size: 100%;
  }
  .HDT_mobileImageFader {
    position: absolute;
    bottom: 0;
    height: 363px;
    width: 100%;
    background: linear-gradient(180deg, rgba(6, 6, 16, 0) 0%, rgba(6, 6, 16, 0.37) 15.69%, rgba(6, 6, 16, 0.6) 41.57%, #060610 100%);
  }
  .CP_Home_main .GST_main .GST_main_wrap {
    margin-top: 84px;
  }
}
@media only screen and (max-width: 374px) {
  .HDT_mobileImage {
    min-height: 525px;
  }
  .HDT_mobileImageFader {
    height: 305px;
  }
  .CP_header_main .CP_nav_main_wrap .CP_LoginSignup_wrap .CP_loginButton {
    width: 90px;
    height: 35px;
  }
  .CP_nav_main_wrap .CP_menu_toggler {
    margin-left: 10px;
  }
}

.CP_left_circle {
  margin-left: -9%;

  img {
    height: 202px;
    width: 202px;
  }
}

.CP_home_rightLaptopBackground {
  margin-left: -65px;
  width: 820px;
  height: 640px;
}
.CP_home_rightLaptop {
  height: 568px;
  margin-top: -543px;
}
.CP_home_rightLaptopDark {
  height: 568px;
  margin-top: -589px;
}

.CP_left_dark {
  margin-top: 47px !important;
  margin-left: -93px !important;
}

.left__circle__dark {
  height: 240px !important;
  width: 213px !important;
}
.cp_date_range_button {
  border: 2px;
  background-color: gray !important;
  border-radius: 20px;
  color: white !important;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}
.create_invoice_btn {
  display: flex;
  justify-content: center;
  padding: 8px 30px;
  background: #009cff;
  border-radius: 3px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 1.35px;
  color: #ffffff;
  animation-duration: 2s;
  animation-name: cardUp3;
  margin-left: 17px;
  margin-top: 20px;
  margin-bottom: -5px;
}
.create-invoice-btn-handler {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #0091ff;
  border-radius: 25px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1.35px;
  color: #ffffff;
  border: 1px solid #3e3e3e;
  margin-left: 20px;
  height: 45px;
  width: 405px;
  margin-left: 20px;
  background-color: #0091ff;
  margin-bottom: 30px;
  border: none;
  cursor: pointer;
  margin-top: -10px;
}
.disable-create-invoice-btn-handler {
  background: rgb(0, 145, 255);
  opacity: 0.5;
  border-radius: 25px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1.35px;
  color: #ffffff;
  margin-left: 20px;
  height: 45px;
  width: 402px;
  margin-bottom: 30px;
  border: none;
  margin-top: -10px;
}
.invoice-fields-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.35px;
  color: #000000;
}
.create-invoice-modal-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.05808px;
  color: #000000;
}

.coins__filter {
  margin-left: 20px;
  box-sizing: border-box;
  height: 51px;
  width: 301px;
  border: 1px solid var(--transaction-filter-box-border);
  border-radius: 25px;
  background-color: var(--transaction-filter-box);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  .heading___icon {
    cursor: pointer;
    @include flexBoxSpaceBetween;
    padding: 6px 10px;
    .heding {
      color: var(--signup-text-color);
      font-size: 16px;
      letter-spacing: 0.04px;
      line-height: 22px;
      padding-left: 10px;
    }
    .arrow {
      margin-top: -8px;
      color: var(--signup-text-color);
      font-size: 24px;
      height: 24px;
      width: 24px;
      i {
        font-weight: 900;
      }
    }
  }

  .showDropdown {
    border-radius: 25px 25px 0 0;
    background-color: #ffffff;
    box-shadow: var(--transaction-filter-dataBox-border);
    z-index: 1;
    position: relative;
    margin-top: -49px;
    .showCoinDropdown__heading {
      cursor: pointer;
      @include flexBoxSpaceBetween;
      padding: 15px 10px;
      .heading__name {
        color: black;
        font-size: 16px;
        letter-spacing: 0.04px;
        line-height: 22px;
        padding-left: 10px;
      }
      .dropdown__icon {
        margin-top: -11px;
        font-size: 24px;
        height: 24px;
        width: 24px;
        color: black;
        i {
          font-weight: 900;
        }
      }
    }
    .search__box {
      @include flexBoxSpaceBetween;
      height: 40px;
      width: 100%;
      border-radius: 4px;
      background-color: var(--transaction-filter-search-background);
      padding: 0px 20px;
      color: black;

      input {
        color: black;
        background-color: var(--transaction-filter-search-background);
        font-size: 14px;
        letter-spacing: 0.04px;
        line-height: 19px;
        border: none;
        outline: none;
      }
      i {
      }
    }
    .allCrypto__rows {
      overflow-y: scroll;
      .coins__rowData {
        @include flexBoxSpaceBetween;
        height: 30px;
        padding: 25px 20px;
        cursor: pointer;
        .rowData__name__image {
          img {
            height: 30px;
            width: 30px;
            display: inline-block;
            border-radius: 100%;
          }
          span {
            padding-left: 10px;
            color: black;
            font-size: 14px;
            letter-spacing: 0.04px;
            line-height: 19px;
          }
        }
        .CryptoCheckBox {
          height: 14px;
          width: 14px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
    .allCrypto__rows::-webkit-scrollbar {
      width: 10px;
    }
    .TransactionDtls_cards_Scroll::-webkit-scrollbar-track {
      background: transparent;
    }
    .allCrypto__rows::-webkit-scrollbar-thumb {
      width: 10px;
      border-radius: 6px;
      background-color: #{$mainColor};
    }
    .allCrypto__rows::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .refresh__and__selectAll {
      height: 46px;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
      .refreshSelectAll {
        @include flexBoxSpaceBetween;
        padding: 2px 20px;
        .refresh {
          cursor: pointer;
          box-sizing: border-box;
          height: 34px;
          width: 34px;
          border: 1px solid #{$mainColor};
          border-radius: 17px;
          background-color: #{$mainColor};
          align-items: center;
          margin-right: 5px;
          color: #ffff;
          .refresh__icon {
            padding-left: 9px;
            padding-top: 5.5px;
            height: 15px;
            width: 19.29px;
          }
          i {
          }
        }
        .selectAll {
          cursor: pointer;
          box-sizing: border-box;
          height: 34px;
          width: 230px;
          border: 2px solid #0091ff;
          border-radius: 25px;
          background-color: #ffffff;
          align-items: center;
          color: black;

          h1 {
            margin-top: 5px;
            font-size: 13px;
            letter-spacing: 0.04px;
            line-height: 18px;
            text-align: center;
          }
        }
      }
    }
  }
}
.deposit_amount_body {
  text-align: left;
  padding: 0px 30px 30px 30px;
  overflow-x: hidden;

  .deposit_amount_title {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.05px;
    line-height: 27px;
  }

  .deposit_amount_input_title {
    color: #828282;
    font-size: 18px;
    letter-spacing: 0.05px;
    line-height: 24px;
  }

  .deposit_amount_content {
    // margin-bottom: 150px;

    .deposit_amount_content_enter_amount {
      color: #ffffff;
      font-size: 15px;
      letter-spacing: 1.35px;
      line-height: 27px;
    }

    .deposit_amount_selection {
      display: flex;
      border: 1px solid #e6e6e6;
      border-radius: 30px;
      background-color: #f7fafc;
      padding: 16px;

      .deposit_amount_selection_span {
        width: 15px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1.55px;
        line-height: 24px;
      }

      .deposit_amount_selection_input {
        border: none;
        width: 80%;
        outline: none;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1.55px;
        line-height: 24px;
        background-color: #f7fafc;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }

      .deposit_amount_selection_select {
        border: none;
        width: 60px;
        font-size: 15px;
        margin-left: auto;
        background: white;
        outline: none;
        font-weight: bold;
        letter-spacing: 1.16px;
        line-height: 18px;
        background-color: #f7fafc;

        option {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .deposit_amount_button_text {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1.35px;
    line-height: 27px;
    text-align: center;
  }

  .deposit_amount_continue {
    border-radius: 30px;
    background-color: #{$mainColor};
    padding: 15px;
    text-align: center;
    color: white;
    cursor: pointer;
  }

  .deposit_amount_enter_amount {
    border-radius: 30px;
    background-color: #e4e4e4;
    color: #656565;
    padding: 15px;
    text-align: center;
  }
}
.create-invoice-fields-border {
  // display: flex;
  align-items: center;
  align-content: center;
  background: #ffffff !important;
  border: 1px solid #8a8a8a !important;
  border-radius: 20px !important;
}
.box-width-height {
  height: 40px !important;
  width: 402px !important;
}
.display-flex {
  display: flex;
}
.invoice-input-value {
  font-family: 'Open Sans';
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 27px !important;
  display: flex;
  align-items: center;
  letter-spacing: 1.35px !important;
  color: #000000;
  padding-left: 10px;
}
.invoice-modal-header {
  justify-content: center !important;
}
.font-weight-normal {
  font-style: normal !important;
  font-weight: 500 !important;
}
.invoice-details-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-top: 1em;
  margin-bottom: 2.5em;
}
.invoice-details-back {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04928px;
  color: #000000;
  margin-bottom: 4em;
  margin-top: -15px;
  cursor: pointer;
}
.invoice-details-key {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #4f4f4f;
}
.invoice-details-value {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: #000000;
}
.invoice-details-container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 0 2.5em;
  padding: 0.5em 0em;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
}
.border-none {
  border: none;
}
.invoice-url-color {
  color: #007fe0 !important;
}
.invoice-link-box {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  padding: 2px 0px;
  padding-left: 15px;
  margin: 7px 40px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
.invoice-copy-btn {
  background: #0091ff;
  border: 1px solid #009cff;
  border-radius: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  color: #ffffff;
}
.invoice-link {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #636363;
}
.copy-link-message {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.optional-text-color {
  color: #d7d7d7;
}
.authenticator-scanner {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 0em !important;
}
.invoice_qrCode {
  width: 150px;
  height: 150px;
}
.invoice-download-qr-code {
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  gap: 10px;
  background: #0091ff;
  border: 1px solid #009cff;
  border-radius: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  align-items: center;
  color: #ffffff;
}
.invoice-arrow-icon {
  margin-top: 2px;
  color: var(--signup-text-color);
  font-size: 20px;
  height: 24px;
  width: 24px;
}
.invoice-amout {
  padding-left: 20px;
}
.invoice-close-icon {
  position: absolute;
  right: 30px;
}
.invoice-select-style {
  position: absolute;
  right: 40px;
  height: 37px;
  background: #ffffff !important;
  border-radius: 20px !important;
}
#selectCurrency {
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 5px;
  border: none;
}
.invoice-select-option {
  gap: 7px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-style: none !important;
}
.currency-icons-display{
  display: flex;
    position: absolute;
    right: 5.5em;
}
.currency-icons-display-details{
  display: flex;
    position: absolute;
    right: 7.5em;
}
.invoice-details-icons{
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
}