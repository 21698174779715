.jn-header {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	z-index: 3;
	display: flex;
	align-items: center;
	&-row {
		display: grid;
		grid-template-columns: auto 1fr auto;
		grid-gap: 64px;
		align-items: center;
	}
	&-logo {
		width: 190px;
		img {
			width: 100%;
			height: auto;
		}
	}
	&-text {
		color: #fff !important;
		font-size: 14px;
	}
	&-link {
		text-decoration: none !important;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: calc(100% + 6px);
			width: 100%;
			left: 0;
			height: 1px;
			background: #fff;
			opacity: 0;
			transition: all .15s;
			transform: translateY(10px);
		}
		&:hover, &.active {
			&:before {
				opacity: 1;
				transform: translateY(0);
			}
		}
		&:not(:last-child) {
			margin-right: 24px;
		}
	}
	&-login {
		font-size: 14px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	&-actions {
		display: flex;
		align-items: center;
	}
	&-account {
		display: flex;
	}
	&-menu {
		background: #1D2939;
		width: 100%;
		position: fixed;
		top: 0;
		overflow-y: auto;
		height: 100%;
		z-index: 11;
		right: 0;
		padding: 40px 0;
		display: none;
		@media screen and (min-width: 992px) {
			display: none !important;
		}
		&.active {
			display: block;
		}
		&-logo {
			display: flex;
			margin-bottom: 48px;
			justify-content: center;
			img {
				width: 135px;
				height: auto;
			}
		}
		&-grid {
			display: grid;
			grid-template-rows: 1fr auto;
			align-items: center;
			height: 100%;
		}
		&-btn {
			width: 40px;
			height: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-left: 10px;
			div {
				width: 16px;
				height: 2px;
				background: #fff;
				&:not(:last-child) {
					margin-bottom: 6px;
				}
			}
		}
		&-actions {
			padding: 0 24px;
			margin-top: 48px;
			.theme-btn-block {
				&:first-child {
					margin-bottom: 16px;
				}
			}
		}
		&-link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px 24px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.12);
			color: #fff !important;
			text-decoration: none !important;
			font-size: 16px;
			svg {
				flex-shrink: 0;
				flex-grow: 0;
				width: 24px;
				height: 24px;
				margin-left: 12px;
				position: relative;
				right: -7px;
			}
		}
	}
	.on-desktop {
		@media screen and (max-width: 991px) {
			display: none !important;
		}
	}
	.on-mobile {
		@media screen and (min-width: 992px) {
			display: none !important;
		}
	}
}

@media screen and (max-width: 1300px) {
	.jn-header-row {
		grid-gap: 46px;
	}
}

@media screen and (max-width: 991px) {
	.jn-header-account {
		display: none;
	}
	.jn-header-row {
		grid-template-columns: 1fr auto;
	}
}

@media screen and (max-width: 460px) {
	.jn-header-logo {
		width: 140px;
	}
	.jn-header-login {
		padding-left: 20px;
		padding-right: 20px;
	}
	.jn-header-row {
		grid-gap: 20px;
	}
}

@media screen and (max-width: 335px) {
		
}