.safe {
	position: relative;
	background: #000;
	padding-bottom: 0px;
	&__bg {
		width: 100%;
		height: auto;
		margin-top: 35px;
	}
	&__glow {
		position: absolute;
		// top: 30%;
		left: 0;
		right: 0;
		margin: 0 auto;
		max-width: 1500px;
		width: 100%;
		height: auto;
		mix-blend-mode: overlay;
	}
	&__content {
		max-width: 840px;
		margin: 0 auto;
	}
	&__gradient {
		background-image: url('https://dev.junomoney.org/images/upd/safe-radial.webp');
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// z-index: 2;
	}
	.section-upperTitle {
		margin-top: -65px;
	}
}

@media screen and (max-width: 1300px) {
	.safe__glow {
		max-width: 1000px;
		bottom: -2%;
	}
}

@media screen and (max-width: 770px) {
	.safe__glow {
		bottom: 10%;
	}
}

@media screen and (max-width: 477px) {
	.safe__glow {
		display: none;
	}
	.safe .section-upperTitle {
		margin-top: -9vw;
	}
}