.jn-footer {
	border-top: 1px solid rgba(255, 255, 255, 0.32);
	background: #091324;
	position: relative;
	padding: 80px 0;
	z-index: 1;
	&-logo {
		width: 160px;
		height: auto;
	}
	&-content {
		margin-top: 24px;
		margin-bottom: 32px;
		padding-top: 12px;
		border-top: 1px solid rgba(255, 255, 255, 0.24);;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&-text {
		color: #FFF;
		font-size: 14px;
		line-height: 120%;
		b {
			font-weight: 600;
		}
	}
	a.jn-footer-text {
		color: #fff !important;
	}
}

@media screen and (max-width: 991px) {
	.jn-footer {
		padding: 48px 0;
	}
	.jn-footer-content {
		flex-direction: column;
		padding-top: 24px;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.24);
	}
	.jn-footer-links {
		margin-top: 12px;
	}
	.jn-footer-text {
		text-align: center;
		&.grey-color-mobile {
			color: #EAECF0;
		}
	}
	.jn-footer-logo {
		margin: 0 auto;
	}
}

@media screen and (max-width: 465px) {
	span.jn-footer-text {
		display: none;
	}
	.jn-footer-links {
		flex-direction: column;
		display: flex;
		margin-top: 24px;
	}
	.jn-footer a.jn-footer-text:not(:last-child) {
		margin-bottom: 12px;
	}
}