.jncTransactions {
	overflow-x: auto;
	display: grid;
	@media screen and (min-width: 992px) {
		.jncExpanded {
			border-radius: 0;
		}
	}
	.rdt_TableHeadRow {
		padding-left: 10px;
		padding-right: 10px;
	}
	&.is-dashboard {
		border-radius: 15px;
		.rdt_TableCol {
			flex-grow: 0 !important;
			min-width: 200px;
			flex-shrink: 1 !important;
			flex-basis: 1 !important;
		}
		.rdt_TableCol[data-column-id="1"],
		.rdt_TableCell[data-column-id="1"] {
			padding-left: 0;
		}
		.jncTable-minHeight {
			border-radius: 0;
		}
		.jncTable {
			min-height: auto;
		}
	}
	&:not(.is-dashboard) {
		.jncTable-noData {
			@media screen and (min-width: 992px) {
				padding-top: 24px !important; 
			}
		}
		.rdt_TableCol {
			flex-grow: 0 !important;
			min-width: 200px;
		}
		@media screen and (max-width: 991px) {
			.jncTable:not(.no-data) {
				padding: 24px;
			}
		}
		@media screen and (max-width: 767px) {
			.jncTable:not(.no-data) {
				padding: 0;
			}
		}
	}
	&__clearFilter {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 14px;
		gap: 5px;
		color: #667085;
	}
	&__filters {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 20px;
		max-width: 1300px;
		&-btn {
			border-radius: 99px;
			border: 1px solid #D0D5DD;
			color: #000;
			font-size: 12px;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 6px 18px;
			width: 100%;
			margin-bottom: 20px;
			svg {
				width: 18px;
				height: auto;
				margin-right: 4px;
			}
		}
	}
}

.jncTransactionsList {
	display: grid;
	grid-gap: 20px;
	&__item {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 15px;
		align-items: center;
		width: 100%;
		text-align: left;
	}
	&__type {
		display: flex;
		align-items: center;
		&-icon {
			width: 40px;
			height: 40px;
			background: #EAECF0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50px;
			margin-right: 10px;
			svg {
				width: 20px;
			}
            img {
                width: 20px;
                height: 20px;
            }
		}
	}
	&__text {
		color: #000;
		font-size: 14px;
		line-height: 20px;
		&.t-right {
			text-align: right;
		}
	}
	&__date {
		color: #344054;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px; 
	}
	&__status {
		margin-left: auto;
		padding: 2px 8px;
		border-radius: 16px;
		text-align: center;
		font-weight: 500;		
		font-size: 12px;
		margin-top: 4px;
		&.na, &.created {
			color: #344054;
			background: rgb(236, 236, 236);
		}
		&.success, &.updated,&.completed {
			background: #ECFDF3;
			color: #027A48;
		}
		&.pending ,&.processing {
			background: #F2F4F7;
			color: #344054;
		}
		&.witheld {
			color: #B54708;
			background: #FFFAEB;
		}
		&.rejected, &.error , &.failed {
			background: #FEF3F2;
			color: #B42318;
		}
	}
}

.jncTransactionModal {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 15px;
	&__title {
		color: #171717;
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		font-family: "Inter", sans-serif;
	}
	&__header {
		display: flex;
		align-items: center;
		.svg-block {
			width: 40px;
			height: 40px;
			background: #EAECF0;
			border-radius: 50%;
			margin-right: 14px;
		}
		svg {
			width: 20px;
		}
	}
	&__group {
		&:not(:last-child) {
			padding-bottom: 24px;
			margin-bottom: 12px;
			border-bottom: 1px solid #EAECF0;
		}
		&-text {
			color: #000;
			font-size: 16px;
			letter-spacing: 0.042px;
			margin-bottom: 10px;
		}
		&-grid {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-gap: 15px;
			align-items: center;
			.jncTransactionModal__group-text {
				font-weight: 500;
			}
		}
		.jncTransactionsList__status {
			margin-left: initial;
			font-size: 14px;
		}
		.jncInputView-copy {
			margin-left: 0;
			border: 1px solid #f8f8f8;
		}
	}
	&__repeat {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		p {
			text-align: center;
			font-size: 12px;
			color: #000;
		}
	}
}

.jncTable-inputs-group-text {
    font-family: var(--opensansFont);
}

@media screen and (max-width: 991px) {
	.jncTransactions:not(.is-dashboard) {
		// padding: 20px 24px 0;
		background: #fff;
		border-radius: 12px;
		border: 1px solid #EFEFEF;
		.jncTableM-block.active {
			border-bottom-color: transparent;
		}
		.jncTable-noData {
			padding-bottom: 24px;
		}
	}
	.jncTransactions.is-dashboard {
		.jncTableM-block:last-of-type {
			border-bottom: 0;
			.jncTableM-btn {
				padding-bottom: 0;
			}
		}
		.jncTableM-block:first-of-type {
			.jncTableM-btn {
				padding-top: 0;
			}
		}
	}
	.jncTable {
		border-radius: 0;
	}
	//filters
	.jncTransactions__filters {
		grid-template-columns: 1fr;
		max-width: initial;
	}
	.jncTable-inputs {
		grid-template-columns: 1fr;
	}
	.jncTable-inputs-group {
		max-width: 100% !important;
	}
}

.jncResetLabel {
	width: auto;
	padding-bottom: 5px;
	@media screen and (min-width: 992px) {
		display: none;
	}
}

.jncResetInput {
	@media screen and (max-width: 767px) {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		width: 100%;
	}
	@media screen and (min-width: 768px) and (max-width: 991px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		gap: 10px;
	}
}

@media screen and (max-width: 767px) {
	.jncTransactions:not(.is-dashboard) {
		padding: 0;
		border: 0;
		border-radius: 0;
		.jncTable-inputs-btn {
			height: 48px;
		}
	}
	.jncTransactions {
		.jncTable-inputs {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 10px;
		}
	}
	.jncTransactions.is-dashboard {
		border-radius: 0;
		.jncTableM-wrap {
			padding: 0;
		}
	}
	.jncTable.is-dashboard {
		padding: 0;
	}
}

@media screen and (max-width: 430px) {
	.jncTransactions {
		.jncTable-inputs {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}