.mobileMenu {
	transform: translate3d(100%, 0, 0);
	max-width: 400px;
	background: rgba(0, 0, 0, 0.85);
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(30px);
	width: 100%;
	position: fixed;
	top: 0;
	overflow-y: auto;
	height: 100%;
	z-index: 11;
	right: 0;
	transition: transform 0.2s ease-in-out;
	backface-visibility: hidden;
	padding: 20px 30px 40px 30px;
	@media screen and (min-width: 992px) {
		display: none;
	}
	&.active {
		transform: translate3d(0, 0, 0);
	}
	&__start {
		height: 100%;
		display: flex;
		align-items: center;
		&_content {
			display: none;
			width: 100%;
		}
	}
	&__logo {
		max-width: 320px;
		width: 100%;
		margin: 0 auto 15px;
	}
	&__actions {
		width: 100%;
		.divider {
			display: none;
		}
		.theme-btn-block.mb {
			margin-bottom: 20px;
		}
	}
	&__close {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 20px;
		right: 4%;
		svg {
			width: 100%;
			height: 100%;
		}
	}
	&__loggedIn {
		padding-top: 140px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&__logout {
		.divider {
			margin-bottom: 20px;
		}
	}
	.divider {
		width: 100%;
		height: 1px;
		background: rgba(255, 255, 255, 0.1);
	}
}

@media screen and (max-width: 590px) {
	.mobileMenu {
		max-width: 100%;
		border-radius: 0;
		border-left: 0;
		border-bottom: 0;
		padding: 20px 4% 40px 4%;
	}
	.mobileMenu__start {
		flex-direction: column;
		justify-content: center;
	}
	.mobileMenu__start_content {
		display: block;
	}
	.mobileMenu__actions {
		margin-top: 60px;
		.divider {
			display: block;
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-height: 710px) {
	.mobileMenu__loggedIn {
		padding-top: 80px;
	}
}

@media screen and (max-height: 610px) {
	.mobileMenu__loggedIn {
		padding-top: 30px;
		height: auto;
	}
}
