.jn-contactScroll {
	padding: 66px 0;
	position: relative;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	z-index: 3;
	&-relative {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
		margin: 0 auto;
	}
	.content-container {
		position: relative;
	}
	&-deco {
		position: absolute;
		left: -50px;
		bottom: 0;
		width: 280px;
	}
	&-grid {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 20px;
		align-items: center;
	}
	&-text {
		color: #EAECF0;
		font-size: 32px;
		font-weight: 700;
		line-height: 110%;
	}
	&-content {
		display: flex;
		align-items: center;
		img {
			width: 60px;
			height: auto;
			margin-right: 32px;
		}
	}
}

@media screen and (min-width: 2000px) {
	.jn-contactScroll-relative {
		right: 0;
		margin: 0 auto;
		max-width: 2000px;
	}
	.jn-contactScroll-deco {
		left: 85px;
	}
}

@media screen and (max-width: 1300px) {
	.jn-contactScroll-deco {
		width: 330px;
	}
}

@media screen and (max-width: 991px) {
	.jn-contactScroll-text {
		font-size: 28px;
	}
	.jn-contactScroll-content img {
		width: 45px;
	}
}

@media screen and (max-width: 720px) {
	.jn-contactScroll-grid {
		grid-template-columns: 1fr;
		grid-gap: 32px;
	}
	.jn-contactScroll {
		.junoClient-btn {
			width: 100%;
		}
	}
	.jn-contactScroll-deco {
		width: 270px;
	}
	.jn-contactScroll {
		background-size: 150vw 100%;
	}
}

@media screen and (max-width: 548px) {
	.jn-contactScroll-content {
		flex-direction: column;
		align-items: flex-start;
	}
	.jn-contactScroll-content img {
		margin-right: 0;
		margin-bottom: 24px;
	}
	.jn-contactScroll-text {
		br {
			display: none;
		}
	}
}

@media screen and (max-width: 350px) {
	.jn-contactScroll-text {
		font-size: 24px;
	}
}