.jnAm-counter {
	display: flex;
	align-items: center;
	background: rgba(20, 29, 41, 0.80);
	background-image: url('https://dev.junomoney.org/images/juno-am/counter-bg.webp');
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	background-position: center;
	.jnAm-bottomDeco {
		position: absolute;
		bottom: 0;
		left: 0;
	}
	&-upperTitle {
		color: var(--accent-color);
		font-size: 20px;
		font-weight: 500;
		line-height: 120%;
		letter-spacing: 0.3px;
		margin-bottom: 24px;
	}
	&-grid {
		grid-gap: 60px 50px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin-top: 100px;
	}
	&-block {
		padding-bottom: 20px;
		border-bottom: 2px solid rgba(0, 145, 255, 0.19);
		&-title {
			color: var(--accent-color);
			font-family: "Times New Roman";
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 20px;
		}
	}
	&-margin{
		margin-bottom: 25px;
	}
}

@media screen and (max-width: 991px) {
	.jnAm-counter-grid {
		display: flex;
		flex-wrap: nowrap;
		grid-gap: 0;
		overflow-y: auto;
		margin-top: 75px;
		border-top: 1px solid rgba(255, 255, 255, 0.20);
		padding-bottom: 10px;
	}
	.jnAm-counter-block {
		min-width: 350px;
		padding: 24px 24px 36px 24px;
		border: 0;
		&:not(:last-child) {
			border-right: 1px solid rgba(255, 255, 255, 0.20);
		}
	}
	.jnAm-counter {
		align-items: flex-end;
		padding-bottom: 20px !important;
		min-height: 120vh;
		.content-container {
			width: 100%;
		}
	}
	.jnAm-counter-upperTitle,
	.jnAm-counter .jnAm-title {
		padding: 0 4%;
	}
}

@media screen and (max-width: 430px) {
	.jnAm-counter {
		min-height: auto;
	}
	.jnAm-counter-block {
		min-width: 300px;
		padding: 20px 20px 36px 20px;
	}
	.jnAm-counter-upperTitle {
		font-size: 16px;
		margin-bottom: 15px;
	}
	.jnAm-counter-block-title {
		font-size: 20px;
	}
	.jnAm-counter-block {
		min-width: 277px;
	}
}
