$actionBtnTransition: all 0.4s;

.jncActions {
    &__grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 12px;
    }

    &__grid__crypto {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 12px;
    }

    &__btn {
        padding: 32px;
        border-radius: 12px;
        border: 1px solid #efefef;
        background: #fff;
        text-align: left;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 50%;
            height: 50%;
            transform: scale(0);
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transform-origin: 50% 50%;
            background: #6366f1;
            transition: $actionBtnTransition;
            opacity: 0;
        }

        &:hover {
            @media (hover: hover) {
                &::before {
                    transform: scale(3);
                    opacity: 1;
                    background: var(--theme-color);
                }

                .jncActions__icon {
                    background: #fff;
                }

                .jncActions__title,
                .jncActions__text {
                    color: #fff;
                }
            }
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 500;
        margin-top: 16px;
        transition: $actionBtnTransition;
        color: #000;
    }

    &__text {
        color: #475467;
        margin-top: 16px;
        position: relative;
        transition: $actionBtnTransition;
    }

    &__icon {
        background: #eaecf0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $actionBtnTransition;

        .svg-block {
            width: 100%;
            height: 100%;
        }

        svg {
            width: 48%;
            height: auto;
        }
    }

    &__top {
        position: relative;
    }
}

@media screen and (max-width: 1535px) {
    .jncActions__btn {
        padding: 24px;
    }
}

@media screen and (max-width: 1355px) {
    .jncActions__text {
        font-size: 14px;
    }
}

@media screen and (max-width: 1275px) {
    .jncActions__grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .jncActions__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 991px) {
    .jncActions {
        order: -1;
    }

    .jncActions__grid {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 4px;
    }

    .jncActions__grid__crypto {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 4px;
    }

    .jncActions__text {
        display: none;
    }

    .jncActions__title {
        margin-top: 24px;
        font-size: 14px;
        font-weight: 500;
    }

    .jncActions__btn {
        padding: 16px;
        min-height: 150px;
    }
}

@media screen and (max-width: 767px) {
    .jncActions__btn {
        background: #f2f4f7;
        min-width: 120px;
    }

    .jncActions__icon {
        background: #d0d5dd;
    }

    .jncActions__icon {
        width: 32px;
        height: 32px;
    }

    .jncActions__overflow {
        overflow-x: auto;
    }
}

@media screen and (max-width: 630px) {
    .jncActions {
        position: relative;
        overflow-x: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 150px;
            background: linear-gradient(
                -90deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            z-index: 1;
        }
    }
}
