.svg-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.jncLayout {
    min-height: calc(100vh - 72px); // header height
    display: grid;
    grid-template-columns: auto 1fr;

    &-main {
        border-radius: 12px;
        background: #f7f7f7;
    }

    &-pd {
        padding: 24px;
    }

    .alert {}
}

.jncAlert {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 9px 10px;
    margin-bottom: 0;
}

.jncSectionTitle {
    &:not(.no-mb) {
        margin-bottom: 26px;
    }

    &.see-all {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_children {
        display: flex;
        gap: 10px;
    }

    p {
        font-size: 18px;
        font-weight: 600;
    }

    a,
    button {
        text-decoration: none;
        color: #1d2939;
        padding: 8px 16px;
        background: #eaecf0;
        border-radius: 99px;
        display: flex;
        transition: 0.15s;

        &.white-bg {
            background: #f2f4f7;
        }

        &:hover {
            background: var(--theme-color);
            color: white;
        }
    }

    &.btn-mobile {
        display: flex;
        justify-content: flex-end;

        &.mb {
            margin-bottom: 13px;
        }
    }
}

.jncUser {
    button {
        text-decoration: none;
        color: #1d2939;
        padding: 8px 16px;
        background: #eaecf0;
        border-radius: 99px;
        display: flex;
        font-size: 12px;
    }
}

.jncSection {
    &.mt {
        margin-top: 26px;
    }

    &.mb {
        margin-bottom: 26px;
    }

    @media screen and (max-width: 991px) {
        &.hidden.is-dashboard {
            display: none;
        }
    }
}

.jncPage {
    padding: 32px 24px;
    max-width: 1766px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    &-heading {
        margin-bottom: 48px;
    }

    &-title {
        display: flex;
        font-family: var(--titleFont);
        align-items: center;
        color: #171717;
        font-size: 28px;
        font-weight: 800;
        line-height: 120%;

        .line {
            width: 40px;
            height: 1px;
            background: #171717;
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 10px;
        }
    }

    &-subtitle {
        color: #667085;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
    }

    &-backLink {
        margin-right: 10px;

        svg {
            width: 24px;
            height: auto;
        }
    }
}

.animate__fadeInUpCustom {
    animation-name: fadeInUpCustom;
}

@keyframes fadeInUpCustom {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 150px, 0);
        transform: translate3d(0, 150px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.jncContainer {
    max-width: 1920px;
    width: 100%;
}

.jncCard {
    padding: 32px;
    background: #fff;
    border-radius: 12px;
    border: 1px solid #efefef;
}
.cryptoBalance{
    padding: 20px 32px!important;
}

.jncCardsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;

    &.no-grid {
        @media screen and (max-width: 991px) {
            display: block !important;
        }
    }
}

.jncActionBtn {
    position: relative;
    width: 40px;
    height: 40px;
    border: 1px solid #d0d5dd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.15s;

    &:hover {
        background: var(--theme-color);
        border-color: var(--theme-color);

        span {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        svg path {
            stroke: white;
        }
    }
}

.jncActionBtn-transfer {
    &:hover {
        background: var(--theme-color);
        border-color: var(--theme-color);

        span {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        svg path {
            fill: white;
        }
    }
}

.jncHint {
    position: relative;

    span {
        display: block;
        background: #131b2a;
        pointer-events: none;
        border-radius: 8px;
        top: -40px;
        position: absolute;
        height: 36px;
        font-size: 12px;
        color: #fff !important;
        text-align: center;
        padding: 8px 12px;
        white-space: nowrap;
        transition: 0.15s;
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
    }

    &:hover {
        span {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}

.juno-client-theme {
    font-family: var(--textFont);
    font-size: 16px;
    color: #000;

    .projectApp {
        background: #fff;
    }
}

.jncTypeahead {
    &-item {
        display: flex;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border-radius: 50%;
        }
    }
}

//todo:kris delete?
.jnA-nav {
    display: flex;
    background: #f2f4f7;
    border-radius: 20px;
    padding: 3px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: max-content;

    &-block {
        padding-bottom: 24px;
        padding-top: 24px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-link {
        color: #667085;
        text-decoration: none !important;
        font-size: 14px;
        text-align: center;
        border-radius: 17px;
        padding: 8px 20px;
        transition: 0.15s;
        cursor: pointer;

        &.active {
            background: var(--btn-background);
            color: #fff;
        }

        &:hover:not(.active) {
            color: var(--btn-background);
        }
    }

    &-search {
        display: flex;
        align-items: center;
    }
}

//todo:kris delete?
.jnA-tabs {
    display: flex;
    background: #fff;
    flex-wrap: nowrap;
    overflow-x: auto;

    &-component {
        border-top: 1px solid rgba(0, 0, 0, 0.12);

        @media screen and (max-width: 991px) {
            margin-bottom: 70px;
        }
    }

    &-btn {
        padding-top: 16px;
        padding-bottom: 16px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;

        p {
            font-size: 16px;
            font-weight: 500;
            color: #98a2b3;
            transition: 0.15s;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            height: 2px;
            background: var(--btn-background);
            opacity: 0;
            transition: 0.15s;
        }

        &.active {
            p {
                color: #344054;
            }

            &:before {
                opacity: 1;
            }
        }

        &:hover:not(.active) {
            p {
                color: var(--btn-background);
            }
        }
    }
}

// --modal-body-background: #fff;
// --input-label-color: #344054;
// --icon-color: #667085;
// --input-background: #fff;
// --input-border-color: #D0D5DD;
// --input-placeholder-color: #98A2B3;
// --modal-text-color: #344054;
// --modal-light-text-color: #667085;
// --input-border-radius: 25px;
// --input-font-size: 14px;

.pagination-container {
    display: flex;
    list-style-type: none;
    // padding: 20px 0;
    padding-top: 20px;
    justify-content: center;
    margin-bottom: 0;

    .pagination-item {
        height: 30px;
        width: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        text-align: center;
        margin: auto 2px;
        color: #344054;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 13px;

        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            cursor: pointer;
        }

        &.selected {
            background-color: rgba(0, 0, 0, 0.08);
        }

        .arrow {
            &::before {
                position: relative;
                /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
                content: '';
                /* By using an em scale, the arrows will size with the font */
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid rgba(0, 0, 0, 0.87);
                border-top: 0.12em solid rgba(0, 0, 0, 0.87);
            }

            &.left {
                transform: rotate(-135deg) translate(-50%);
            }

            &.right {
                transform: rotate(45deg);
            }
        }

        &.disabled {
            pointer-events: none;

            .arrow::before {
                border-right: 0.12em solid rgba(0, 0, 0, 0.43);
                border-top: 0.12em solid rgba(0, 0, 0, 0.43);
            }

            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }
}

.jncPage-title {
    font-family: var(--stagnanFont);
}

.jncLayout-main {
    overflow: auto;
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
    .jncCard {
        padding: 28px;
    }
}

@media screen and (max-width: 991px) {
    .juno-client-theme {
        background: var(--theme-color);

        .projectApp {
            border-radius: 18px;
            background: #f7f7f7;
        }
    }

    .jncLayout {
        min-height: calc(100vh - 50px);
        grid-template-columns: 1fr;
    }

    .jncLayout-main {
        border-radius: 0;
        background: transparent;
    }

    .jncPage {
        padding: 24px;
        padding-bottom: calc(24px + 70px); // page padding bottom + fixed sidebar height
    }

    .jncPage__order {
        display: grid;
        grid-template-columns: 1fr;
    }

    .jncPage-heading {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 991px) {
    .juno-client-theme {
        font-size: 14px;

        .projectApp {
            background: #fff;
        }
    }

    .jncPage {
        padding-left: 12px;
        padding-right: 12px;
    }

    .jncCard {
        padding: 0;
        border: 0;
        border-radius: 0;
    }

    .jncSectionTitle:not(.no-mb) {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 550px) {
    .jncPage-title {
        font-size: 20px;
    }

    .jncAlert {
        margin-top: 0;
    }
}

@media screen and (max-width: 450px) {
    .jncPage-title {
        .line {
            display: none;
        }
    }
}