.jnAm-cmt {
	background: #fff;
	display: grid;
	grid-template-rows: auto 1fr;
	&-container {
		border-top: 1px solid rgba(0, 0, 0, 0.20);
		border-bottom: 1px solid rgba(0, 0, 0, 0.20);
	}
	&-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&-img {
			height: 250px;
			width: 620px;
			position: relative;
			right: -220px;
			object-fit: cover;
		}
	}
	&-grid {
		border-right: 1px solid rgba(0, 0, 0, 0.20);
		border-left: 1px solid rgba(0, 0, 0, 0.20);
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		height: 100%;
	}
	&-block {
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		&:first-child, 
		&:nth-child(2),
		&:nth-child(4),
		&:nth-child(5) {
			border-right: 1px solid rgba(0, 0, 0, 0.20);
		}
		&:first-child, 
		&:nth-child(2),
		&:nth-child(3) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.20);
		}
		&-num {
			color: #001D0B;
			text-align: center;
			font-family: "Times New Roman";
			font-size: 60px;
			font-weight: 700;
			opacity: .34;
			line-height: 105%;
		}
		&-text {
			text-align: center;
			color: #001D0B;
			font-family: "Times New Roman";
			font-size: 24px;
			font-weight: 700;
		}
		.line {
			background: var(--accent-color);
			width: 100px;
			height: 2px;
			margin: 24px auto;
		}
	}
}

@media screen and (max-width: 1550px) {
	.jnAm-cmt-top-img {
		right: -219px;
	}
}

@media screen and (max-width: 991px) {
	.jnAm-cmt-top {
		display: block;
	}
	.jnAm-cmt {
		padding-top: 50px;
		.jnAm-title {
			margin-bottom: 50px;
			padding: 0 4%;
		}
		.content-container {
			width: 100%;
		}
	}
	.jnAm-cmt-top-img {
		right: 0;
		width: 100%;
		height: auto;
	}
	.jnAm-cmt-container {
		border: 0;
	}
	.jnAm-cmt-block {
		padding: 24px;
	}
	.jnAm-cmt-block:first-child, .jnAm-cmt-block:nth-child(2), .jnAm-cmt-block:nth-child(3) {
		border-bottom: 0;
	}
	.jnAm-cmt-block:first-child, .jnAm-cmt-block:nth-child(2), .jnAm-cmt-block:nth-child(4), .jnAm-cmt-block:nth-child(5) {
		border-right: 0;
	}
	.jnAm-cmt-grid {
		grid-template-columns: repeat(2, 1fr);
		border-right: 0;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			height: 100%;
			width: 1px;
			background: rgba(0, 0, 0, 0.2);
		}
	}
	.jnAm-cmt-block:first-child,
	.jnAm-cmt-block:nth-child(2),
	.jnAm-cmt-block:nth-child(3),
	.jnAm-cmt-block:nth-child(4) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}
	.jnAm-cmt-block .line {
		display: none;
	}
	.jnAm-cmt-block {
		flex-direction: row;
		justify-content: flex-start;
	}
	.jnAm-cmt-block-num {
		margin-right: 36px;
	}
	.jnAm-cmt-block-text {
		text-align: left;
	}
}

@media screen and (max-width: 665px) {
	.jnAm-cmt-block-text {
		font-size: 20px;
		br {
			display: none;
		}
	}
	.jnAm-cmt-block-num {
		font-size: 45px;
		margin-right: 25px;
	}
}

@media screen and (max-width: 520px) {
	.jnAm-cmt-grid {
		grid-template-columns: 1fr;
	}
	.jnAm-cmt-grid:before {
		display: none;
	}
	.jnAm-cmt-block:nth-child(5) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}
}

@media screen and (max-width: 430px) {
	.jnAm-cmt-block {
		padding: 20px;
	}
	
}