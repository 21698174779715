.verify-input .MuiInputBase-input{
    height: 100%;
    padding: 0;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
}

.verify-input .MuiOutlinedInput-root {
    height: 100%;
    padding: 10px 8px;
}