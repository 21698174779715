@import './_mixins.scss';

// .icon_close{
//   color: white;
// }
.b2c-signup-model {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    max-height: none;
    z-index: 1500;
    background-color: var(--signup-model-bg);
  }
}
.signup-model {
  width: 600px;
  border-radius: 10px;
  padding: 1rem 2rem;
  overflow-y: hidden;
}

.signup-close-icon {
  @include flexBoxJustifyEnd;
  cursor: pointer;
}
.icon-close {
  color: var(--close-icon-clr);
}
.signup-label-filed {
  margin-right: 1rem;
}
.signup-model-conatiner {
  display: grid;
  justify-content: center;
  align-items: center;
}
.signup-heading {
  color: var(--signup-text-color);
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1.08px;
  line-height: 38px;
  text-align: center;
  margin: 1rem;
}

.signup-input-flex {
  @include flexBoxJustifyStart;
}
.signup-input-label {
  color: var(--signup-text-color);
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
  margin: 5px 0px;
  font-weight: bold;
}
.signup-input-field {
  box-sizing: border-box;
  // border: 1px solid #f5f5f5;
  border: none;
  -webkit-appearance: none;
  border-radius: 20px;
  background-color: var(--signup-input-field-bg-clr);
  outline: var(--input-outline-clr);
  padding: 8px 15px;
  width: 100%;
  color: var(--signup-text-color);
  border-color: var(--signup-input-field-border-clr);
}
.signup-input-field-light {
  background-color: #fbfbfb;
  border-color: #fbfbfb;
  color: #000000;
}
.signup-input-field-dark {
  background-color: #252525;
  border-color: #252525;
  color: #{$mainTextColor};
  outline: none;
}


.signup-input-field::placeholder {
  color: #b8b8b8;
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
  padding-left: 5px;
}
.accpet-privacy-policy {
  @include flexBox;
}

.accept-privacy-policy-box {
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: var(--accept-box-shadow);
  margin-right: 20px;
}
.accept-privacy-policy-text {
  color: var(--signup-text-color);
  font-size: 12px;
}
.b2c-create-account {
  cursor: pointer;
  border-radius: 25px;
  background-color: #{$mainColor};
  color: #{$mainTextColor};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1.35px;
  line-height: 27px;
  text-align: center;
  padding: 10px;
  outline: none;
  border: none;
}
.b2c-create-account-disabled {
  background-color: var(--create-button-clr);
  color: #4a4a4a;
  cursor: not-allowed;
}
.b2c-create-account-disabled-light {
  background-color: lightgray;
  color: #4a4a4a;
}
.b2c-create-account-disabled-dark {
  background-color: #252525;
  color: #4a4a4a;
}
.b2c-already-account {
  color: var(--signup-text-color);
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
  text-align: center;
}

.mt {
  margin-top: 10px;
}
.mb {
  margin-bottom: 10px;
}
.alredy-login {
  color: #06b6fe;
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.email-margin {
  margin: 1.5rem 0;
}


.successMessage {
  margin-top: 5px;
  margin-bottom: 10px;
  color: var(--signup-text-color);
  text-align: center;
}

.email-text {
  text-align: center;
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
}
.font-bold {
  font-weight: bold;
}
.text-align-center {
  text-align: center;
}
.visibility-icon {
  position: absolute;
  right: 12%;
  top: 60%;
  cursor: pointer;
}

.accpet-privacy-policy {
  input[type="checkbox"] {
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    margin: 0 0.4em 0.4em 0;
    border: 1px solid #bfbaba;
    background: -webkit-linear-gradient(white, white);
    -webkit-appearance: none;
    -webkit-transition: box-shadow 200ms;
}

input[type="checkbox"] {
    -webkit-border-radius:2px;
    border-radius:2px;
}

input[type="checkbox"]:not(:disabled):hover {
    border-color:#bfbaba;
}

input[type="checkbox"]:active:not(:disabled) {
   border-color:#bfbaba;
}

input[type="checkbox"]:focus {
    outline:none;
}

input[type="checkbox"]:checked {
    border-color:#bfbaba;
}


input[type="checkbox"]:checked:before {
font-weight: bold;
color: blue;
content: '\2713';
// -webkit-margin-start: 0;
margin-left: 2px;
font-size: 1rem;
}

input:disabled {
opacity: .6;
box-shadow: none;
background: white;
box-shadow:none;
}
}

.password-opacity::-webkit-input-placeholder {
  opacity: 0.4 !important;
}

.password-opacity::-moz-placeholder {
  opacity: 0.4 !important;
}

.password-opacity::-ms-placeholder {
  opacity: 0.4 !important;
}
.password-opacity::placeholder {
  opacity: 0.4 !important;
}

@media only screen and (min-width: 350px) and (max-width: 767px) {

  .receive_model_mobile{    
    .css-uhb5lp {
      background-color: var(--signup-model-bg);
  
      margin: 0px !important;
     
    }
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
      width: 100vw;

      background-color: var(--signup-model-bg);
      margin: 0;
    }
    .card-bg{
      width: 100% !important;
      box-shadow: none;
      height: 100vh;
    }
    .enable-authenticator-header{
      // height: 42px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      justify-content: left;
    }
    .signup-close-icon{
      position: absolute;
      right: 20px;
    }
    .enable-authenticator-model{
      width: 100% !important;
    }
    .enable-authenticator-content{
      max-height: 100%;
      .kyc-select-options{
        width: 100%;
      }
    }
    .kyc-options-container{
      position: relative;
      width: 100%;
    }
    .receive-crypto-text-header{
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
    }

    .send__continue{
      width: 100%;
    }
    .you__send{
      width: 100%;
    }
    .you__send .you__send__left{
    }
    .you__send .you__send__left input{
      width: 108px;
    }
  }

  .status__imgs{
    width: 55px;
  }

  .crypto__status__content{
    width: auto;
  }

  .status__title{
    font-size: 18px;
  }

  .status__msg{
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
  
  .send__continue{
    height: 41px;
  }
}


.create-account-heading-mobile-view {
  display: none;
}

.b2c-login-model {
  .signup-model {
    width: 461px;
  }
}
.credit-card-modal {
  max-height: 700px;
  overflow-x: auto;
  overflow-y: inherit;
}

.credit-card-modal::-webkit-scrollbar {
  width: 10px;
}

.credit-card-modal::-webkit-scrollbar-track {
  background: transparent;
}

.credit-card-modal::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 6px;
  background-color: #{$mainColor};
}

.credit-card-modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .b2c-signup-model {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
      margin: 0px;
      max-width: none;
    }
  }
  .signup-model {
    width: auto;
  }
  .signup-input-flex {
    display: block;
    margin-top: 0px;
  }
  .signup-label-filed {
    margin-right: 0px;
    margin-bottom: 22px;
  }
  .accept-privacy-policy-text {
    letter-spacing: 0px;
    line-height: 17px;
  }
  .accpet-privacy-policy {
    input[type="checkbox"] {
      height: 1em;
    }
  }
  .accpet-privacy-policy input[type="checkbox"]:checked:before {
    font-weight: bold;
    color: blue;
    content: '\2713';
    margin-left: 2px;
    font-size: 1rem;
    position: relative;
    top: -4px;
}
  .create-account-desktop-view, .privacy-policy-mobile-view{
    display: none;
  }
  .create-account-heading-mobile-view  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #06B6FE;
    padding: 1.5rem 2rem;

    .signup-heading {
      font-size: 19px;
      letter-spacing: 0px;
      line-height: 23px;
      margin: 0rem;
    }
  }
  .b2c-signup-model {
    .css-hz1bth-MuiDialog-container {
      height: auto;
    }
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
      width: -webkit-fill-available;
    }
  }
  .b2c-login-model {
    .signup-model {
      width: -webkit-fill-available
    }
  }
  .b2c-login-model {
    .signup-model {
      height: 100vh;
    }
  }
  .b2c-login-form-mobile-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 450px;
  }
  .email-text-mobile-view {
    text-align: unset;
  }
  .b2c-signup-model {
    .css-ekeie0 {
      height: auto;
    }
  }
}

.resend-btn {
  color: #06b6fe;
  text-align: end;
  text-decoration: underline;
  height: 12px;
  margin-top: 2px;
}

.resend-btn:hover {
  cursor: pointer;
}

.check-junk-text {
  color:white;
  text-align: center;
  padding-top: 5px;
} 


.fetchingMessage {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #06b6fe;
  text-align: center;
  opacity: 1;
  animation: fade 2s linear;
}


@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

.merchantBorderColor{
  border-color: #8A8A8A !important;
}
