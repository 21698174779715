.jn-addressdetails {
	&-dialog{
		max-width:  570px !important;
	}
	&-delete{
		max-width: 422px !important;
		border-top:3px solid #F04438;
	}
	&-pending{
		max-width: 422px !important;
		border-top:3px solid #70707B;
	}
	&-success{
		max-width: 422px !important;
		border-top:3px solid #12B76A;
	}
	&-view{
		max-width: 570px !important;
	}
	&-actionbox {
		border-top:1px solid #D1D1D6;
		background: #F4F4F5;
		height: 94px;
		padding: 24px !important;
	}
	&-contentviewbox{
		border-bottom: 0px !important;
	}
	&-contentweight{
		font-weight: 600;
	}
	&-confirm{
		max-width: 422px !important;
		border-top:3px solid #F79009;
		max-height: 417px !important;

	}
	&-box1{
		gap: 10px;
	}
	&-title {
		color: #18181B;
		font-size: 16px;
		font-weight: 600;
        line-height: 24px; 
	}
	&-confirm-title{
		font-family: Inter;
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		color: #26272B !important;
		//padding-top: 4px;
		//padding-bottom: 4px;
	}
	&-confirm-content{
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
		padding-top: 4px;
		padding-bottom:24px;
	}
	&-confirm-header {
		display: flex;
		justify-content: space-between;
		text-overflow: ellipsis;
		text-wrap: wrap;
		height: 54px;
		padding-top: 24px;
	}
	&-confirm-address {
		color: #18181B !important;
		font-size: 16px;
		font-weight: 600;
        line-height: 24px; 
		font-family: Inter;		
		text-overflow: ellipsis;
		text-wrap:wrap ;
	}
	&-content {
		color: #18181B;
		font-size: 14px;
		font-weight: 400;
        line-height: 20px;
    }
	&-header {
		padding-top: 10px;
		padding-bottom: 10px;
		display: flex;
		justify-content: space-between;

    }
	&-selectcrypto{
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #F4F4F5;
	}
	&-actions{
		display: flex;
		justify-content: space-between;
		padding-left: 24px;
		padding-right: 24px;
	}
	&-next{
		width: 44px;
		height: 44px;
		border-radius: 50%;
		border: 1px solid #D1D1D6
	}
	&-editaddress{
		font-size: 24px;
	}
	&-dialog-content {
		border: 0px !important;
		padding-top: 8px !important;
		padding-bottom: 0px !important;
	}
	&__btns {
		display: flex;
		grid-template-columns: repeat(4, 1fr);
		justify-items: center;
		justify-content: space-around;
		p {
			font-size: 12px;
			text-align: center;
			overflow: hidden;
			max-width: 70px;
			white-space: nowrap;
			text-overflow: ellipsis;
			color: #000;
		}
		
	}
	&-close-icon svg path{
		fill:#18181B !important;
	}
	&-option-background{
		background: #F4F4F5;
		border: #D1D1D6;
	}
	&-content-input{

		position: relative; 
        display: inline-block; 
		
	}
	&-content-copy{
		position: absolute; 
        top: 15px; 
        right: 15px; 
	}
	&-confirmCopy svg{
		height: 24px !important;
		width: 24px !important;

	}
	&-action-delete{
		border-top: 1px;
		border-color: #D1D1D6;
	}
	&-warning-icon{
		height: 40px !important;
		width: 40px !important;
		margin: auto !important;

	}
	&-warning-icon path {
		height: 31.5px !important;
		width: 35px !important;
	}
	&-warning{
		max-height: 72px;
		max-width: 72px;
		border-radius: 99px;
		background-color: rgb(254, 240, 199);
		margin: auto;
		align-content: center;
		border: 8px solid rgb(255, 250, 235);
		height: 72px !important;

	}
}
.addressTwoBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    &-right {
        display: flex;
        align-items: flex-end;
        gap: 10px;
    }
}

@media screen and (max-width: 515px) {
	.addressTwoBtns {
	&-right {
			width: 88%;
		}
	}
	
	.jn-addressdetails-dialog-content {
		padding-top:34px !important ;
	}
	.jn-addressdetails-content {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.jn-addressdetails-confirm{
		max-height: 433px !important;
		max-width: 351px !important;
	}
	.jn-addressdetails-delete{
		max-height: 336px !important;
		max-width: 351px !important;
	}
	.jn-addressdetails-selectcrypto {
		border-color: #E4E4E7;
		}
	.jncActionBtn {
		background: #F4F4F5;
		border: 0px;
	}
	.jn-addressdetails-next {
		background: #F4F4F5;
		border: 0px;
	}
	.jncModal__actionDelete {
		background: #F4F4F5;
		border: 0px;
	}
	.jn-addressdetails-selectcrypto {
		padding-top: 12px;
	}
	.jn-addressdetails-copy {
		 align-items: center;
		color: #1570EF;
	}
	.jn-addressdetails-copy svg rect {
		stroke: #1570EF;
	}
	.jn-addressdetails-copy svg path {
		stroke: #1570EF;
	}
	.jn-addressdetails-copy-none-align {
		color: #1570EF;
	}
	.jn-addressdetails-copy-none-align svg rect {
		stroke: #1570EF;
	}
	.jn-addressdetails-copy-none-align svg path {
		stroke: #1570EF;
	}
	.jn-addressdetails__btns_text{    
		text-align: start !important;
        margin-left: 10px;
		margin-top: 10px;
		font-size: 12px;}
	.jn-addressdetails-header{
		overflow: hidden;
	}
}

@media screen and (max-width: 768px) {
	.jn-addressdetails-success{
		max-height: 394px !important;
		max-width: 351px !important;
	}
}
