.jnAm-hero {
	margin-top: -100px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(20, 29, 41, 0.94) 0%, rgba(20, 29, 41, 0.96) 100%), #DEDEDE;
		opacity: .8;
		z-index: 1;
	}
	&-content {
		max-width: 615px;
		width: 100%;
		position: relative;
		z-index: 2;
		.line {
			margin: 20px 0;
			height: 1px;
			width: 66px;
			background: var(--accent-color);
		}
	}
	.jnAm-btn {
		margin: 30px 0 118px;
	}
	&-scroll {
		position: relative;
		color: rgba(255, 255, 255, 0.70);
		letter-spacing: 0.1px;
		&:before {
			content: '';
			position: absolute;
			width: 335px;
			left: calc(-335px - 22px);
			height: 1px;
			background: var(--accent-color);
			top: 0;
			bottom: 0;
			margin: auto 0;
		}
	}
	&-rect {
		position: absolute;
		top: 0;
		left: 0;
		width: 20%;
		height: 100%;
		z-index: 1;
	}
	.content-container {
		position: relative;
		z-index: 1;
		padding-top: 110px;
	}
}

@media screen and (max-width: 1090px) {
	.jnAm-hero-rect {
		display: none;
	}
	.jnAm-hero-scroll {
		margin-left: 213px;
	}
}

@media screen and (max-width: 570px) {
	.jnAm-hero-scroll {
		margin-left: 96px;
	}
	.jnAm-hero .jnAm-btn {
		margin: 20px 0 60px;
	}
}