@import './mixins';
$bg-color: #FFFFFF;

.modal-dialog {
  max-width: 565px !important;
  margin-top: 111.48px;
}
.loginSignupContent > .modal-dialog, .loginSignupContent > .modal-dialog > .modal-content {
  max-width: 444px !important;
  border: none !important;
  background-color: transparent !important;
  margin-left: auto;
  margin-right: auto;
}
.loginSignupCard {
  max-width: 444px;
  border-radius: 8px;
  padding: 30px 30px 35px 30px;
  border: 1px solid #0091ff90;
  background: radial-gradient(circle, #0F1029 0%, #000000 100%);
  box-shadow: 0 0 40px 10px rgba(0,145,255,0.25);
  .loginSignupLogoWrap {
    @include flexBoxJustifyCenter;
    .loginSignupLogo {
      width: 50px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
    }
    .loginSignupLogoAutowidth {
      width: auto;
      max-width: 300px;
      margin-bottom: 15px;
    }
  }
  .form-group-payment{
    margin-bottom: 19px;
    min-height: 78px;
    @include flexBox;
    flex-direction: column;
  }
  .project_emailId {
    font-weight: 500;
    width: 100%;
    margin-bottom: 21px;
    min-height: 50px;
    color: #C1C1C1;
    font-size: 16px;
    letter-spacing: 1.35px;
    line-height: 22px;
    text-align: center;
  }
  .loginSignupHeading {
    @include flexBoxJustifyCenter;
    width: 100%;
    height: 38px;
    color: #{$mainTextColor};
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0.08px;
    line-height: 38px;
    margin-top: 19px;
    margin-bottom: 20px;
    text-align: center;
  }
  .project_emailId_input {
    height: 40px;
    width: 100%;
    padding-left: 19px;
    color: #B8B8B8;
    border: 1px solid transparent;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
    border-radius: 20px;
    background-color: #252525 !important;
  }
  .project_emailId_errorBorder {
    border-color: #A40000;
  }
  .project_emailId_input:hover {
    color: #{$mainTextColor}
  }
  .project_inputLabel {
    height: 27px;
    width: 100%;
    color: #{$mainTextColor};
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1.35px;
    line-height: 27px;
    margin-bottom: 10px;
    @include flexBoxSpaceBetween;
  }
  .loginFormErrorMsg {
    color: #A40000;
    font-size: 15px;
    letter-spacing: 1.35px;
    line-height: 27px;
    text-align: right;
  }
  .project_emailId_input::placeholder {
    line-height: 16px;
    opacity: 1;
    height: 40px;
    width: 100%;
    color: #B8B8B8;
    font-size: 15px;
    letter-spacing: 1.35px;
    line-height: 27px;
    background-color: transparent !important;
  }
  .project_emailId_input:focus {
    outline: none;
    border: 1px solid #0091FF;
    width: 100%;
  }
  .project_inputField {
    border-radius: 20px;
    width: 100%;
    background-color: #252525 !important;
    position: relative;
    .loginFormErrorIcon {
      color: #A40000;
      position: absolute;
      right: 18px;
      top: 10px;
      font-size: 16px;
    }
  }
  .project_passwordChanged {
    border-radius: 4px;
    width: 100%;
    background-color: #252525 !important;
    position: relative;
    color: #fff;
    text-align: center;
    .passwordChangedIcon{
      height: 36px;
      width: 36px;
      margin-right: 13px;
    }
    .passwordCloseIcon{
      margin-left: 45px;
    }
  }
  .project_loginSubmit_btn {
    height: 50px;
    width: 100%;
    color: #{$mainTextColor};
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.24px;
    line-height: 19px;
    text-align: center;
    border-radius: 25px;
    margin-top: 40px;
    margin-bottom: 36px;
    background-color: #{$mainColor};
  }
  .project_loginSubmit_btn:hover, .project_loginSubmit_btn:focus {
    background-color: #{$mainHoverColor};
    box-shadow: 0 0 24px 0 rgba(0,145,255,0.5);
    text-shadow: 0px 2px 17px #FFFFFF;
    border-color: #{$mainHoverColor};
  }
  .project_loginSubmit_btn:active {
    border: 1px solid #7BC6FF;
  }
  .project_loginSubmit_btn_disabled, .project_loginSubmit_btn_disabled:focus, .project_loginSubmit_btn_disabled:hover {
    background-color: #828282;
    border-color: #828282;
    color: #A3A3A3;
    box-shadow: none;
    text-shadow: none;
  }
  .project_forgot_password {
    display: flex;
    color: #0091FF;
    cursor: pointer;
    justify-content: flex-end;
  }
  .project_signupBottom_wrap {
    @include flexBoxJustifyCenter;
    flex-direction: column;
    min-height: 27px;
    width: 100%;
    .project_haveAccount {
      color: #{$mainTextColor};
      min-height: 27px;
      font-size: 15px;
      letter-spacing: 1.35px;
      line-height: 27px;
      text-align: center;
      .project_loginNavBtn {
        display: inline-block;
        color: #{$mainColor};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .loginSignupCard {
    max-width: 480px;
    min-height: 100vh;
    padding: 0;
    border: none;
    box-shadow: none;
    background: radial-gradient(circle, #0F1029 0%, #000000 100%);
  }
  .loginSignupCardBG {
    padding: 40px 16px 31px 16px;
    max-width: 480px;
    width: 100%;
    min-height: 99.8vh;
    background-repeat: no-repeat;
    background-position-x: -696px;
    background-position-y: -302px;
  }
  .loginSignupContent > .modal-dialog, .loginSignupContent > .modal-dialog > .modal-content {
    margin-top: 0px;
    max-width: 480px !important;
    margin-bottom: 0px;
  }
  .project_signupBottom_wrap {
    position: absolute;
    bottom: 31px;
  }
  .loginSignupCard {
    .loginSignupHeading {
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 0.07px;
      line-height: 31px;
      margin-top: 19px;
      margin-bottom: 20px;
      text-align: center;
    }
    .project_emailId {
      color: #{$mainTextColor};
    }
  }
}


.inp {
  width: 278px;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 18px;
}

.btn-geet {
  display: inline-block;
  width: 100px;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  background: white linear-gradient(180deg, #fff 0%, #f3f3f3 100%);
  border-radius: 2px;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  line-height: 40px;
  display: none;
}

.btn-geet:hover {
  background: white linear-gradient(0deg, #fff 0%, #f3f3f3 100%);
}

#captcha {
  display: inline-block;
  // width: 300px;
  height: 50px;
  width: 100%;
}

label {
  display: inline-block;
  width: 80px;
  text-align: right;
  vertical-align: top;
}

#wait {
  margin: 0;
  color: #666;
  text-align: left;
}

.geetest_float {
    display: flex;
    justify-content: center;
}

#captcha .geetest_box_wrap .geetest_box{
  position: relative;
  top:100px;
}