.jn-hero {
	background: #081428;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	min-height: 800px;
	margin-top: -80px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(180deg, rgba(8,20,40,0.3) 0%, rgba(8,20,40,1) 100%);
		left: 0;
		z-index: 1;
	}
	.content-container {
		position: relative;
		z-index: 1;
	}
	&-top {
		width: 550px;
    height: 500px;
    top: 0;
    right: -300px;
    position: absolute;
    object-fit: contain;
		animation: 1s fromOpacity .2s forwards;
		opacity: 0;
	}
	&-bottom {
		width: 450px;
    bottom: -20px;
    left: -100px;
    position: absolute;
    object-fit: contain;
		animation: 1s fromOpacity .2s forwards;
		opacity: 0;
	}
	&-title {
		font-size: 72px;
		font-weight: 800;
		color: #fff;
		text-align: center;
		line-height: 1;
		margin-bottom: 24px;
		font-weight: 600;
		span {
			color: #28E7C5;
			text-align: center;
			font-weight: 800;
		}
	}
	&-subtitle {
		font-size: 20px;
		text-align: center;
		margin-bottom: 23px;
	}
	&-content {
		max-width: 900px;
		width: 100%;
		margin: 0 auto;
	}
	.theme-btn-block {
		margin-top: 32px;
	}
	&-gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		animation: 1s fromOpacity 1s forwards;
	}
}

.jn-video {
	position: absolute;
	top: 0; 
	left: 0;
	width: 100%;
	height: 100%;
	&.homepage {
		video {
			background-image: url('https://dev.junomoney.org/images/junoclient/palace.webp');
		}
	}
	&.individual {
		video {
			background-image: url('https://dev.junomoney.org/images/junoclient/city-zoom.webp');
		}
	}
	&.corporate {
		video {
			background-image: url('https://dev.junomoney.org/images/junoclient/bw-city.webp');
		}
	}
	&.banking {
		video {
			background-image: url('https://dev.junomoney.org/images/junoclient/turkish-city.webp');
		}
	}
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		background-size: cover;
		background-position: center;
	}
}

@keyframes fromOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media screen and (min-width: 2000px) {
	.jn-hero-top {
		right: -30px;
		top: 60px;
		width: 470px;
		height: 470px;
	}
	.jn-hero-bottom {
		left: 59px;
		width: 450px;
	}
	.jn-hero-gradient {
		right: 0;
		margin: 0 auto;
		max-width: 2000px;
	}
	.jn-hero {
		max-height: 740px;
	}
}

@media screen and (max-width: 1300px) {
	.jn-hero-title {
		font-size: 60px;
	}
	.jn-hero-subtitle {
		font-size: 18px;
	}
	.jn-hero-top {
		width: 400px;
		height: 400px;
		right: -172px;
		top: 50px;
	}
	.jn-hero-bottom {
		width: 320px;
	}
}

@media screen and (max-width: 991px) {
	.jn-hero-title {
		font-size: 40px;
	}
	.jn-hero-subtitle {
		font-size: 16px;
	}
	.jn-hero-top {
		width: 272px;
		height: 272px;
		right: -60px;
		top: 60px;
	}
}

@media screen and (max-width: 700px) {
	.jn-hero-top {
		right: -137px;
	}
	.jn-hero-bottom {
		width: 300px;
	}
}

@media screen and (max-width: 420px) {
	.jn-hero-title {
		br {
			display: none;
		}
	}
}

@media screen and (max-width: 360px) {
	.jn-hero-title {
		font-size: 35px;
	}
}