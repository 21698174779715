.junoClient {
	background: #081428;
	overflow: hidden;
	&-title {
		color: #fff;
		text-align: center;
		font-size: 56px;
		font-weight: 700;
		line-height: 110%;
		letter-spacing: -1.6px;
		&.mb {
			margin-bottom: 90px;
		}
		@media screen and (max-width: 991px) {
			font-size: 42px;
			&.mb {
				margin-bottom: 56px;
			}
		}
		@media screen and (max-width: 620px) {
			font-size: 32px;
		}
	}
	&-subtitle {
		max-width: 838px;
		margin: 24px auto 100px;
		text-align: center;
		@media screen and (max-width: 991px) {
			max-width: 600px;
		}
	}
	&-text {
		color: #EAECF0;
		line-height: 125%;
		font-size: 18px;
		@media screen and (max-width: 991px) {
			font-size: 16px;
		}
	}
	&-btn {
		padding: 16px 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		transition: .15s;
		border-radius: 8px;
		font-weight: 400;
		@media screen and (max-width: 991px) {
			font-size: 16px;
		}
		svg {
			width: 20px;
			height: 20px;
			object-fit: contain;
			margin-left: 10px;
			path {
				transition: .15s;
			}
		}
		&-violet {
			background: var(--btn-background);
			color: #fff;
			position: relative;
			overflow: hidden;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(255, 255, 255, .17);
				transition: .15s;
				opacity: 0;
			}
			&:hover {
				&:before {
					opacity: 1;
				}
				// background: #7D58EF;
			}
		}
		&-outlined {
			border: 1px solid #fff;
			color: #fff;
			&:hover, &.active {
				background: #fff;
				color: #091324;
				svg path {
					fill: #091324;
				}
			}
		}
	}
	&-gradient {
		position: relative;
		background-color: #081428;
		overflow: hidden;
		&-bottom {
			width: 2680px;
			position: absolute;
			bottom: -35%;
			left: -800px;
			z-index: 1;
			&-el {
				position: absolute;
				left: -22px;
				bottom: 0;
				width: 400px;
				height: auto;
				bottom: 12px;
			}
		}
		&-top {
			width: 1920px;
			position: absolute;
			top: -35%;
			right: -960px;
			z-index: 1;
			&-el {
				position: absolute;
				right: -104px;
				top: 120px;
				width: 380px;
				height: auto;
				opacity: .7;
			}
		}
	}
	//todo:kris change all sections in juno to junoClient-section
	&-section {
		padding: 95px 0;
		@media screen and (max-width: 1090px) {
			padding: 65px 0;
		}
	}
}

.data-loader {
	&-block {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, .8);
	}
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--btn-background);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}



@media screen and (min-width: 2000px) {
	.junoClient-gradient {
		max-width: 2000px;
		overflow: initial;
		margin: 0 auto;
	}
	.junoClient-gradient-bottom {
		bottom: -30%;
    left: -565px;
	}
	.junoClient-gradient-bottom-el {
		left: 88px;
	}
	.junoClient-gradient-top-el {
		right: 68px;
		top: 94px;
	}
}

@media screen and (max-width: 1300px) {
	.junoClient-gradient-bottom-el {
		width: 350px;
		left: -57px;
	}
	.junoClient-gradient-bottom {
		left: -600px;
	}
	.junoClient-gradient-top-el {
		right: -200px;
	}
}

@media screen and (max-width: 991px) {
	.junoClient-gradient-bottom {
		width: 2000px;
		left: -500px;
		bottom: -26%;
	}
	.junoClient-gradient-top {
    opacity: .4;
	}
	.junoClient-gradient-top-el {
		width: 220px;
		right: -133px;
	}
}

@media screen and (max-width: 533px) {
	.junoClient-gradient-bottom {
		bottom: -20%;
	}
}

// @media screen and (max-width: 480px) {
// 	.junoClient-gradient-top {
// 		width: 800px;
// 		top: -200px;
// 	}
// }