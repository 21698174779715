.jn-asset {
	background: #091324;
	position: relative;
	z-index: 2;
	.junoClient-title {
		text-align: left;
	}
	&-img {
		display: flex;
		img {
			width: 110%;
			height: auto;
			position: relative;
			left: calc((100vw - 1480px) / -2);
		}
	}
	&-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
	}
	&-icon {
		width: 48px;
		height: auto;
		margin-bottom: 24px;
	}
	&-title {
		color: #fff;
		font-size: 32px;
		font-weight: 700;
		margin-bottom: 16px;
		line-height: 120%;
	}
}

@media screen and (min-width: 2000px) {
	.jn-asset-img {
		img {
			left: 0;
			width: 100%;
		}
	}
	.jn-asset-grid {
		grid-gap: 80px;
		border: 1px solid rgba(255, 255, 255, 0.12);
		border-radius: 18px;
		padding: 40px 40px 40px 0;
	}
}

@media screen and (max-width: 1550px) {
	.jn-asset-img {
		img {
			left: calc((100vw - 1200px) / -2);
		}
	}
}

@media screen and (max-width: 1300px) {
	.jn-asset-img {
		img {
			left: calc((100vw - 1000px) / -2);
		}
	}
}

@media screen and (max-width: 1058px) {
	.jn-asset-img {
		img {
			left: calc((100vw - 950px) / -2);
			width: 100%;
		}
	}
	.jn-asset-title {
		font-size: 28px;
	}
}

@media screen and (max-width: 991px) {
	.jn-asset-img {
		img {
			left: calc(8vw / -2);
		}
	}
	.jn-asset-grid {
		align-items: flex-start;
	}
}

@media screen and (max-width: 700px) {
	.jn-asset-grid {
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}
}