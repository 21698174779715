.jncTotalBalance {
    text-align: center;
    margin-bottom: 16px;

    p {
        line-height: 124%;

        &:first-child {
            color: #667085;
            font-size: 18px;
        }

        &:last-child {
            color: #0a0a0a;
            font-size: 30px;
            font-weight: 600;
        }
    }
}

.jncDashbTabs {
    &__seeAll {
        width: 100%;
        margin-bottom: 26px;
        border-radius: 99px;
        border: 1px solid #d0d5dd;
        text-align: center;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 12px 18px;
        margin-bottom: 26px;
        display: block;
        text-decoration: none;
        margin-top: 24px;
    }
}

.jncDesktopTotalBalance {
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        @media screen and (max-width: 1300px) {
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }
    }
    &__welcomeMessage {
        font-size: 18px;
        color: #344054;
        margin-bottom: 24px;
    }
    &__balance {
        font-size: 30px;
        font-weight: 600;
    }
    &__progressBar {
        display: flex;
        margin-bottom: 24px;
        height: 8px;
        border-radius: 2;
        overflow: hidden;
    }
    &__dot {
        height: 6px;
        width: 6px;
        border-radius: 100%;
    }
    &__contribution {
        display: flex;
        gap: 20px;
    }
    &__value {
        font-size: 14px;
    }
    &__currencies {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
    }
    &__subheading {
        font-size: 24px;
        font-weight: 300;
        font-family: var(--stagnanFont);
    }
}

.dashboardHeaderActions {
    display: flex;
    gap: 10px;
    &__button {
        padding: 6px 12px;
        border-radius: 99px;
        border: 1px solid #d0d5dd;

        &:disabled {
            opacity: 0.6; // Example for disabled state
        }
    }
}

@media screen and (max-width: 767px) {
    .jncBalances.is-dashboard {
        margin-top: 0;

        .hide-on-mobile {
            display: none;
        }
    }
}

@media screen and (max-width: 991px) {
    .jncBalances,
    .jncTransactions {
        &.is-dashboard {
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 520px) {
    .jncTotalBalance {
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .jncDesktopTotalBalance__contribution {
      overflow-x: auto;
    }
  }