body {
	--btn-background: #0091FF; 
	--btn-text-color: #fff;
	--btn-background-disabled: #5394c4;
}

.paysu-b2c-theme {
	--btn-text-color: #1E1E1E;
}

.theme-btn-block {
	display: flex;
	&.full-width {
		width: 100%;
		.theme-btn, .junoClient-btn {
			width: 100%;
		}
	}
	&.mt-subtitle {
		margin-top: 32px;
	}
	&.center {
		justify-content: center;
	}
	&.full-on-mobile {
		@media screen and (max-width: 600px) {
			width: 100%;
			.theme-btn {
				width: 100%;
			}
		}
	}
}

.theme-btn {
	padding: 10px 27px;
	border-radius: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: .15s;
	background: var(--btn-background);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	text-decoration: none !important;
	color: #fff;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, .17);
		transition: .15s;
		opacity: 0;
	}
	&:hover {
		&:before {
			opacity: 1;
		}
	}
	p {
		font-size: 16px;
		letter-spacing: 0.6px;
		// color: var(--btn-text-color);
	}
	&.disabled, &:disabled {
		filter: grayscale(0.8);
		cursor: initial;
	}
}

.theme-btn-red {
	background: #F04438;
	&:hover {
		background: #D92D20;
	}
}


.theme-btn-transparent {
	border: 1px solid var(--btn-background);
	background: transparent;
	color: var(--btn-text-color);
	&:hover {
		opacity: .5;
	}
}

