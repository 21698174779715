.legal {
	background-color: rgba(3, 5, 21, 1);
	background-image: url('https://dev.junomoney.org/images/upd/stars.webp');
	&__title {
		font-weight: 700;
		font-size: 22px;
		margin-top: 20px;
		font-family: 'Intel', sans-serif;
	}
	&__desc {
		color: #E0E0E0;
		line-height: 150%;
	}
	.bold{
		font-weight: bold;
	}
	table td{
		border-bottom: 1px solid white;
		padding: 10px;
		border-left: 1px solid white;
	}
}

@media screen and (max-width: 760px) {
	.legal__title {
		font-size: 19px;
	}
}