.jncCharts {
	display: grid;
	grid-template-columns: 1.3fr 1fr;
	grid-gap: 16px;	
	&__card {
		padding: 24px;
		border-radius: 12px;
		border: 1px solid #EFEFEF;
		background: #FFF;
		display: grid;
		grid-template-rows: auto 1fr;
		grid-gap: 28px;
	}
	&__overview {
		display: grid;
		grid-template-columns: 250px 1fr;
		grid-gap: 24px;
		align-items: center;
	}
    &-history {
        width: 50%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 20px;
        &-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .jncModal__field .rbt-input{
                box-shadow: none;
                height: 40px;
            }
        }
}