.balance-crypto-info {
	border-radius: 8px;
	background: #FFF0E0;	
	padding: 12px;
	p {
		color: #000;
		text-align: center;
	}
}

.balance-crypto-copy {
	padding: 8px 12px;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 8px;
	align-items: center;
	cursor: pointer;
	border-radius: var(--input-border-radius);
	border: 1px solid #D0D5DD;
	margin-top: 24px;
	cursor: pointer;
	transition: .15s;
	&:hover {
		border-color: var(--theme-color);
	}
	p {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	svg {
		width: 16px;
		height: 16px;
	}
}

.balance-fiat-fx {
	.theme-modal-field-input {
		display: flex;
		align-items: center;
		img {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
	}
	&-exchange {
		display: flex;
		align-items: center;
		position: relative;
		font-size: var(--input-font-size);
		color: var(--modal-text-color);
		.data-loader {
			width: 15px;
			height: 15px;
			border-width: 1px;
		}
	}
}

.fx-confirm {
	&-img {
		width: 100%;
		height: auto;
		margin-bottom: 24px;
	}
	&-exchange {
		border-radius: 8px;
		border: 1px solid #D0D5DD;
		overflow: hidden;
	}
	&-group {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 5px;
		padding-top: 16px;
		align-items: center;
		&-value {
			display: flex;
			align-items: center;
			padding: 0 16px;
			&.end {
				justify-content: flex-end;
			}
			img {
				width: 24px;
				height: 24px;
				margin-right: 12px;
			}
			p {
				color: #1D2939;
				font-size: 22px;
				font-weight: 700;
				line-height: 30px;
			}
		}
	}
	&-rate {
		color: #344054;
		font-size: 14px;
		text-align: center;
		padding: 16px 0;
		background: #F9FAFB;
	}
	.divider {
		height: 1px;
		width: 100%;
		background: #D0D5DD;
		margin-top: 16px;
	}
}

.transConfirm {
	border-radius: 8px;
	border: 1px solid #D0D5DD;
	overflow: hidden;
	&__top {
		padding: 12px 12px 20px 12px;
		border-bottom: 1px solid #D0D5DD;
	}
	&__heading {
		display: grid;
		align-items: center;
		grid-template-columns: 1fr auto;
		grid-gap: 15px;
		margin-bottom: 20px;
	}
	&__title {
		color: #344054;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
	}
	&__attachment {
		display: flex;
		align-items: center;
		gap: 5px;
		background-color: #F2F4F7;
		border-radius: 99px;
		padding: 4px 8px;
		cursor: pointer;
		span {
			font-weight: 500;
			font-size: 16px;
		}
	}
	&__amount {
		display: flex;
		align-items: center;
		img {
			width: 24px;
			height: 24px;
			margin-right: 10px;
		}
		p {
			color: #1D2939;
			font-size: 22px;
			font-weight: 700;
			line-height: 30px;
		}
	}
	&__details {
		padding: 12px;
		background: #F9FAFB;
		&-group {
			display: grid;
			grid-template-columns: 120px 1fr;
			grid-gap: 15px;
		}
		.mb {
			margin-bottom: 12px;
		}
		&-label {
			color: #475467;
			font-size: 14px;
		}
		&-value {
			color: #344054;
			font-size: 14px;
			font-weight: 600;
		}
	}
	.inner-divider {
		height: 1px;
		width: 100%;
		background: #D0D5DD;
		margin: 12px 0;
	}
	.showMore-btn {
		font-weight: 600;
		font-size: 14px;
		color: #000;
	}
	.showless {
		display: none;
	}
	.showMore {
		display: block;
	}

	&-attachment {
		padding: 12px;
		&-heading {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			margin-bottom: 20px;
			span {
				font-weight: 600;
				font-size: 16px;
			}
		}
		&-file {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}

	.divider {
		height: 1px;
		width: 100%;
		background: #D0D5DD;
		margin: 12px 0 0;
	}
}


.jn-client-prefix-input {
	background: var(--input-background);
	border: 1px solid var(--input-border-color);
	border-radius: var(--input-border-radius);
	font-size: var(--input-font-size);
	display: grid;
	grid-template-columns: auto 1fr;
	overflow: hidden;
	box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.15);
	&.has-error {
		box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		border-color: #FDA29B;
	}
	span {
		width: 40px;
		color: #475467;
		text-align: center;
		border-right: 1px solid #D0D5DD;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	input {
		padding: 11px 14px;
		border: 0;
		color: var(--modal-text-color);
		box-shadow: none !important;
		background: var(--input-background);
		&::placeholder {
			color: var(--input-placeholder-color);
		}
	}
	
}

.jn-client-upload {
  width: 100%;
  text-align: center;
  position: relative;
	input {
		display: none;
	}
	&-label {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		border: 1px solid #D0D5DD;
		background: #fff;
		padding: 16px;
		cursor: pointer;
		&.drag-active {
			background: #f5f5f5;
			border-style: dotted;
		}
	}
	&-svg {
		margin: 0 auto 12px;
		display: block;
		width: 40px;
		height: 40px;
	}
	&-text {
		font-size: 14px;
		color: var(--modal-light-text-color);
		text-align: center;
		button {
			color: #000;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.jn-client-attachment {
	border-radius: 8px;
	border: 1px solid #D0D5DD;
	padding: 12px 16px;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 10px;	
	align-items: center;
	&:not(:last-child) {
		margin-bottom: 8px;
	}
	&-text {
		color: #667085;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		span {
			color: #344054;
			font-weight: 600;
		}
	}
	&-delete {
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		svg {
			width: 100%;
			height: 100%;
		}
	}
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.savedAccounts {
	border-radius: 8px;
	// background: rgba(118, 118, 128, 0.12);
	background: #F2F4F7;
	padding: 2px;
	margin-bottom: 24px;
	
	&-tabs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		button {
			padding: 8px;
			text-align: center;
			color: #000;
			text-align: center;
			font-size: 14px;
			position: relative;
			transition: all .15s;
			&.active {
				background: #fff;
				border-radius: 7px;
				border: 0.5px solid rgba(0, 0, 0, 0.04);
				box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12);
			}
		}
	}
	&-item {
		border-radius: 8px;
		border: 1px solid #D0D5DD;
		padding: 16px;
		display: grid;
		grid-template-columns: auto 1fr auto;
		grid-gap: 12px;
		align-items: center;
		transition: .15s;
		cursor: pointer;
		width: 100%;
		text-align: left;
		&:not(:last-child) {
			margin-bottom: 12px;
		}
		&:hover {
			border-color: #000;
		}
		p {
			color: #1D2939;
			font-size: 16px;
			font-weight: 500;
			span {
				color: #667085;
				font-weight: 400;
			}
		}
	}
	&-edit {
		width: 30px;
		height: 30px;
		padding: 2px;
		display: flex;
		cursor: pointer;
		transition: .15s;
		border-radius: 6px;
		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			path {
				transition: .15s;
			}
		}
		&:hover {
			background: #F2F4F7;
			svg path {
				stroke: #344054;
			}
		}
	}
	&-btns {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 10px;
		align-items: center;
		&-right {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 10px;
			.min-516 {
				.jncButton {
					width: 100%;
				}
			}
		}
	}
	&-delete {
		display: flex;
		width: 44px;
		height: 44px;
		border: 1px solid #D0D5DD;
		padding: 10px;
		border-radius: 50%;
		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}			
	}
}

@media screen and (max-width: 767px) {
	.fx-confirm-group {
		display: block;
		padding-top: 0;
	}
	.fx-confirm-exchange {
		border: 0;
		border-radius: 0;
	}
	.fx-confirm-group-label {
		color: #000;
		font-size: 16px;
		margin-bottom: 10px;
		&.mt {
			padding-top: 12px;
			border-top: 1px solid #EAECF0;
		}
	}
	.fx-confirm-group-value {
		padding: 0;
		margin-bottom: 24px;
	}
	.fx-confirm-group-value.end {
		justify-content: flex-start;
	}
	.fx-confirm-group-value p {
		font-size: 18px;
		font-weight: 500;
		color: #000;
	}
	.fx-confirm-rate {
		padding: 0;
		background: 0;
		font-size: 16px;
		font-weight: 500;
		color: #000;
		text-align: left;
	}
}

@media screen and (max-width: 515px) {
	.savedAccounts-btns-right {
		display: flex;
		justify-content: flex-end;
		.jncButton {
			min-width: 140px;
		}
	}
}

@media screen and (max-width: 510px) {
	.jn-client-modal-successBtns {
		grid-template-columns: 1fr;
		margin-top: 48px;
		.juno-client-btn {
			margin-top: 0;
		}
	}
}

@media screen and (max-width: 400px) {
	.savedAccounts-tabs button {
		font-size: 14px;
	}
	.savedAccounts-item {
		padding: 14px;
		p {
			font-size: 14px;
		}
	}
	.savedAccounts-item-icon {
		width: 30px;
		height: 30px;
	}
}

@media screen and (max-width: 390px) {
	.balance-fiat-fx-group {
		grid-template-columns: 1fr;
	}
}