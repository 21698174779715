.loader {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	&-block {
		position: fixed;
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		z-index: 10;
		background: #f0f0f0;
	}
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #222;
		border-radius: 50%;
		animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		// border-color: var(--btn-background) transparent transparent transparent;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.junoClient {
	.loader-block {
		background: #091428;
	}
	.loader div {
		border-color: #2ae8c6 transparent #fff transparent;
	}
}