.juno-btn {
	border: 1px solid var(--btn-background);
	border-radius: 160px;
	padding: 28px 48px;
	display: flex;
	align-items: center;
	transition: .2s ease-in-out;
	text-decoration: none !important;
	justify-content: center;
	&.login-btn {
		padding: 20px 32px;
		@media screen and (max-width: 690px) {
			p {
				font-size: 14px;
			}
		}
		@media screen and (max-width: 400px) {
			padding: 15px 25px;
		}
	}
	p {
		color: #fff;
		font-size: 16px;
		letter-spacing: 0.6px;
		text-decoration: none;
	}
	&:hover {
		background: var(--btn-background);
	}
}

.juno-client-btn {
	border-radius: 99px;
	padding: 11px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: .15s;
	p {
		text-align: center;
		font-size: 16px;
		letter-spacing: 0.6px;
	}
	&.filled-style {
		background: var(--btn-background);
		p {
			color: #fff;
		}
		&:hover {
			filter: brightness(1.1);
		}
		svg {
			margin-right: 6px;
			path {
				fill: #fff;
			}
		}
	}
	&.outlined-style {
		border: 1px solid #D0D5DD;
		&:hover {
			background: #ebedf0;
		}
		p {
			color: #344054;
		}
	}
	&:disabled {
		pointer-events: none;
		filter: grayscale(0.7) brightness(1.4);	
	}
	&.full-width {
		width: 100%;
	}
}

.jncAddItem {
	border: 1px dashed #98A2B3;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 24px;
	transition: .2s;
	width: 100%;
	@media screen and (max-width: 991px) {
		&:not(.no-mt) {
			margin-top: 24px;
		}
	}
	&:hover {
		background: #fff;
	}
	p {
		color: #344054;
		font-size: 18px;
		font-weight: 500;
		margin-top: 12px;
		@media screen and (max-width: 767px) {
			font-size: 14px;
		}
	}
	svg {
		width: 48px;
		height: auto
	}
	&:disabled {
		opacity: .4;
	}
	&.manage-currencies {
		min-height: 190px;
	}
}

@media screen and (max-width: 991px) {
	.juno-btn {
		padding: 24px 48px;
	}
}

@media screen and (max-width: 991px) {
	.jncAddItem p {
		font-size: 16px;
	}
}

@media screen and (max-width: 450px) {
	.jncAddItem.is-dashboard svg {
		width: 60px;
		height: 60px;
	}
}