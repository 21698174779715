.jncExpanded {
	background: #F9FAFB;
	padding: 24px;
	box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	&:empty {
		display: none;
	}
	&-heading {
		color: #000;
		font-size: 18px;
		margin-bottom: 24px;
	}
	&-fxRate {
		display: flex;
		align-items: center;
		svg {
			width: 22px;
			height: 22px;
			margin-left: 6px;
		}
	}
	&-block {
		&-copy {
			display: grid;
			grid-template-columns: auto 1fr;
			// align-items: center;
			p {
				&.is-ellipsis {
					width: 100%;
					text-overflow: ellipsis;
					max-width: 100%;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
		
	}
	&-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 25px 55px;
	}
	&-label {
		font-size: 14px;
		color: #667085;
		margin-bottom: 5px;
	}
	&-failed {
		color: #D92D20;
	}
	&-text {
		font-size: 16px;
		color: #344054;
		font-weight: 600;
		&-icon {
			display: flex;
			align-items: center;
			img {
				width: 30px;
				height: 30px;
				object-fit: cover;
				margin-right: 10px;
				border-radius: 50%;
			}
		}
		span {
			color: #98A2B3;
		}
	}
	&-copy {
		display: flex;
		margin-left: 10px;
		width: max-content;
		padding: 2px;
		&:hover {
			svg.copy-icon rect, svg.copy-icon path {
				stroke: var(--theme-color);
			}
		}
		svg {
			width: 20px;
			height: 20px;
			object-fit: contain;
			&.copy-icon rect, path {
				transition: .15s;
			}
		}
	}
	&-attachs {
		margin-top: 45px;
		&-row {
			display: flex;
			flex-wrap: wrap;
			position: relative;
			left: -3px;
		}
		&-item {
			background: #EAECF0;
			border-radius: 8px;
			width: 44px;
			margin: 3px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .15s;
			cursor: pointer;
			&:hover {
				background: #eeeff2;
			}
			svg {
				height: 45%;
				width: auto;
			}
			&:not(:last-child) {
				margin-right: 12px;
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.jncExpanded-text {
		font-size: 14px;
	}
	.jncExpanded-heading {
		font-size: 16px;
	}
	.jncExpanded-label {
		margin-bottom: 0;
		font-size: 13px;
	}
	.jncExpanded-attachs {
		margin-top: 20px;
	}
}

@media screen and (max-width: 767px) {
	.jncExpanded {
		padding: 12px;
	}
	.jncExpanded-grid {
		grid-template-columns: 1fr;
		grid-gap: 10px;
		.jncExpanded-copy {
			margin-left: auto;
		}
	}
}
