.juno-client-theme {
	.juno-snackbar {
		width: 500px;
		height: auto;
		background: #101828;
		border-radius: 8px;
		&-msg {
			width: 100%;
			background: #101828;
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
		}
	}
}

@media screen and (max-width: 991px) {
    .juno-client-theme {
        .juno-snackbar {
            width: 100%;
        }
    }
}