@import './_mixins.scss';

.transform-capitalize {
	text-transform: capitalize;
}

.b2c-settings-header3{
    color: #000;
    font-size: 16px;
    letter-spacing: 0.04px;
    line-height: 20px;
  }
.CP_myAccounts_main {
    min-height: calc(100vh - 240px);
    justify-content: center;

    .myAccountHeaderSectionWrap {
        width: 100%;
        border-bottom: 1px solid #51515D;
    }

    .myAccountHeaderSection {
        box-shadow: 0 1px 11px 2px rgba(0, 0, 0, 0.08);
        background-position-y: -209px;
        background-repeat: no-repeat;
        padding-top: 90px;
        background-position-x: 567px;
    }

    .myAccHeaderMain {
        @include flexBoxJustifyCenter;
        background-size: cover;
        width: 100%;

        .myAccHeaderWrap {
            min-height: 117.66px;
            justify-content: flex-end;
            width: 100%;
            padding: 16.66px;
            @include flexBox;
            align-items: flex-end;

            .myAccEditCover {
                height: 40px;
                width: 180px;
                background-color: #ffffff;
                border-radius: 4px;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
                color: #000000;
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 0.04px;
                line-height: 20px;
                cursor: pointer;
                @include flexBoxJustifyCenter;

                .myAccEditCoverIcon {
                    margin-right: 10px;
                }
            }
        }
    }
.navTabBtn {
    color: #A6A6A7;
    font-size: 14px;
    letter-spacing: 1.18px;
    line-height: 16px;
    font-weight: normal !important;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    @include flexBoxJustifyCenter;
    min-width: fit-content;
    padding: 10px;
    margin-right: 40px;
}
.navTabBtnActive {
    color: var(--content-text-color);
    border-bottom: 2px solid #{$mainColor};
    font-weight: bold !important;
}
.navTabsWrap {
    display: flex;
    width: 90%;
    justify-content: flex-start;
    margin: auto;
}
.navTabBtnActive:hover {
    color: var(--content-text-color);
}

    .myAccNavTabs {
        .myAccNavTabsWrap {
            padding-left: 16.66px;
            padding-right: 16.66px;
            @include flexBox;
            min-height: 37.54px;
            justify-content: flex-start;

            .myAccNavTabBtn {
                color: #A6A6A7;
                font-size: 14px;
                letter-spacing: 1.18px;
                line-height: 16px;
                font-weight: normal !important;
                border-bottom: 2px solid transparent;
                margin-right: 50px;
                min-height: 37px;
                cursor: pointer;
                @include flexBoxJustifyCenter;
            }

            .myAccNavTabBtn:hover {
                color: var(--content-text-color);
            }

            .myAccNavTabBtnActive {
                color: var(--content-text-color);
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1.18px;
                line-height: 17px;
                border-bottom: 2px solid #{$mainColor};
                margin-right: 50px;
                min-height: 37px;
                cursor: pointer;
                @include flexBoxJustifyCenter;
            }
        }
    }

    .myAccountTabsWrap {
        min-height: 55vh;
        .myAccSettingsMain {
            min-height: 100vh;
            width: 100%;
            background-color: #FAFAFA;

            .myAccSettingsWrap {
                padding-left: 16.66px;
                padding-right: 16.66px;
                width: 100%;

                .SuccessAlert,
                .ErrorAlert {
                    width: 300px;
                    border-radius: 4px;
                    font-size: 14px;
                    padding: 15px;
                    font-weight: bold;
                    @include flexBoxJustifyCenter;
                    margin-bottom: -30px;
                    margin-top: 35px;
                }

                .SuccessAlert {
                    color: green;
                    background-color: #d2f8d2;
                }

                .ErrorAlert {
                    color: red;
                    background-color: #FFD9D9;
                }

                .updateMerchantSettings {
                    @include flexBoxJustifyCenter;
                    width: 200px;
                    min-height: 50px;
                    border-radius: 25px;
                    background-color: #{$mainColor};
                    color: #{$mainTextColor};
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 1.09px;
                    line-height: 22px;
                    text-align: center;
                    cursor: pointer;
                    margin-top: 35px;
                }

                .updateMerchantSettings:hover,
                .updateMerchantSettings:focus {
                    background-color: #{$mainHoverColor};
                    box-shadow: 0 0 24px 0 rgba(0, 145, 255, 0.5);
                    text-shadow: 0px 2px 17px #FFFFFF;
                    border-color: #{$mainHoverColor};
                }

                .myAccSettings_heading,
                .myAccSettings_heading_msg {
                    color: #000000;
                    font-size: 16px;
                    letter-spacing: 1.35px;
                    line-height: 22px;
                }

                .myAccSettings_heading {
                    margin-bottom: 5px;
                    margin-top: 10px;
                    font-size: 22px;
                    font-weight: bold;
                    letter-spacing: 0.06px;
                    line-height: 26px;
                }

                .myAccSettings_heading_msg {
                    margin-bottom: 20px;
                }

                .myAcc_settingItemWrap {
                    margin-bottom: 20px;
                    min-height: 86px;
                    max-width: 384px;
                    @include flexBoxJustifyCenter;
                    flex-direction: column;
                    position: relative;

                    .FilterModalInputGroup.myAcc_settingItemInputWrap {
                        padding: 0 15px;
                    }

                    .FilterModalInputGroup {
                        @include flexBoxSpaceBetween;
                        width: 100%;
                        min-height: 78px;
                        margin-top: 20px;
                        position: relative;
                        color: #828282;

                        .FilterModalInputText {
                            font-size: 15px;
                            letter-spacing: 1.35px;
                            line-height: 27px;
                            flex-grow: 1;
                        }

                        .FilterModalInputDropdown {
                            box-sizing: border-box;
                            min-height: 40px;
                            width: 100%;
                            border: 1px solid #8A8A8A;
                            border-radius: 20px;
                            @include flexBoxSpaceBetween;
                            padding: 0px 14px;
                            color: #828282;
                            cursor: pointer;

                            .FilterModalInputDropIcon {
                                font-size: 14px;
                                font-weight: 500;
                            }

                            .FilterModalInputDropdownDateImage {
                                margin-right: 16.88px;
                                height: 24px;
                                width: 24px;
                                color: transparent;
                            }

                            .FilterModalInputDropdownDateTextWrap {
                                width: 100%;
                                flex-grow: 1;
                                @include flexBoxSpaceBetween;
                            }
                        }

                        .FilterModalInputDropdown:hover {
                            color: #000000;
                        }
                    }

                    .FilterModalCurrencyDropdownlist {
                        @include flexBox;
                        flex-direction: column;
                        margin-top: 8px;
                        width: 100%;
                        overflow: hidden;
                        border-radius: 20px;
                        background-color: #FFFFFF;
                        box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.13);
                        position: absolute;
                        top: 37px;
                        z-index: 1;
                        padding-bottom: 20px;

                        .FM_currencyDrop_All {
                            @include flexBoxSpaceBetween;
                            width: 100%;
                            color: #000000;
                            font-size: 15px;
                            letter-spacing: 1.35px;
                            line-height: 27px;

                            .FM_currencyDrop_AllSelector {
                                height: 40px;
                                width: 50%;
                                cursor: pointer;
                                padding-left: 15px;
                                @include flexBoxJustifyStart;
                                color: #000000;
                                font-size: 15px;
                                letter-spacing: 1.35px;
                                line-height: 27px;
                            }

                            .FilterModalInputDropUpIconWrap {
                                @include flexBox;
                                justify-content: flex-end;
                                cursor: pointer;
                                height: 40px;
                                width: 50%;
                                padding-right: 15px;

                                .FilterModalInputDropUpIcon {
                                    height: 14px;
                                    width: 14px;
                                    font-size: 14px;
                                    color: #{$mainColor};
                                }
                            }
                        }

                        .FM_currencyDrop_All:hover {
                            background-color: #ECECEC;
                        }

                        .FilterModalCurrencyDropdownlistWrap {
                            height: 150px;
                            overflow-y: auto;
                            width: 100%;

                            .currencyListItemWrap {
                                @include flexBoxSpaceBetween;
                                width: 100%;
                                padding-left: 14px;
                                padding-right: 15px;
                                height: 51px;
                                cursor: pointer;
                                min-height: 50px;

                                .currencyListIconNameWrap {
                                    @include flexBox;

                                    .currencyList_Icon {
                                        margin-right: 10px;
                                        height: 30px;
                                        width: 30px;
                                        color: transparent;
                                        margin-right: 10px;
                                    }

                                    .currencyList_Name {
                                        color: #000000;
                                        font-size: 15px;
                                        letter-spacing: 1.35px;
                                        line-height: 27px;
                                        flex-grow: 1;
                                    }
                                }

                                .currencyListCheckBox {
                                    width: 14px;
                                    height: 14px;
                                    color: transparent;
                                    margin-right: 10px;
                                }
                            }

                            .currencyListItemWrap:hover {
                                background-color: #ECECEC;
                            }
                        }
                    }

                    .FilterModalCurrencyDropdownlistWrap::-webkit-scrollbar {
                        width: 4px;
                    }

                    .FilterModalCurrencyDropdownlistWrap::-webkit-scrollbar-track {
                        background: transparent;
                        background-color: transparent;
                    }

                    .FilterModalCurrencyDropdownlistWrap::-webkit-scrollbar-thumb {
                        width: 4px;
                        border-radius: 3px;
                        background-color: #{$mainColor};
                    }

                    .FilterModalCurrencyDropdownlistWrap::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }

                    .FilterModalInputGroup:hover {
                        color: #000000;
                    }

                    .myAcc_settingItemNameWrap {
                        width: 100%;
                        @include flexBox;
                        min-height: 35px;
                        justify-content: flex-start;

                        .myAcc_settingItemName {
                            color: #000000;
                            min-height: 27px;
                            font-size: 15px;
                            font-weight: bold;
                            letter-spacing: 1.35px;
                            line-height: 27px;
                        }

                        .myAcc_settingItemIcon {
                            height: 20px;
                            width: 20px;
                            color: #000000;
                            font-family: FontAwesome;
                            font-size: 18px;
                            line-height: 28px;
                            margin-left: 5px;
                        }

                        .settingTooltip {
                            background-color: #000000;
                            opacity: 1;
                            padding: 5px;

                            .settingTooltipContent {
                                color: #{$mainTextColor};
                                font-size: 14px;
                            }
                        }
                    }

                    .myAcc_settingItemInputWrap {
                        min-height: 40px;
                        max-width: 384px;
                        width: 100%;
                        border-radius: 20px;
                        border: 1px solid #8A8A8A;
                        background-color: #FFFFFF;
                        position: relative;
                        box-sizing: border-box;
                        margin-top: 11px;

                        .myAcc_settingDropDown {
                            min-height: 40px;
                            max-width: 384px;
                            width: 100%;
                            @include flexBoxSpaceBetween;

                            .myAcc_settingSelectOptions {
                                color: #000000;
                                font-size: 15px;
                                letter-spacing: 1.35px;
                                line-height: 27px;
                                margin-left: 18.5px;
                            }

                            .myAcc_settingSelectOptionsIonn {
                                font-size: 24px;
                                position: absolute;
                                right: 0;
                                margin-right: 12px;
                                cursor: pointer;
                                font-weight: bold;
                                pointer-events: none;
                                background: none;
                            }
                        }

                        .myAcc_settingDropDown>div>div {
                            border-radius: 20px !important;
                            box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.13);
                            margin-top: 5px !important;
                        }

                        .myAcc_settingDropDown>div>div>div>div:hover {
                            outline: none;
                            border-color: transparent;
                            border-radius: 20px !important;
                        }

                        .myAcc_settingDropDown>div>div>div>div {
                            outline: none;
                            border-color: transparent;
                            border-radius: 20px !important;
                        }

                        .myAcc_settingDropDown>div>div>div>div:last-child {
                            border-radius: 0px !important;
                        }

                        .myAcc_settingDropDown>div>button {
                            padding: 3px 5px 5px 19px;
                            z-index: 100;
                            height: 40px !important;
                            border-radius: 20px;
                            box-shadow: none;
                        }

                        .myAcc_settingDropDown>div>button>span:first-child {
                            color: #000000;
                            font-size: 14px;
                            letter-spacing: 1.00px;
                            line-height: 28px;
                            opacity: 1;
                            font-weight: 500;
                        }

                        .myAcc_settingDropDown>div>button>span:not(:first-child) {
                            display: none;
                        }

                        .myAcc_settingItemInput {
                            border: none;
                            min-height: 39px;
                            margin-left: 18.5px;
                            min-width: 340px;
                            color: #828282;
                            box-sizing: border-box;
                        }

                        .myAcc_settingItemInput:hover {
                            color: #000000
                        }

                        .myAcc_settingItemInput::placeholder {
                            color: #828282;
                            font-size: 14px;
                            letter-spacing: 1.09px;
                            line-height: 28px;
                        }

                        .myAcc_settingItemInput:focus {
                            outline: none;
                            min-height: 40px;
                            border-radius: 20px;
                            border: none;
                            color: #000000;
                            background-color: #FFFFFF;
                            box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.13);
                            border: none;
                            min-width: 384px;
                            margin-left: 18.5px;
                            position: absolute;
                            margin-top: -1px;
                            margin-left: -1px;
                            padding-left: 21.5px;
                        }
                    }
                }
            }
        }
    }
}

.filterModelSelectButtonContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    width: 100%;
    gap: 2px;
    -webkit-box-shadow: 0px -5px 35px -10px rgba(150, 150, 150, 1);
    -moz-box-shadow: 0px -5px 35px -10px rgba(150, 150, 150, 1);
    box-shadow: 0px -5px 35px -10px rgba(150, 150, 150, 1);

    .filterModelSelectButton {
        cursor: pointer;
        box-sizing: border-box;
        height: 34px;
        width: 100%;
        border: 2px solid #0091ff;
        color: #{$mainColor};
        border-radius: 25px;
        background-color: white;
        align-items: center;
        margin-left: 5px;

        h1 {
            margin-top: 5px;
            font-size: 13px;
            letter-spacing: 0.04px;
            line-height: 18px;
            text-align: center;
        }
    }

    .filterModelDeselectButton {
        cursor: pointer;
        box-sizing: border-box;
        height: 34px;
        width: 38.4px;;
        border: 1px solid #{$mainColor};
        border-radius: 17px;
        background-color: #{$mainColor};
        align-items: center;

        .DeSelectIcon {
            padding-left: 9.5px;
            padding-top: 6.5px;
            color: #ffff;
            height: 15px;
            width: 19.29px;
        }

    }

}


.changePassword {
    @include flexBoxJustifyCenter;
    width: 200px;
    margin-top: 10px;
    cursor: pointer;
    min-height: 50px;
    border-radius: 25px;
    background-color: #{$mainColor};
    color: #{$mainTextColor};
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.09px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
}

.changePassword:hover,
.changePassword:focus {
    background-color: #{$mainHoverColor};
    box-shadow: 0 0 24px 0 rgba(0, 145, 255, 0.5);
    text-shadow: 0px 2px 17px #FFFFFF;
    border-color: #{$mainHoverColor};
}

.myAccDetailsMain {
    .myAccDetailsWrap {
        padding: 16.66px;
        min-height: 141.5px;
        @include flexBoxSpaceBetween;
        align-items: center;

        .myAccMerchantIcon {
            height: 80px;
            width: 80px;
            border-radius: 40px;
            background-color: #D8D8D8;
            position: relative;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
            background-position: center;
            background-size: cover;

            .myAccMerchantIconEdit {
                height: 30px;
                width: 30px;
                cursor: pointer;
                @include flexBoxJustifyCenter;
                position: absolute;
                bottom: -2px;
                right: -9.5px;
                color: #000000;
                border-radius: 40px;
                background-color: #E4E6EA;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.29);
                cursor: pointer;
                border: 2px solid black;
            }

            .myAccMerchantIconInput {
                opacity: 0;
            }
        }

        .myAccMerchantName {
            color: var(--banner-text-color);
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.06px;
            line-height: 29px;
            flex-grow: 1;
            margin-left: 14px;
        }

        .myAccOptionsWrap {
            position: relative;

            .myAccOptionsBtn {
                @include flexBoxJustifyCenter;
                font-size: 28px;
                color: #{$mainTextColor};
                height: 40px;
                width: 40px;
                cursor: pointer;
                border-radius: 2px;
                background-color: #{$mainColor};
            }

            .myAccOptions {
                @include flexBoxJustifyCenter;
                flex-direction: column;
                position: absolute;
                top: 47px;
                right: 0;
                min-height: 100px;
                min-width: 315px;
                border-radius: 4px;
                background-color: #FFFFFF;
                box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
                padding: 17px;

                .myAcc_optionitem_wrap {
                    width: 100%;
                    min-height: 40px;
                    @include flexBoxSpaceBetween;

                    .myAcc_optionitem_Name {
                        flex-wrap: 1;
                        color: #000000;
                        font-size: 18px;
                        letter-spacing: 0.27px;
                        line-height: 22px;
                    }

                    .myAcc_optionitem_Icon {
                        height: 17.5px;
                        width: 11.5px;
                        margin-left: 5px;
                        color: #000000;
                        font-family: FontAwesome;
                        font-size: 18px;
                        letter-spacing: 0;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

.myAccTopPadder {
    height: 24.5px;
    overflow-y: hidden;

    .myAccTopShadow {
        width: 100%;
        margin-top: -1px;
        height: 1px;
        box-shadow: 0 1px 11px 2px rgba(0, 0, 0, 0.08)
    }
}


.FilterModalContent>.modal-dialog {
    max-width: 465px !important;
    border: none !important;
    background-color: transparent !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
}

.FilterModalBody.modal-body {
    min-height: 588px;
    max-width: 465px;
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 33px 40px 40px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
}

.ClearAlertModal>.modal-dialog {
    max-width: 384px !important;
    border: none !important;
    background-color: transparent !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 350px;
}

.approvalModals>.modal-dialog, #ClearAlertModalNoMargin {
    margin-top: 60px;
}

.approvalModals>.modal-dialog,
.ClearAlertModalBody.modal-body {
    max-width: 500px !important;
}

.ClearAlertModalBody.modal-body {
    min-height: 328px;
    max-width: 384px;
    width: 100%;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 30px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.ClearAlertModal {
    .ClearAlertModalBody {
        .ClearAlertModalIconWrap {
            @include flexBoxJustifyCenter;
            color: #{$mainTextColor};

            .ClearAlertModalIcon {
                height: 50px;
                width: 50px;
            }
        }

        .ClearAlertModalTitle {
            min-height: 26px;
            width: 100%;
            color: #000000;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 14px;
        }

        .ClearAlertModalDescription {
            min-height: 38px;
            width: 100%;
            color: #000000;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
            margin-bottom: 33px;
        }

        .ClearAlertModalClearBtn {
            @include flexBoxJustifyCenter;
            width: 100%;
            min-height: 50px;
            border-radius: 25px;
            background-color: #{$mainColor};
            color: #{$mainTextColor};
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.09px;
            line-height: 22px;
            text-align: center;
            cursor: pointer;
        }

        .approveTransactionButton,
        .rejectTransactionButton {
            background-color: #70A72D;
            max-width: 240px;
            margin-left: auto;
            margin-right: auto;
        }

        .rejectTransactionButton {
            background-color: #FF0000;
        }

        .ClearAlertModalClearBtn:hover,
        .ClearAlertModalClearBtn:focus {
            background-color: #{$mainHoverColor};
            box-shadow: 0 0 24px 0 rgba(0, 145, 255, 0.5);
            text-shadow: 0px 2px 17px #FFFFFF;
            border-color: #{$mainHoverColor};
        }

        .approveTransactionButtonDisable,
        .rejectTransactionButtonDisable,
        .approveTransactionButtonDisable:hover,
        .rejectTransactionButtonDisable:hover {
            background-color: #828282;
            color: #A3A3A3;
            cursor: initial;
            text-shadow: none;
            box-shadow: none;
        }

        .CloseGreenBtn {
            background-color: #5EA900;
            max-width: 240px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 56px;
        }

        .ClearAlertModalGoBackBtn {
            @include flexBoxJustifyCenter;
            width: 100%;
            min-height: 50px;
            color: #000000;
            font-size: 16px;
            letter-spacing: 0;
            border-radius: 25px;
            line-height: 22px;
            text-align: center;
            cursor: pointer;
        }
    }
}

.FilterModalContent {
    .FilterModalBody {
        .FilterModalHeadingWrap {
            @include flexBoxSpaceBetween;
            width: 100%;
            margin-bottom: 27.71px;

            .FilterModalClear {
                box-sizing: border-box;
                cursor: pointer;
                min-height: 40px;
                max-width: 73px;
                width: 100%;
                border: 1px solid #{$mainColor};
                border-radius: 20px;
                background-color: #FFFFFF;
                color: #{$mainColor};
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 1.35px;
                line-height: 18px;
                @include flexBoxJustifyCenter;
            }

            .FilterModalClear:hover,
            .FilterModalClear:focus {
                background-color: #ddecf6 !important;
            }

            .FilterModalTitle {
                min-height: 40px;
                width: 100%;
                color: #000000;
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 0.06px;
                line-height: 26px;
                text-align: center;
                @include flexBoxJustifyCenter;
            }

            .FilterModalCloseButtonWrap {
                max-width: 73px;
                width: 100%;
                @include flexBox;
                justify-content: flex-end;
                align-items: center;
                color: transparent;

                .FilterModalCloseButton {
                    height: 24px;
                    width: 24px;
                    cursor: pointer;
                    @include flexBoxJustifyCenter;
                }
            }

        }

        .FilterModalInputGroup {
            @include flexBox;
            flex-direction: column;
            width: 100%;
            min-height: 78px;
            margin-top: 20px;
            position: relative;

            .FilterModalInputLabel {
                min-height: 27px;
                width: 100%;
                color: #000000;
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 1.35px;
                line-height: 27px;
                @include flexBoxJustifyStart;
                margin-bottom: 11px;
            }

            .FilterModalInputText {
                font-size: 15px;
                letter-spacing: 1.35px;
                line-height: 27px;
                flex-grow: 1;
            }

            .FilterModalInputDropdown {
                box-sizing: border-box;
                min-height: 40px;
                width: 100%;
                border: 1px solid #8A8A8A;
                border-radius: 20px;
                @include flexBoxSpaceBetween;
                padding: 0px 14px;
                color: #828282;
                cursor: pointer;

                .FilterModalInputDropIcon {
                    font-size: 14px;
                    font-weight: 500;
                }

                .FilterModalInputDropdownDateImage {
                    margin-right: 16.88px;
                    height: 24px;
                    width: 24px;
                    color: transparent;
                }

                .FilterModalInputDropdownDateTextWrap {
                    width: 100%;
                    flex-grow: 1;
                    @include flexBoxSpaceBetween;
                }
            }

            .FilterModalInputDropdown:hover {
                color: #000000;
            }

            .FilterModalCurrencyDropdownlist {
                @include flexBox;
                flex-direction: column;
                column-gap: 2px;
                width: 100%;
                overflow: hidden;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.13);
                position: absolute;
                top: 37px;
                z-index: 3;

                .currencyListItemWrap {
                    @include flexBoxSpaceBetween;
                    width: 100%;
                    padding-left: 14px;
                    padding-right: 15px;
                    height: 51px;
                    cursor: pointer;

                    .currencyListIconNameWrap {
                        @include flexBox;

                        .currencyList_Icon {
                            margin-right: 10px;
                            height: 30px;
                            width: 30px;
                            color: transparent;
                        }

                        .currencyList_Name {
                            color: #000000;
                            font-size: 15px;
                            letter-spacing: 1.35px;
                            line-height: 27px;
                            flex-grow: 1;
                        }
                    }

                    .currencyListCheckBox {
                        width: 14px;
                        height: 14px;
                        color: transparent;
                    }
                }

                .currencyListItemWrap:hover {
                    background-color: #ECECEC;
                }

                .FM_currencyDrop_All {
                    @include flexBoxSpaceBetween;
                    width: 100%;
                    color: #000000;
                    font-size: 15px;
                    letter-spacing: 1.35px;
                    line-height: 27px;

                    .FM_currencyDrop_AllSelector {
                        height: 40px;
                        width: 50%;
                        cursor: pointer;
                        padding-left: 15px;
                        @include flexBoxJustifyStart;
                        color: #000000;
                        font-size: 15px;
                        letter-spacing: 1.35px;
                        line-height: 27px;
                    }

                    .FilterModalInputDropUpIconWrap {
                        @include flexBox;
                        justify-content: flex-end;
                        cursor: pointer;
                        height: 40px;
                        width: 50%;
                        padding-right: 15px;

                        .FilterModalInputDropUpIcon {
                            height: 14px;
                            width: 14px;
                            font-size: 14px;
                            color: #{$mainColor};
                        }
                    }

                    .FilterModalInputDropUpIconWrapEM {
                        width: initial;
                        padding: 0;
                    }
                }

                .FM_currencyDrop_AllEM {
                    padding: 0 14px;
                }

                .FM_currencyDrop_All:hover {
                    background-color: #ECECEC;
                }
            }

            .FilterModalCurrencyDropdownlistWrap {
                height: 150px;
                overflow-y: auto;
                width: 100%;
            }

            .FilterModalCurrencyDropdownlistWrap::-webkit-scrollbar {
                width: 4px;
            }

            .FilterModalCurrencyDropdownlistWrap::-webkit-scrollbar-track {
                background: transparent;
                background-color: transparent;
            }

            .FilterModalCurrencyDropdownlistWrap::-webkit-scrollbar-thumb {
                width: 4px;
                border-radius: 3px;
                background-color: #{$mainColor};
            }

            .FilterModalCurrencyDropdownlistWrap::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            .FilterModalCurrencyDropdownlistWrapEM {
                height: 200px;
            }
        }

        .FilterModalSave {
            height: 50px;
            width: 100%;
            border-radius: 25px;
            cursor: pointer;
            background-color: #{$mainColor};
            color: #{$mainTextColor};
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 1.35px;
            line-height: 27px;
            text-align: center;
            @include flexBoxJustifyCenter;
            margin-top: 30px;
        }

        .DisableFilterModalSave {
            background-color: #828282;
        }

    }
}

.myAccTransactionsMain {
    background-color: #FAFAFA;
    min-height: 100vh;

    .transactions_container {
        padding-left: 16.66px;
        padding-right: 16.66px;

        .transactionsFiltersWrap {
            @include flexBoxJustifyCenter;

            .filterBtn {
                height: 40px;
                width: 40px;
                min-width: 40px;
                margin-left: 15px;
                border-radius: 19.83px;
                cursor: pointer;
                background-color: #FFFFFF;
                @include flexBoxJustifyCenter;

                .filterBtnIcon,
                .downloadBtnIcon {
                    font-size: 19px;
                    height: 40px;
                    width: 40px;
                    color: transparent;
                }

                .downloadBtnIcon {
                    padding-top: 0px;
                    width: 40px;
                    height: 40px;
                }

                .filterBtnIcon:hover,
                .downloadBtnIcon:hover {
                    transform: scale(1.1);
                }
            }

            .transactionsSearchFilters {
                cursor: pointer;
                box-sizing: border-box;
                min-height: 40px;
                width: 100%;
                flex-grow: 1;
                border: 1px solid #8A8A8A;
                border-radius: 19.83px;
                padding: 0 17px;
                background-color: #FFFFFF;
                @include flexBoxSpaceBetween;

                .transactionsFiltersItem {
                    cursor: pointer;
                    @include flexBoxSpaceBetween;
                    margin-top: 5.5px;
                    margin-bottom: 5.5px;

                    .transactionsFilterTextWrap {
                        flex-grow: 1;
                        @include flexBox;
                        align-items: flex-start;
                        flex-direction: column;

                        .transactionsFilterInput {
                            width: 100%;
                            border: none;
                            padding: 0px;
                            background-color: #FFFFFF;
                            text-overflow: ellipsis;
                        }

                        .transactionsFilterInput::placeholder,
                        .transactionsFilterValue {
                            color: #000000;
                            font-size: 15px;
                            letter-spacing: 1.35px;
                            line-height: 27px;
                        }

                        .transactionsFilterInput:focus {
                            outline: none;
                        }
                    }

                    .transactionsFilterSingleIconWrap {
                        cursor: pointer;
                        @include flexBoxJustifyCenter;

                        .transactionsFilterSingleIcon {
                            color: transparent;
                            height: 20px;
                            width: 20px;
                        }

                        .transactionsFilterSingleIcon:hover {
                            transform: scale(1.2);
                        }
                    }

                    .transactionsFilterIconsWrap {
                        @include flexBoxJustifyCenter;
                        flex-direction: column;
                        height: 24px;
                        width: 24px;

                        .transactionsFilterIcon {
                            color: #000000;
                            font-family: FontAwesome;
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0px;
                            line-height: 14px;
                            text-align: center;
                        }
                    }
                }

                .filterDevider {
                    box-sizing: border-box;
                    height: 50px;
                    width: 1px;
                    margin-right: 20.5px;
                    margin-left: 9.5px;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                }

                .transactionsSearchFilter {
                    width: 33%;
                    cursor: initial;
                    flex-grow: 1;
                }

                .transactionDateRangeFilter {
                    width: 19%;
                }

                .transactionCurrencyFilter {
                    width: 19%;
                }

                .transactionSortByFilter {
                    width: 19%;
                }

                .transactionsResetFilters {
                    width: 10%;
                    cursor: pointer;
                    @include flexBoxJustifyCenter;

                    .transactionsFilterResetIcon {
                        box-sizing: border-box;
                        height: 20px;
                        width: 20px;
                        font-weight: 500;
                        font-size: 22px;
                        margin-right: 10px;
                        margin-top: -3px;
                    }

                    .transactionsFilterResetText {
                        color: #000000;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0.04px;
                        line-height: 22px;
                    }
                }
            }

            .transactionsDownloadBtn {
                @include flexBoxJustifyCenter;
                margin-left: 21.5px;
                box-sizing: border-box;
                height: 60px;
                width: 60px;
                border: 1px solid #CCCCCC;
                border-radius: 2px;
                background-color: #FFFFFF;
                cursor: pointer;

                .transactionsDownloadIcon {
                    height: 36px;
                    width: 36px;
                    color: #000000;
                    font-family: FontAwesome;
                    font-size: 34px;
                    letter-spacing: 0px;
                    line-height: 35px;
                    text-align: center;
                    margin-top: 8px;
                }
            }
        }
    }
}


.successModal {
    padding: 11px 15px;
    width: 100%;
    max-width: 465px;
    min-height: 52px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 135px;
    right: 50px;

    .successModalWrap {
        width: 100%;
        @include flexBoxSpaceBetween;

        .successModalIcon {
            height: 30px;
            width: 30px;
            color: transparent;
        }

        .successModalMsg {
            width: 100%;
            color: #000000;
            font-size: 15px;
            letter-spacing: 1px;
            text-align: left;
            margin-left: 10px;
            line-height: 21px;
        }

        .closeIcon {
            height: 24px;
            width: 24px;
            cursor: pointer;
            color: transparent;
        }
    }
}

.TransactionTableMain,
.APIKeysTableMain {
    padding-left: 16.66px;
    padding-right: 16.66px;
    margin-top: 29.5px;
    margin-bottom: 85px;

    .APIKeysTableWrap {
        border-bottom: 1px solid #ECECEC;
    }

    .table-responsive {
        border: 1px solid #ECECEC;
        border-radius: 4px;
        background-color: #FFFFFF;
        margin-bottom: 0;
    }

    .table {
        border: none;
    }

    .TransactionTable_heading {
        border-top: none;
        border-bottom: 1px solid #ECECEC;
        height: 50px;
        color: #666666;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.16px;
        line-height: 27px;
        text-transform: uppercase;
        cursor: pointer;

        .TransactionTable_heading_sortIcon {
            color: #000000;
            font-size: 13px;
            font-weight: 500;
            opacity: 0.6;
        }
    }

    .TransactionTable_heading:hover {
        background-color: #F0F0F0;
    }

    .APIKeysTable_heading {
        color: #AFB4BE;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.16px;
        line-height: 27px;
        text-transform: uppercase;
        border-top: none;
        border-bottom: 1px solid #ECECEC;
    }

    .transactionsTable_item {
        min-height: 50px;
        color: #000000;
        font-size: 14px;
        line-height: 27px;
        border-top: none;
        border-bottom: 1px solid #F5F5F5;

        .Tstatus_witheld,
        .Tstatus_pending,
        .Tstatus_success,
        .Tstatus_failed {
            height: 29.75px;
            min-width: 90px;
            padding-left: 10px;
            padding-right: 10px;
            white-space: nowrap;
            max-width: 100%;
            border-radius: 4px;
            background-color: #F4DF98;
            color: #A7682D;
            font-size: 12px;
            font-weight: bold;
            line-height: 27px;
            text-align: center;
        }

        .Tstatus_pending {
            color: #0091FF;
            background-color: rgba(0, 145, 255, 0.32);
        }

        .Tstatus_success {
            color: #70A72D;
            background-color: #CAF498;
        }

        .Tstatus_failed {
            color: #A72D2D;
            background-color: #F49898;
        }
    }

    .APIKeysTable_item {
        min-height: 50px;
        color: #000000;
        font-size: 14px;
        letter-spacing: 1.09px;
        line-height: 28px;
        border-top: none;
        border-bottom: 1px solid #F5F5F5;
    }

    animation-duration: 2s;
    animation-name: cardUp3;
}

@keyframes cardUp3 {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.APIKeysTableMain {
    .table {
        border: 1px solid #ECECEC;
        border-radius: 4px;
        background-color: #FFFFFF;
        margin-bottom: 0;
    }
}

.TransactionTableMain {
    margin-bottom: 30px;
    padding-bottom: 10px;

    .transactionsTable_item {
        cursor: pointer;

        .USTImages {
            height: 30px;
            width: 30px;
            margin-right: 10px;
        }
    }

    .CP_TableRow:hover {
        background-color: #F0F0F0;
    }
}

.APIKeysTableMain {
    padding: 0px;

    .APIKeysTable_BODY {
        background-color: #ffffff;
        box-sizing: border-box;

        .APIKeysTable_item {
            position: relative;
            overflow: visible;

            .revealAndHideBtn {
                color: #{$mainColor};
                cursor: pointer;
            }

            .APIKeysTable_itemTokenDropdownContent {
                position: absolute;
                left: 0;
                top: 50px;
                padding: 10px;
                color: #000000;
                background-color: #FFFFFF;
                z-index: 10;
                box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
            }

            .APIKeysTable_item_dropIcon {
                font-weight: bold;
                font-size: 22px;
                color: #{$mainColor};
                transform: translateY(3px);
                margin-left: 5px;
            }

            .APIKeysTable_itemTokenWrap {
                @include flexBox;
            }
        }
    }
}

.sKeyRevealWarningWrap {
    @include flexBox;
    flex-direction: column;
    align-items: flex-start;
    left: -15px;
    top: 65px;
    box-sizing: border-box;
    min-height: 152px;
    max-width: 821px;
    width: 100%;
    border: 1px solid #FF881B;
    border-radius: 4px;
    padding: 17px;
    background-color: #FFFFFF;
    margin-top: 26px;

    .warningHeading {
        @include flexBoxJustifyCenter;
        width: 100%;

        .warningIconWrapCircle {
            height: 40px;
            width: 40px;
            color: transparent;
        }

        .warningHeading_title {
            flex-grow: 1;
            min-height: 21px;
            width: 100%;
            color: #000000;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.54px;
            line-height: 21px;
            @include flexBoxJustifyStart;
            margin-left: 17px;
        }
    }

    .sKeyRevealWarningText {
        max-width: 778px;
        color: #000000;
        font-size: 14px;
        letter-spacing: 1.35px;
        line-height: 21px;
        margin-top: 11px;
    }
}

.myAccAPIKeysMain {
    min-height: 100vh;
    width: 100%;
    background-color: #FAFAFA;

    .myAccAPIKeysWrap {
        padding-left: 16.66px;
        padding-right: 16.66px;
        width: 100%;

        .myAccAPIKeys_heading,
        .myAccAPIKeys_heading_msg {
            color: #000000;
            font-size: 16px;
            letter-spacing: 1.35px;
            line-height: 22px;
        }

        .myAccAPIKeys_heading {
            font-size: 22px;
            letter-spacing: 0.06px;
            line-height: 26px;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 10px;
        }

        .myAccAPIKeys_heading_msg {
            margin-bottom: 20px;

            .myAccAPIKeys_heading_msg_link {
                color: #{$mainColor};
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .myAccAPIKeys_not_available {
            margin-top: 40px;
            opacity: .4;
        }
    }
}

.myAccoutDetailsMain {
    width: 100%;
    min-height: 100vh;
    background-color: #FFFFFF;
    @include flexBox;

    .myAccoutDetailsCard {
        padding: 39px 40.5px 40px 40.5px;
        max-width: 465px;
        width: 100%;
        margin: auto;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);

        .myAccoutOptModalHeading {
            @include flexBoxJustifyCenter;
            width: 100%;
            text-align: center;
            height: 26px;
            width: 164px;
            color: #000000;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0.06px;
            line-height: 26px;
            margin-bottom: 30px;
        }

        .myAccoutOptModal_form_group {
            @include flexBox;
            flex-direction: column;
            width: 100%;
            margin-bottom: 15px;

            .myAccoutOptModalLable {
                min-height: 27px;
                width: 100%;
                @include flexBox;
                color: #000000;
                font-weight: bold;
                margin-bottom: 0px;
                font-size: 15px;
                letter-spacing: 1.35px;
                line-height: 27px;
                margin-bottom: 11px;
            }

            .myAccoutOptModalLable_input_wrap {
                @include flexBoxSpaceBetween;
                box-sizing: border-box;
                height: 40px;
                width: 100%;
                font-weight: bold;
                border: 1px solid #A6A6A6;
                border-radius: 20px;
                background-color: #FFFFFF;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);

                .myAccoutOptModalLable_input {
                    border: none;
                    margin-left: 21px;
                    margin-right: 20px;
                    padding: 0;
                    width: 100%;
                }

                .myAccoutOptModalInputChange {
                    height: 40px;
                    color: #{$mainColor};
                    font-size: 15px;
                    letter-spacing: 1.35px;
                    line-height: 27px;
                    padding: 5px;
                    padding-right: 14px;
                    cursor: pointer;
                    @include flexBoxJustifyCenter;
                }

                .myAccoutOptModalLable_input::placeholder {
                    height: 40px;
                    width: 195px;
                    color: #000000;
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: 1.35px;
                    line-height: 17px;
                    opacity: .6;
                }

                .myAccoutOptModalLable_input:focus {
                    outline: none;
                }
            }
        }

        .project_Submit_btn {
            height: 50px;
            width: 100%;
            color: #{$mainTextColor};
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.24px;
            line-height: 19px;
            text-align: center;
            border-radius: 4px;
            background-color: #{$mainColor};
            margin-top: 38px;
        }
    }
}

.Show_more_transactions_wrap {
    height: 40px;
    @include flexBoxJustifyCenter;
    width: 100%;
    padding-bottom: 45px;
    padding-top: 8px;

    .Show_more_transactions {
        @include flexBoxJustifyCenter;
        min-height: 60px;
        white-space: nowrap;
        border: 1px solid #{$mainColor};
        cursor: pointer;
        box-sizing: border-box;
        width: 187px;
        border-radius: 30px;
        background-color: transparent;
        color: #{$mainColor};
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1.35px;
        line-height: 27px;
        text-align: center;
        text-transform: uppercase;
    }

    .Show_more_transactions:hover,
    .Show_more_transactions:focus {
        background-color: #ddecf6;
    }

    animation-duration: 2s;
    animation-name: cardUp4;
}

@keyframes cardUp4 {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.No_Transactions_Found {
    color: var(--content-text-color);
    margin: 15px auto;
    width: 100%;
    @include flexBoxJustifyCenter;
    opacity: .4;
    animation-duration: 2s;
    animation-name: cardUp4;
}


.TransactionDtls {
    padding-bottom: 30px;

    .TransactionDtls_container {
        @include flexBoxJustifyCenter;
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .TransactionDtls_heading-wrap {
        @include flexBoxSpaceBetween;
        width: 100%;
        padding-bottom: 35px;

        .TransactionDtls_heading {
            color: #000000;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 1.69px;
            line-height: 27px;
            padding-left: 15px;
            padding-right: 15px;
            flex-grow: 1;
            width: 100%;
            @include flexBoxJustifyStart;

            .TransactionDtls_heading_trasactionsBtn {
                color: #{$mainColor};
                cursor: pointer;
                white-space: nowrap;
            }
        }

        .ApprovalButtons_wrap {
            @include flexBox;
            width: fit-content;

            .approvalButton,
            .rejectButton {
                height: 50px;
                width: 187px;
                border-radius: 4px;
                background-color: #CAF498;
                color: #3E6909;
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 1.35px;
                line-height: 27px;
                @include flexBoxJustifyCenter;
                cursor: pointer;
            }

            .rejectButton {
                background-color: #F49898;
                color: #A72D2D;
                margin-left: 10px;
            }

            .approvalButton:hover,
            .rejectButton:hover {
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, .5);
            }
        }
    }

    .TransactionDtls_container>.TransactionDtls_cards:not(:last-child) {
        margin-right: 24px;
    }

    .TransactionDtls_cards_NoScroll {
        height: 710px;
        max-width: 486px;
        width: 100%;
        padding: 20px 30px;
        padding-right: 25px;
    }

    .TransactionDtls_cards {
        box-sizing: border-box;
        height: 710px;
        max-width: 486px;
        width: 100%;
        border: 1px solid #ECECEC;
        border-radius: 4px;
        padding-right: 5px;
        background-color: #FFFFFF;

        .TransactionDtls_cards_heading {
            min-height: 27px;
            width: 100%;
            color: #000000;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.35px;
            line-height: 27px;
        }

        .No_Logs {
            height: 27px;
            width: 205px;
            color: #AFB4BE;
            font-size: 15px;
            letter-spacing: 1.35px;
            line-height: 27px;
        }

        .TransactionDtls_cards_hideBottomBorder {
            border-bottom: none !important;
            padding-bottom: 15px;
        }

        .statusMessageValue {
            background-color: #FFFFFF;
            border-radius: 4px;
            padding: 5px;
        }

        .TransactionDtls_cards_table_border {
            border-bottom: 1px solid #F5F5F5;
        }

        .expanderBtn {
            cursor: pointer;
            color: #{$mainColor};
        }

        .TransactionDtls_cards_table_dtl {
            padding-bottom: 0;
            margin-top: 11px;
            min-height: 36.05px;
            padding-bottom: 5px;
            @include flexBoxSpaceBetween;
            width: 100%;

            .TransactionDtls_cards_header {
                min-height: 27px;
                width: fit-content;
                white-space: nowrap;
                color: #666666;
                font-size: 15px;
                letter-spacing: 1.35px;
                line-height: 27px;
                padding-right: 5px;
            }

            .TransactionDtls_cards_value {
                min-height: 27px;
                width: 100%;
                color: #000000;
                font-size: 15px;
                letter-spacing: 1.35px;
                line-height: 27px;
                text-align: right;
                word-break: break-all;
                flex-grow: 1;

                .Tstatus_witheld,
                .Tstatus_pending,
                .Tstatus_success,
                .Tstatus_failed {
                    height: 29.75px;
                    min-width: 90px;
                    padding-left: 10px;
                    padding-right: 10px;
                    white-space: nowrap;
                    max-width: fit-content;
                    border-radius: 4px;
                    background-color: #F4DF98;
                    color: #A7682D;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 1.16px;
                    line-height: 27px;
                    text-align: center;
                    margin-left: auto;
                }

                .Tstatus_pending {
                    color: #0091FF;
                    background-color: rgba(0, 145, 255, 0.32);
                }

                .Tstatus_success {
                    color: #70A72D;
                    background-color: #CAF498;
                }

                .Tstatus_failed {
                    color: #A72D2D;
                    background-color: #F49898;
                }
            }

            .TransactionDtls_activeStatus {
                min-height: 30px;
                width: 100%;
                color: #70A72D;
                font-size: 14px;
                letter-spacing: 1.35px;
                line-height: 27px;
                text-align: right;
            }
        }

        .TransactionDtls_cards_table {
            display: flex;
            justify-content: space-between;

            .TransactionDtls_cardsElog_header {
                height: 27px;
                width: 205px;
                color: #AFB4BE;
                font-size: 15px;
                letter-spacing: 1.35px;
                line-height: 27px;
                margin-top: 19px;
            }

            .TransactionDtls_cardsELog_value {
                min-height: 27px;
                width: 100%;
                color: #000000;
                font-size: 15px;
                letter-spacing: 1.35px;
                line-height: 27px;
                margin-bottom: 5px;
            }

        }

        .eventlog {
            flex-direction: column;
            border-bottom: none !important;
        }
    }

    .TransactionDtls_cards_Scroll {
        overflow-y: auto;
        float: left;
    }

    .TransactionDtls_cards_Scroll::-webkit-scrollbar {
        width: 10px;
    }

    .TransactionDtls_cards_Scroll::-webkit-scrollbar-track {
        background: transparent;
    }

    .TransactionDtls_cards_Scroll::-webkit-scrollbar-thumb {
        width: 10px;
        border-radius: 6px;
        background-color: #{$mainColor};
    }

    .TransactionDtls_cards_Scroll::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.commentBox {
    box-sizing: border-box;
    height: 127px;
    max-width: 440px;
    width: 100%;
    border: 1px solid #8A8A8A;
    border-radius: 20px;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 15px;
    letter-spacing: 1.35px;
    line-height: 27px;
    padding: 10px 15px;
    margin-bottom: 35px;
}

.commentBox::placeholder {
    color: #808080;
    font-size: 15px;
    letter-spacing: 1.35px;
    line-height: 27px;
}

.commentBox:focus {
    border: 1px solid #000000;
    outline: none;
}

.commentBoxApprove::-webkit-scrollbar {
    width: 4px;
    margin-left: -15px;
}

.commentBoxApprove::-webkit-scrollbar-track {
    background: transparent;
    background-color: transparent;
}

.commentBoxApprove::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 3px;
    background-color: #70A72D;
}

.commentBoxReject::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 3px;
    background-color: #FF0000;
}

.commentBoxApprove::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.approvalIcon {
    width: 55px;
    height: 55px;
}

body::-webkit-scrollbar-track {
    background: green;
}


.balance-row-el {
    height: 60px;

    .merchant-img-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-right: 25px;
    }
    span {
        color: #D8D8D8;
    }
}

.balance-row-el.crypto-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
}

.balance-row-el.crypto-row.imgCol {
    justify-content: flex-end;
}

.balance-row-el.crypto-row-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.merchant-balance-icons {
    width: 30px;
    height: 30px;
    fill: green;
}


td.transactionsTable_item.bl {
    padding: 0;
}

.first-table-header {
    margin-left: 18px;
}

.TransactionTable_heading.padding-balance {
    padding-left: 0;
}

.balance-exchange-modal {
    width: 465px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
    padding: 20px 40px;

    // overflow: hidden;
    .balance-header {
        height: 33px;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;

        .exchange-modal-header {
            color: #000000;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0.06px;
            line-height: 24px;
            text-align: center;
            position: relative;
        }

        .balance-close {
            position: absolute;
            right: 40px;
        }
    }

}

.exchange-parent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FilterModalInputGroup.balance {
    width: 180px !important;
}

.exchange-modal-input {
    color: #000000;
    font-size: 15px;
    letter-spacing: 1.35px;
    line-height: 27px;
    width: 100%;
    border: none;
    outline: none;
    background-color: #FFFFFF;
}

.seperator-div {
    height: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;

    .bar {
        width: 120%;
        height: 1px !important;
        background: #B3DEFF;
        position: absolute;
    }

    img {
        width: 40px;
        height: 40px;
        z-index: 2;
    }
}


@media only screen and (max-width: 480px) {
    .navTabsWrap {
        width: 500px !important;
        overflow-x: auto;
    }

    .CP_myAccounts_main .myAccountTabsWrap .myAccSettingsMain .myAccSettingsWrap .myAccSettings_heading,
    .myAccAPIKeysMain .myAccAPIKeysWrap .myAccAPIKeys_heading {
        font-size: 20px;
        letter-spacing: 0.05px;
        line-height: 24px;
        min-height: 24px;
    }

    .CP_myAccounts_main .myAccountTabsWrap .myAccSettingsMain .myAccSettingsWrap .myAcc_settingItemWrap .myAcc_settingItemInputWrap {
        max-width: 100%;
    }

    .CP_myAccounts_main .myAccountTabsWrap .myAccSettingsMain .myAccSettingsWrap .myAcc_settingItemWrap {
        max-width: 100%;
    }

    .CP_myAccounts_main .myAccountTabsWrap .myAccSettingsMain .myAccSettingsWrap,
    .myAccDetailsMain .myAccDetailsWrap,
    .myAccAPIKeysMain .myAccAPIKeysWrap,
    .CP_myAccounts_main .myAccNavTabs .myAccNavTabsWrap {
        padding-left: 0px;
        padding-right: 0px;
        overflow-x: auto;
    }

    .CP_myAccounts_main .myAccNavTabs .myAccNavTabsWrap .myAccNavTabBtn,
    .CP_myAccounts_main .myAccNavTabs .myAccNavTabsWrap .myAccNavTabBtnActive {
        // margin-left: 0px;
        // margin-right: 0px;
        width: 100%;
    }

    .CP_myAccounts_main .myAccountTabsWrap .myAccSettingsMain .myAccSettingsWrap .myAcc_settingItemWrap .myAcc_settingItemInputWrap .myAcc_settingItemInput:focus {
        min-width: calc(100% + 2px);
    }

    .CP_myAccounts_main .myAccountHeaderSection {
        background-position-y: initial;
        background-size: cover;
        background-position-x: initial;
    }

    .CP_myAccounts_main .myAccountTabsWrap .myAccSettingsMain .myAccSettingsWrap .myAcc_settingItemWrap .myAcc_settingItemInputWrap .myAcc_settingItemInput {
        min-width: calc(100% - 37px);
    }

    .APIKeysComponentMain {
        width: 100vw;
        margin-left: -16px;
        animation-duration: 2s;
        animation-name: cardUp3;

        .APIKeysComponentItemWrap {
            position: relative;
            overflow: visible;
            padding: 17px 16px;
            background-color: #FFFFFF;
            border-bottom: 1px solid #F5F5F5;

            .sKeyRevealWarningWrap {
                margin-top: 0px;
                margin-bottom: 10px;
                padding: 10px;
                min-height: initial;

                .warningIconWrapCircle {
                    height: 20px;
                    width: 20px;
                }

                .warningHeading_title {
                    height: 21px;
                    width: 100%;
                    font-size: 14px;
                    letter-spacing: 1.35px;
                    line-height: 21px;
                    margin-left: 14px;
                }

                .sKeyRevealWarningText {
                    font-size: 11px;
                    letter-spacing: 1.06px;
                    line-height: 16px;
                }
            }
        }

        .revealAndHideBtn {
            color: #{$mainColor};
            cursor: pointer;
        }

        .APIKeysTable_item {
            .APIKeysTable_item_dropIcon {
                font-weight: bold;
                font-size: 22px;
                color: #{$mainColor};
                transform: translateY(3px);
                margin-left: 5px;
            }

            .APIKeysTable_itemTokenWrap {
                @include flexBox;
                width: 100%;
            }

            .APIKeysTable_itemToken {
                position: relative;
                width: 100%;

                .APIKeysTable_itemTokenDropdownContent {
                    position: absolute;
                    left: 0px;
                    top: 30px;
                    padding: 10px;
                    color: #000000;
                    background-color: #FFFFFF;
                    z-index: 10;
                    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    .myAccTransactionsMain .transactions_container,
    .TransactionTableMain {
        padding: 0px;
    }

    .CP_MobileWrap {
        width: 100vw;
        margin-left: -16px;
        margin-right: 0px;
        padding: 10px 16px;
        padding-right: 0px;
        background-color: #FFFFFF;
        border-bottom: 1px solid #F5F5F5;
        @include flexBoxSpaceBetween;
        cursor: pointer;
        overflow: hidden;

        .CP_MobileRow {
            .transactionsMobile_item_status {

                .Tstatus_witheld,
                .Tstatus_pending,
                .Tstatus_success,
                .Tstatus_failed {
                    min-height: 20px;
                    min-width: 60px;
                    padding-left: 8.5px;
                    padding-right: 8.5px;
                    white-space: nowrap;
                    max-width: fit-content;
                    border-radius: 4px;
                    background-color: #F4DF98;
                    color: #A7682D;
                    font-size: 8px;
                    font-weight: bold;
                    letter-spacing: 0.77px;
                    line-height: 20px;
                    text-align: center;
                    margin-bottom: 4px;
                }

                .Tstatus_pending {
                    color: #0091FF;
                    background-color: rgba(0, 145, 255, 0.32);
                }

                .Tstatus_success {
                    color: #70A72D;
                    background-color: #CAF498;
                }

                .Tstatus_failed {
                    color: #A72D2D;
                    background-color: #F49898;
                }
            }

            .transactionsMobile_itemWrap {
                @include flexBoxJustifyStart;
                width: calc(100vw - 56px);
                text-overflow: ellipsis;

                .transactionsMobile_item {
                    height: 27px;
                    width: fit-content;
                    color: #000000;
                    font-size: 15px;
                    letter-spacing: 1.45px;
                    line-height: 27px;
                    white-space: nowrap;
                }

                .transactionsMobile_item_small {
                    height: 21px;
                    font-size: 13px;
                    letter-spacing: 1.25px;
                    line-height: 21px;
                }
            }
        }

        .CP_MobileViewDetails {
            min-width: 40px;
            padding-right: 16px;
            background-color: #FFFFFF;

            .CP_MobileViewDetailsIcon {
                @include flexBoxJustifyCenter;
                cursor: pointer;
                height: 24px;
                width: 24px;
            }
        }
    }

    .approvalModals>.modal-dialog,
    .ClearAlertModalBody.modal-body,
    .ClearAlertModal>.modal-dialog {
        max-width: 288px !important;
    }

    .ClearAlertModalBody.modal-body {
        padding: 20px 24px;
        min-height: auto;
    }

    .ClearAlertModal .ClearAlertModalBody .ClearAlertModalTitle {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .ClearAlertModal .ClearAlertModalBody .ClearAlertModalDescription {
        min-height: 36px;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        margin-bottom: 26px;
    }

    .ClearAlertModal>.modal-dialog {
        margin-top: 150px;
    }

    .FilterModalContent>.modal-dialog>.modal-content {
        border: none;
    }

    .FilterModalContent>.modal-dialog {
        margin-top: 70px;
        margin-bottom: 0px;
        max-width: 480px !important;
        width: 100vw;
    }

    .CP_header_main {
        z-index: 1060;
    }

    .FilterModalBody.modal-body {
        min-height: calc(100vh - 70px);
        box-shadow: none;
        padding: 12.5px 16px;
        border: none;
        border-radius: 0px;
        width: 100%;
        max-width: 480%;
    }

    .FilterModalContent .FilterModalBody .FilterModalHeadingWrap .FilterModalClear {
        color: #{$mainTextColor};
        background-color: #{$mainColor};
    }

    .FilterModalContent .FilterModalBody .FilterModalHeadingWrap .FilterModalClear:hover,
    .FilterModalContent .FilterModalBody .FilterModalHeadingWrap .FilterModalClear:focus {
        color: #{$mainColor}
    }

    .TransactionDtls {
        padding-bottom: 30px;
        width: 100vw;
        margin-left: -16px;
        overflow: hidden;
        position: absolute;
        top: 70px;
        min-height: 130vh;
        background: #ffffff;
    }

    .TransactionDtls .TransactionDtls_heading-wrap {
        height: 84px;
        margin-bottom: 9px;
        padding-bottom: 0px;
        width: 100vw;
        background: radial-gradient(circle, #0F1029 0%, #000000 100%);
    }

    .TransactionDtls .TransactionDtls_heading-wrap .TransactionDtls_heading {
        height: 39px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 1.1px;
        line-height: 27px;
        @include flexBoxJustifyStart;
        color: #{$mainTextColor};

        .TransactionDtls_heading_trasactionsBtn {
            height: 39px;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 1.1px;
            line-height: 27px;
            @include flexBoxJustifyCenter;
        }
    }

    .ControllerNav {
        @include flexBoxJustifyStart;
        width: 100%;
        margin-bottom: 8.96px;
        padding-left: 16px;
        padding-right: 16px;

        .CrontrollerBtns {
            max-width: 231px;
            height: 50px;
            width: 100%;
            border-radius: 30px;
            background-color: #E6E7EC;
            margin-right: 16px;
            padding: 5px;
            @include flexBoxSpaceBetween;

            .CrontrollerBtn {
                height: 40px;
                max-width: 111px;
                width: 100%;
                border-radius: 30px;
                background-color: #E6E7EC;
                color: #000000;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1.18px;
                line-height: 17px;
                text-align: center;
                cursor: pointer;
                @include flexBoxJustifyCenter;
            }

            .CrontrollerBtnActive {
                background-color: #FFFFFF;
            }
        }
    }

    .ApprovalButtons_wrap {
        @include flexBox;
        width: fit-content;

        .approveIconBtn,
        .rejectIconBtn {
            height: 40px;
            width: 40px;
            color: transparent;
        }

        .approveIconBtn {
            margin-left: 16px;
        }
    }

    .commentBoxApprove {
        margin-bottom: 7px;
    }

    .TransactionDtls .TransactionDtls_container {
        padding: 0;
    }

    .TransactionDtls .TransactionDtls_cards {
        border: none;
        height: auto;
        padding: 0;
    }

    .TransactionDtls .TransactionDtls_cards_NoScroll {
        height: auto;
        padding: 22px 20px 0px;
    }

    .TransactionDtls .TransactionDtls_cards .TransactionDtls_cards_table_dtl .TransactionDtls_cards_header {
        color: #4E5360;
    }

    .successModal {
        width: 93%;
        top: 10px;
        right: 50%;
        transform: translateX(50%);
    }
}

.FromAddressDrop {
    font-size: 22px;
    font-weight: bold;
    color: #{$mainColor};
    transform: translateY(3px);
    margin-left: 5px;
}

.FromAddressDropdownContent {
    padding: 10px;
    color: #000000;
    background-color: #FFFFFF;
    z-index: 10;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
}

.transaction_summary {
    height: 40px;
    max-width: 97%;
    margin: 20px auto 0 auto;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    background-color: #FFFFFF;
    @include flexBox;
    padding: 0px 10px;
    color: #000000;

    .summary_title {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.08px;
        line-height: 17px;
        padding-right: 2px;
    }

    .summary_content {
        font-size: 12px;
        letter-spacing: 1.08px;
        line-height: 27px;
    }

    span {
        font-weight: 600;
    }
}

.transaction_summary_display_block {
    display: block;
    height: auto;
    padding: 5px;
    max-width: 100%;
    margin: 20px 16.5px 0 16.5px;

    .transaction_summary_line {
        display: flex;
    }
}

.automation_nave_wrap {
    @include flexBoxJustifyStart;
    width: 100%;
    padding: 0 40px;

    .automation_nav_item {
        margin-right: 75px;
        width: fit-content;
        height: 40px;
        color: #A6A6A7;
        font-size: 14px;
        letter-spacing: 1.18px;
        line-height: 16px;
        @include flexBoxJustifyStart;
        cursor: pointer;
    }

    .automation_nav_item_active {
        border-bottom: 4px solid #{$mainColor} !important;
        color: #{$mainColor} !important;
    }

    .userSettings_nav_item {
        padding-right: 30px;
        width: fit-content;
        height: 40px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 16px;
        letter-spacing: 1.35px;
        line-height: 27px;
        @include flexBoxJustifyStart;
        cursor: pointer;
        border-bottom: 3px solid lightgray;
    }
}

.tab_container {
    margin-top: 35px;
    margin-left: 35px;

    .title {
        color: #000000;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1.35px;
        line-height: 27px;
    }
}

.automation_btn_container {
    width: 192px;
    border-radius: 25px;
    background-color: #7C7C7C;
    color: #FFFFFF;

    .automation_button {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1.35px;
        line-height: 27px;
        text-align: center;
        padding: 10px;
    }

}

.automation_btn_container_active {
    border: 2px solid #009CFF;
    background-color: #FFFFFF;
    color: #009CFF;
}


.deposit_model_mobile {
    .css-uhb5lp {
        background-color: #FFFFFF !important;

    }

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        background-color: #FFFFFF !important;
    }

    .card-bg {
        background-color: #FFFFFF !important;

    }

    .enable-authenticator-header {
        background-color: #FFFFFF !important;
        color: #000000 !important;
    }

    .enable-authenticator-content {
        background-color: #FFFFFF !important;
        color: #000000 !important;
    }

    .content_container {
        border-radius: 11px;
        background-color: #FFEBD7;

        p {
            color: #000000 !important;
        }

        div {
            color: #000000 !important;
        }
    }

    .kyc-options-container,
    .verify-onboard-sub-heading,
    .kyc-seleted-options,
    .kyc-select-options {
        background-color: #FFFFFF !important;
        color: #000000 !important;
    }

    .crypto_heading {
        font-weight: bold;
        padding-left: 1.5rem;
        padding-top: 15px;

        span {
            padding-left: 10px;
            color: #8A8A8A;
            letter-spacing: 1.35px;
            line-height: 27px;
            font-weight: normal;
            padding-top: 12px;
        }
    }

    .input_container {
        .input_box {
            margin: 10px 0 10px 20px;
            width: 402px;
            height: 51px;
            border-radius: 25px;
            padding: 5px;
            border: 1px solid #8A8A8A;
            outline: none;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    .withdraw_btn {
        border: 1px solid #3e3e3e;
        @include flexBoxJustifyCenter;
        margin-left: 20px;
        height: 51px;
        width: 402px;
        color: #FFFFFF;
        border-radius: 25px;
        background-color: #0091FF;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1.35px;
        line-height: 27px;
        margin-bottom: 30px;
        cursor: pointer;
    }

    .confirm_btn {
        border: 1px solid #3e3e3e;
        @include flexBoxJustifyCenter;
        margin: 0 auto 30px;
        height: 51px;
        width: 100px;
        color: #FFFFFF;
        border-radius: 25px;
        background-color: #0091FF;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1.35px;
        line-height: 27px;
        cursor: pointer;
    }
}

.automation_item:hover {
    cursor: default;
}

.automation_table_crypto_name {
    color: #969696;
}

.automation_table_pencil {
    padding-left: 15px;
    color: #009CFF;
}

.navItemWrap,
.navItemWrapA {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.04px;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    @include flexBoxJustifyCenter;
    flex-direction: column;
    white-space: nowrap;
    width: fit-content;
    border: none;
    cursor: pointer;
    background: transparent;
    color: #{$mainTextColor};

    a {
        color: #{$mainTextColor};
        border-bottom: none;
    }
}

.navItemWrapActive {
    a {
        color: #{$mainColor};
    }
}

.automation_save_changes {
    border-radius: 3px;
    background-color: #009CFF;
    color: white;
    padding: 5px 25px;
    width: 160px;
}

.switcharoo {
    height: 40px;
    width: 246px;
    border-radius: 20px;
    background-color: #F0F1F2;
    color: #7C7C7C;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.switcharoo:hover {
    cursor: pointer;
}

.selected-switcharoo {
    height: 34px;
    width: 120px;
    border-radius: 17px;
    background-color: #0091FF;
    color: #FFFFFF;
    margin-top: auto;
    margin-bottom: auto;
}

.normal-switcharoo {
    height: 34px;
    width: 120px;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;

}

.transaction-merchant-table {
    width: 100%;

    th {
        height: 50.04px;
    }

    tr:hover {
        background-color: #F0F0F0;
        cursor: pointer;
    }

    tr {
        height: 59.55px;
        background-color: #FFFFFF;
        border-bottom: 1px solid #F5F5F5;

        td {
            color: #000000;
            font-size: 14px;
            letter-spacing: 1.35px;
            line-height: 27px;
        }

        th {
            color: #666666;
            font-size: 12px;
            letter-spacing: 1.16px;
            line-height: 27px;
        }
    }

    .t-header {
        width: 23%;
    }

    .p-30-l {
        padding-left: 30px;
    }

    .last-row {
        display: flex;
        justify-content: space-between;

        p {
            margin: unset;
        }

        div {
            display: flex;
            justify-content: space-between;
            width: 100px;
            align-items: self-start;
            margin-top: 7%;
        }
    }

    .last-header {
        padding-right: 10em;
    }
}

.singleTRRowMerchant {
    padding: 0px 20px;
    color: var(--signup-text-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--balance-table-odd-row);

    .title {
        padding: 10px 0px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.05px;
        line-height: 24px;

        span {
            font-size: 18px;
            font-weight: normal;
        }
    }

    .TR__address {
        @include flexBoxSpaceBetween;
        padding: 10px 0px;

        .from__address {
            .address__title {
                color: #848484;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
            }

            .address__data {
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 22px;

                span {
                    padding-left: 10px;
                    height: 20px;
                    width: 20px;

                    color: #0091ff;
                    cursor: pointer;

                    i {
                        font-weight: 900;
                    }
                }

                span:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    .balanceAfterTR {
        padding: 20px 0px;

        .balanceAfterTR__heading {
            color: #848484;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
        }

        .balanceAfterTR__data {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 22px;

            span {
                opacity: 0.6;
            }

            i {
                padding-left: 10px;
                height: 20px;
                width: 20px;
                font-weight: 900;
                color: #0091ff;
                cursor: pointer;
            }
        }
    }
}

.s {
    margin: auto;
    max-width: 1700px !important;
    width: 100% !important;

    .b2ctransactionFilter {
        background-color: unset;
        display: flex;
        align-items: center;
        padding: unset;
        height: 70px;
        padding-left: 26px;
        background-color: #F9FAFB;
    }
}

.myAccTransactionsMain.mer {
    padding: 20px;
    background: white;
}

.mer-trans-pad {
    background: #FAFAFA;
    padding-top: 10px;
}

.switchraoo-container {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 26px;
    display: flex;
    justify-content: space-between;
}

.trow-first {
    border-top: 1px solid #F5F5F5;
}

.transaction_item_next.p {
    width: 15px !important;
    height: 15px;
}

.table-pad-unset {
    padding: unset;
}

.TransactionTableMain.s {
    padding-left: unset;
    padding-right: unset;
}

.balance-button {
    box-sizing: border-box;
    height: 40px;
    width: 116px;
    border: 1px solid #E1E1E1;
    border-radius: 20px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 15px;

    img {
        height: 15.6px;
        width: 15.6px;
    }

    div {
        color: #000000;
        font-size: 13px;
        letter-spacing: 0.04px;
        line-height: 14px;
    }
}


.Show_more_transactions.s {
    width: 100%;
    border-radius: unset;
    color: #0091ff;
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
}

.grey-icon {
    color: gray !important;
}




.b2ctransactionFilter.s {
    background-color: #F9FAFB;
    @include flexBox;
    padding: 20px;
    height: 70px;

    .date__filter {
        padding-left: 28px;
        cursor: pointer;
        box-sizing: border-box;
        height: 51px;
        width: 301px;
        border: 1px solid var(--transaction-filter-box-border);
        border-radius: 25px;
        background-color: white;
        @include flexBoxSpaceBetween;
        padding: 0px 10px;
        box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);

        .filter__heding {
            color: black;
            font-size: 16px;
            letter-spacing: 0.04px;
            line-height: 22px;
        }

        .showFilter__arrow {
            color: var(--signup-text-color);
            margin-top: -10px;
            font-size: 24px;
            height: 24px;
            width: 24px;

            i {
                font-weight: 900;
            }
        }
    }

    .MobileFilterBtnContainer {
        display: flex;
        justify-content: space-between;
        gap: 2px;
        width: 100%;

        .searchinput {
            border-radius: 50px !important;

            input.searchinput::placeholder {
                padding-left: 10px !important;
            }
        }

    }

    .date__select {
        position: absolute;
        margin-top: 400px;
        margin-left: -10px;
        z-index: 1;

        .css-4l7j15 {
            background-color: white;
            box-shadow: 0 5px 24px 0 rgba(130, 135, 165, 0.34);
            border-radius: 4px;
            height: 312px;
        }

        .css-1tape97:not(:last-of-type) {
            border-right: 1px solid #d8d8d8;
        }

        .css-f7iyql {
            span {
                color: black;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
            }
        }

        .css-1n2mv2k {
            span {
                color: black;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 14px;
                text-align: center;
            }
        }

        button {
            color: black;
        }

        .css-1snvurg-MuiPickerStaticWrapper-root {
            background-color: var(--profile-menu-bg-color);
            box-shadow: 0 5px 24px 0 rgba(130, 135, 165, 0.34);
            border-radius: 4px;
            height: 312px;
            color: #ffff;

            .css-1tape97:not(:last-of-type) {
                border-right: 1px solid #d8d8d8;
            }

            .css-1n2mv2k {
                span {
                    color: var(--transaction-filter-calendar-date);
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 14px;
                    text-align: center;
                }
            }

            .css-f7iyql {
                span {
                    color: black;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: center;
                }
            }

            button {
                color: black;
            }
        }
    }

    .coins__filter {
        margin-left: 20px;
        box-sizing: border-box;
        height: 51px;
        width: 301px;
        border: 1px solid var(--transaction-filter-box-border);
        border-radius: 25px;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

        .heading___icon {
            cursor: pointer;
            @include flexBoxSpaceBetween;
            padding: 12px 10px;

            .heding {
                color: black;
                font-size: 16px;
                letter-spacing: 0.04px;
                line-height: 22px;
                padding-left: 10px;
            }

            .arrow {
                margin-top: -8px;
                color: var(--signup-text-color);
                font-size: 24px;
                height: 24px;
                width: 24px;

                i {
                    font-weight: 900;
                }
            }
        }

        .showDropdown {
            // height: 334px;
            border-radius: 25px 25px 0 0;
            background-color: white;
            box-shadow: var(--transaction-filter-dataBox-border);
            z-index: 1;
            position: relative;
            margin-top: -49px;

            .showCoinDropdown__heading {
                cursor: pointer;
                @include flexBoxSpaceBetween;
                padding: 15px 10px;

                .heading__name {
                    color: black;
                    font-size: 16px;
                    letter-spacing: 0.04px;
                    line-height: 22px;
                    padding-left: 10px;
                }

                .dropdown__icon {
                    margin-top: -11px;
                    font-size: 24px;
                    height: 24px;
                    width: 24px;
                    color: var(--signup-text-color);

                    i {
                        font-weight: 900;
                    }
                }
            }

            .search__box {
                @include flexBoxSpaceBetween;
                height: 40px;
                width: 100%;
                border-radius: 4px;
                background-color: var(--transaction-filter-search-background);
                padding: 0px 20px;
                color: var(--signup-text-color);

                input {
                    color: var(--signup-text-color);
                    background-color: var(--transaction-filter-search-background);
                    font-size: 14px;
                    letter-spacing: 0.04px;
                    line-height: 19px;
                    border: none;
                    outline: none;
                }

                i {}
            }

            .allCrypto__rows {
                overflow-y: scroll;

                .coins__rowData {
                    @include flexBoxSpaceBetween;
                    height: 30px;
                    padding: 25px 20px;
                    cursor: pointer;

                    .rowData__name__image {
                        img {
                            height: 30px;
                            width: 30px;
                            border-radius: 100%;
                        }

                        span {
                            padding-left: 10px;
                            color: black;
                            font-size: 14px;
                            letter-spacing: 0.04px;
                            line-height: 19px;
                        }
                    }

                    .CryptoCheckBox {
                        height: 14px;
                        width: 14px;
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }

            .allCrypto__rows::-webkit-scrollbar {
                width: 10px;
            }

            .TransactionDtls_cards_Scroll::-webkit-scrollbar-track {
                background: transparent;
            }

            .allCrypto__rows::-webkit-scrollbar-thumb {
                width: 10px;
                border-radius: 6px;
                background-color: #{$mainColor};
            }

            .allCrypto__rows::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            .refresh__and__selectAll {
                height: 46px;
                box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);

                .refreshSelectAll {
                    @include flexBoxSpaceBetween;
                    padding: 2px 20px;

                    .refresh {
                        cursor: pointer;
                        box-sizing: border-box;
                        height: 34px;
                        width: 34px;
                        border: 1px solid #{$mainColor};
                        border-radius: 17px;
                        background-color: #{$mainColor};
                        align-items: center;
                        margin-right: 5px;
                        color: #ffff;

                        .refresh__icon {
                            padding-left: 9px;
                            padding-top: 6px;
                            height: 15px;
                            width: 19.29px;
                        }
                    }

                    .selectAll {
                        cursor: pointer;
                        box-sizing: border-box;
                        height: 34px;
                        width: 230px;
                        border: 2px solid #0091ff;
                        border-radius: 25px;
                        background-color: white;
                        align-items: center;
                        color: black;

                        h1 {
                            margin-top: 5px;
                            font-size: 13px;
                            letter-spacing: 0.04px;
                            line-height: 18px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.singleTRRowMerchant.s {
    background: #f9fafb;
    color: black;
}


.search-ctr {
    height: 40px;
    width: 300px;
    border-radius: 20px;
    background-color: #F0F1F2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    div {
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
    }

    input {
        border: 0;
        outline: 0;
        background-color: #F0F1F2;
    ::placeholder {
        color: #000000;
    }
    }
}

.configure_card {
    margin: 20px 0;
    padding: 15px;
    border-radius: 12px;
    background-color: #EFEFEF;

    .config_heading {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1.35px;
        line-height: 27px;
    }

    .config_btn {
        margin-top: 15px;
        margin-left: -35px;
    }
}

.FilterModalBody.modal-body {
    .mobile_Filter_Container {
        padding: 0px 10px;
    }

    .date__filter {
        padding-left: 28px;
        cursor: pointer;
        box-sizing: border-box;
        height: 51px;
        width: 100%;
        border: 1px solid var(--transaction-filter-box-border) !important;
        border-radius: 25px;
        background-color: white;
        @include flexBoxSpaceBetween;
        padding: 0px 10px;
        box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);

        .filter__heding {
            color: black;
            font-size: 16px;
            letter-spacing: 0.04px;
            line-height: 22px;
        }

        .mobile_status_filter {
            h1 {
                color: black;
            }
        }

        .showFilter__arrow {
            color: var(--signup-text-color);
            margin-top: -10px;
            font-size: 24px;
            height: 24px;
            width: 24px;

            i {
                font-weight: 900;
            }
        }
    }

    .date__select {
        position: absolute;
        margin-top: 400px;
        margin-left: -10px;
        z-index: 1;

        .css-4l7j15 {
            background-color: white;
            box-shadow: 0 5px 24px 0 rgba(130, 135, 165, 0.34);
            border-radius: 4px;
            // height: 312px;
        }

        .css-1tape97:not(:last-of-type) {
            border-right: 1px solid #d8d8d8;
        }

        .css-f7iyql {
            span {
                color: black;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
            }
        }

        .css-1n2mv2k {
            span {
                color: black;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 14px;
                text-align: center;
            }
        }

        button {
            color: black;
        }

        .css-1snvurg-MuiPickerStaticWrapper-root {
            background-color: var(--profile-menu-bg-color);
            box-shadow: 0 5px 24px 0 rgba(130, 135, 165, 0.34);
            border-radius: 4px;
            height: 312px;
            color: #ffff;

            .css-1tape97:not(:last-of-type) {
                border-right: 1px solid #d8d8d8;
            }

            .css-1n2mv2k {
                span {
                    color: var(--transaction-filter-calendar-date);
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 14px;
                    text-align: center;
                }
            }

            .css-f7iyql {
                span {
                    color: black;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: center;
                }
            }

            button {
                color: black;
            }
        }
    }

    .coins__filter {
        margin-left: 0px;
        box-sizing: border-box;
        height: 51px;
        width: 100%;
        border: 1px solid var(--transaction-filter-box-border);
        border-radius: 25px;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        margin-bottom: 20px;
        margin-top: 20px;

        .heading___icon {
            cursor: pointer;
            @include flexBoxSpaceBetween;
            padding: 12px 10px;
            width: "100%";

            .heding {
                color: black;
                font-size: 16px;
                letter-spacing: 0.04px;
                line-height: 22px;
                padding-left: 10px;
            }

            .arrow {
                margin-top: -8px;
                color: var(--signup-text-color);
                font-size: 24px;
                height: 24px;
                width: 24px;

                i {
                    font-weight: 900;
                }
            }
        }

        .showDropdown {
            height: 334px;
            border-radius: 25px 25px 0 0;
            background-color: white;
            box-shadow: var(--transaction-filter-dataBox-border);
            z-index: 1;
            position: relative;
            margin-top: -49px;

            .showCoinDropdown__heading {
                cursor: pointer;
                @include flexBoxSpaceBetween;
                padding: 15px 10px;

                .heading__name {
                    color: black;
                    font-size: 16px;
                    letter-spacing: 0.04px;
                    line-height: 22px;
                    padding-left: 10px;
                }

                .dropdown__icon {
                    margin-top: -11px;
                    font-size: 24px;
                    height: 24px;
                    width: 24px;
                    color: var(--signup-text-color);

                    i {
                        font-weight: 900;
                    }
                }
            }

            .search__box {
                @include flexBoxSpaceBetween;
                height: 40px;
                width: 100%;
                border-radius: 4px;
                background-color: var(--transaction-filter-search-background);
                padding: 0px 20px;
                color: var(--signup-text-color);

                input {
                    color: var(--signup-text-color);
                    background-color: var(--transaction-filter-search-background);
                    font-size: 14px;
                    letter-spacing: 0.04px;
                    line-height: 19px;
                    border: none;
                    outline: none;
                }

                i {}
            }

            .allCrypto__rows {
                overflow-y: scroll;

                .coins__rowData {
                    @include flexBoxSpaceBetween;
                    height: 30px;
                    padding: 25px 20px;
                    cursor: pointer;

                    .rowData__name__image {
                        img {
                            height: 30px;
                            width: 30px;
                            border-radius: 100%;
                        }

                        span {
                            padding-left: 10px;
                            color: black;
                            font-size: 14px;
                            letter-spacing: 0.04px;
                            line-height: 19px;
                        }
                    }

                    .CryptoCheckBox {
                        height: 14px;
                        width: 14px;
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }

            .allCrypto__rows::-webkit-scrollbar {
                width: 10px;
            }

            .TransactionDtls_cards_Scroll::-webkit-scrollbar-track {
                background: transparent;
            }

            .allCrypto__rows::-webkit-scrollbar-thumb {
                width: 10px;
                border-radius: 6px;
                background-color: #{$mainColor};
            }

            .allCrypto__rows::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            .refresh__and__selectAll {
                height: 46px;
                box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);

                .refreshSelectAll {
                    @include flexBoxSpaceBetween;
                    padding: 2px 20px;

                    .refresh {
                        cursor: pointer;
                        box-sizing: border-box;
                        height: 34px;
                        width: 34px;
                        border: 1px solid #{$mainColor};
                        border-radius: 17px;
                        background-color: #{$mainColor};
                        align-items: center;
                        margin-right: 5px;
                        color: #ffff;

                        .refresh__icon {
                            padding-left: 9px;
                            padding-top: 6px;
                            height: 15px;
                            width: 19.29px;
                        }
                    }

                    .selectAll {
                        cursor: pointer;
                        box-sizing: border-box;
                        height: 34px;
                        width: 230px;
                        border: 2px solid #0091ff;
                        border-radius: 25px;
                        background-color: white;
                        align-items: center;
                        color: black;

                        h1 {
                            margin-top: 5px;
                            font-size: 13px;
                            letter-spacing: 0.04px;
                            line-height: 18px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .mobile_status_filter {
        margin-top: 15px;
        color: var(--signup-text-color);

        h1 {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 19px;
            // margin-bottom: 20px;
        }

        .name_wrap {
            @include flexBoxSpaceBetween;
            flex-wrap: wrap;
            margin-top: 10px;

            .status_name {
                margin-top: 10px;
                text-transform: capitalize;
                border-radius: 8px;
                background-color: var(--signup-model-bg);
                box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
                padding: 10px 15px;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 17px;
                margin-right: 5px;
            }
        }
    }

    .filter_button {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
        color: #ffff;
        height: 42px;
        border-radius: 25px;
        background-color: #0091FF;
        @include flexBoxJustifyCenter;
    }

    .activeFilterBtn {
        background-color: #0091FF !important;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2) !important;
    }

    .closeBtn {
        background-color: transparent;
        border-radius: 100px;
        border: none;
        padding: 5px;
        color: black;
    }
}