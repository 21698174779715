.jn-legal {
	font-family: "Inter";
	h1 {
		color: #FFF;
		font-size: 56px;
		font-weight: 700;
		margin-bottom: 64px;
		line-height: 120%;
	}
	h2 {
		color: #FFF;
		font-size: 36px;
		font-weight: 700;
		margin-bottom: 40px;
		line-height: 120%;
		&.mt {
			margin-top: 40px;
		}
	}
	ol, ul {
		padding-left: calc(48px + 36px);
		margin-bottom: 0;
		li {
			&:not(:last-child) {
				margin-bottom: 24px;
			}
		}
		&.bordered-circle {
			list-style: none;
			padding-left: 22px;
			li {
				position: relative;
				&:before {
					position: absolute;
					left: -1.2em;
					top: 0.4em;
					content: ' ';
					display: block;
					width: 0.45em;
					height: 0.45em;
					border: 1px solid #fff;
					border-radius: 50%;
				}
			}
		}
	}
	.custom-li {
		display: grid;
		grid-template-columns: 48px 1fr;
		padding-left: 48px;
		&-content {
			p:not(:last-child) {
				margin-bottom: 24px;
			}
			ol {
				padding-left: 36px;
			}
		}
	}
	p, li {
		color: #D0D5DD;
		font-size: 18px;
		line-height: 130%;
		span {
			color: #fff;
			font-weight: 500;
		}
		i, a {
			color: #FEDF89;
			font-style: normal;
		}
		&.mb {
			margin-bottom: 24px;
		}
	}
	.br {
		height: 32px;
	}
	&-section1 {
		padding-bottom: 72px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.16);
		background-image: url("https://dev.junomoney.org/images/junoclient/legal-gradient.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		margin-top: -80px;
		padding-top: calc(145px + 80px);
	}
	&-section2 {
		padding: 72px 0;
		background: #091324;
	}
	&-block {
		.custom-li {
			&:not(:last-child) {
				margin-bottom: 32px;
			}
		}
	}
	.custom-list {
		counter-reset: none;
		list-style-type: none;
		& > li {
			position: relative;
			&:before {
				position: absolute;
				left: -36px;
				width: 36px;
				font-weight: 700;
			}
		}
		&.roman {
			& > li {
				counter-increment: roman-item;
				&:before {
					content: counters(roman-item, ".", upper-roman)". " !important;
				}
			}
		}
		&.latin {
			& > li {
				counter-increment: latin-item;
				&:before {
					content: counters(latin-item, ".", lower-latin)". ";
				}
			}
		}
	}
	&-contents {
		border-radius: 16px;
		border: 1px solid rgba(255, 255, 255, 0.08);
		background: rgba(255, 255, 255, 0.04);
		padding: 48px;
		&-title {
			font-size: 32px;
			font-weight: 700;
			margin-bottom: 40px;
		}
		&-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 100px;
		}
		&-block {
			p {
				display: grid;
				grid-template-columns: 48px 1fr;
				cursor: pointer;
				color: #fff;
				font-weight: 600;
				&:not(:last-child) {
					margin-bottom: 24px;
				}
				span {
					color: #FEDF89;
					font-weight: 400;
				}
				&:hover {
					span {
						text-decoration: underline;
					}
				}
			}
		}
	}
	&-table {
		margin: 48px 0;
		border-radius: 16px;
		border: 1px solid rgba(255, 255, 255, 0.24);
		background: rgba(255, 255, 255, 0.04);
		&.grid-5 {
			.jn-legal-table-tr {
				grid-template-columns: repeat(5, 1fr);
				.jn-legal-table-block:first-child {
					word-break: break-word;
				}
			}
		}
		&-tr {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			&:not(:last-child) {
				& > div {
					border-bottom: 1px solid rgba(255, 255, 255, 0.16);
				}
			}
			& > div {
				&:not(:last-child) {
					border-right: 1px solid rgba(255, 255, 255, 0.16);
				}
			}
		}
		&-th {
			color: #FFF;
			font-size: 18px;
			font-weight: 600;
			padding: 24px;
			align-self: center;
			height: 100%;
		}
		&-block {
			padding: 24px;
			ol, ul {
				padding-left: 36px;
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.jn-legal-contents-grid {
		grid-gap: 60px;
	}
}

@media screen and (max-width: 1058px) {
	.jn-legal-table-block {
		padding: 20px;
	}
}

@media screen and (max-width: 991px) {
	.jn-legal-table-th,
	.jn-legal-table-tr p,
	.jn-legal-table-tr li {
		font-size: 14px !important;
	}
	.jn-legal-table {
		ol li:not(:last-child) {
			margin-bottom: 13px !important;
		}
	}
	.jn-legal-contents-block p:not(:last-child) {
		margin-bottom: 16px;
	}
	.jn-legal-contents-title {
		font-size: 24px;
		margin-bottom: 32px;
	}
	.jn-legal-contents {
		padding: 24px;
	}
	.jn-legal {
		h1 {
			font-size: 40px;
			margin-bottom: 32px;
		}
		p, li {
			font-size: 16px;
		}
	}
	.jn-legal ol, .jn-legal ul {
		padding-left: 36px;
	}
	.jn-legal .custom-li {
		padding-left: 0;
	}
	.jn-legal h2 {
		font-size: 24px;
	}
	.jn-legal-section1 {
		padding-bottom: 48px;
	}
	.jn-legal-section1 {
		padding-top: 160px;
	}
	.jn-legal-section2 {
		padding: 48px 0;
	}
	.jn-legal-contents-block p {
		grid-template-columns: 33px 1fr;
	}
}

@media screen and (max-width: 740px) {
	.jn-legal-table {
		width: 680px;
		&-overflow {
			overflow-x: auto;
		}
	}
}

@media screen and (max-width: 710px) {
	.jn-legal-contents-grid {
		grid-template-columns: 1fr;
		grid-gap: 16px;
	}
}

