.jnAccConf {
	margin-top: 25px;
	&__block {
		border-radius: 12px;
		border: 1px solid #EAECF0;
		background: #F2F4F7;
		padding: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		a {
			&:hover {
				text-decoration: none !important;
			}
		}
	}
	&__icon {
		max-width: 206px;
		width: 100%;
		height: auto;
		margin-bottom: 16px;
	}
	&__title {
		text-align: center;
		color: #344054;
		text-align: center;
		font-size: 20px;
		font-weight: 700;
	}
	&__btn {
		margin-top: 24px;
	}
}

.jnAccConfMobile {
	
}