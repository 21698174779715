.jn-contact {
	position: relative;
	z-index: 3;
	padding-bottom: 140px;
	&-form {
		border-radius: 16px;
		background: #091324;
		padding: 140px 96px;
		position: relative;
		&-bg {
			background: linear-gradient(141deg, rgba(56,218,139,1) 0%, rgba(128,87,255,1) 100%);
			border-radius: 16px;
			box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.40);
			padding: 1px;
		}	
	}
	&-row {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 32px;
		&.mb {
			margin-bottom: 50px;
		}
	}
	.theme-btn-block {
		margin-top: 64px;
		position: relative;
	}
	&-logo {
		-webkit-perspective: 1000;
		-webkit-backface-visibility: hidden;
		z-index: 2;
		position: absolute;
		top: -40px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 80px;
		height: 80px;
		background: var(--btn-background);
		border-radius: 50%;
		animation: shadow-pulse 1s infinite;
		display: flex;
		align-items: center;
		justify-content: center;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-shadow: 0 0 0 10px rgba(41, 232, 198, 0.3);
			border-radius: 50%;
			box-shadow: 0 0 40px -5px rgba(41, 232, 198, 0.5);
		}
		img {
			width: 30px;
			height: 30px;
			object-fit: contain;
		}
		span {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border-radius: 50%;
			border: 1px solid #fff;
			opacity: .3;
			transform: scale(1);
			animation: shadow-circle 1s infinite;
		}
	}
	&-errors {
		position: absolute;
		top: calc(100% + 20px);
		left: 0;
		font-size: 16px;
		color: #fff;
		opacity: 0;
		transition: .2s;
		width: 100%;
		&.active {
			opacity: 1;
		}
	}
	&-label {
		color: #fff;
		font-family: var(--textFont);
		width: 100%;
		font-size: 18px;
		position: absolute;
		top: 10px;
		transition: all .2s;
		left: 0;
		transform-origin: 0 100%;
		transform: matrix(1, 0, 0, 1, 0, 0);
		@media screen and (max-width: 991px) {
			font-size: 16px;
		}
	}
	&-field {
		position: relative;
	}
}


@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  }
}

@keyframes shadow-circle {
  0% {
		transform: scale(1);
		opacity: .3;
  }
  100% {
		transform: scale(1.8);
		opacity: 0;
  }
}

.jn-input {
	height: 44px;
	padding: 14px 0;
	background: transparent;
	box-shadow: none;
	outline: none;
	border: 0;
	border-bottom: 1px solid rgba(255, 255, 255, .24);
	color: #fff;
	font-family: var(--textFont);
	width: 100%;
	font-size: 18px;
	position: relative;
	z-index: 1;
	&::placeholder {
		color: #EAECF0;
	}
	@media screen and (max-width: 991px) {
		font-size: 16px;
	}
	&:focus, &.has-value {
		outline: none;
		& ~ .jn-contact-label {
			transform: translate(0%, -30px) matrix(0.6, 0, 0, 0.6, 0, 0);
			transition: all .2s;
		}
	}
}

.jn-textarea {
	resize: none;
	height: 134px;
	box-shadow: none;
	outline: 0;
	border: 0;
	border-bottom: 1px solid rgba(255, 255, 255, .24);
}

@media screen and (max-width: 991px) {
	.jn-contact-form {
		padding: 100px 40px 48px;
	}
	.jn-contact .theme-btn-block {
		justify-content: center;
	}
	.jn-contact {
		padding-bottom: 65px;
	}
	.jn-contact-errors {
		font-size: 14px;
		text-align: center;
		top: calc(100% + 9px);
	}
}

@media screen and (max-width: 550px) {
	.jn-contact-form {
		padding: 96px 24px 24px;
	}
	.jn-contact-row {
		grid-template-columns: 1fr;
	}
	.jn-contact-row.mb {
		margin-bottom: 32px;
	}
	.jn-contact {
		.junoClient-btn {
			width: 100%;
		}
	}
	.jn-contact-errors {
		position: static;
		margin-top: 10px;
	}
	.jn-contact .theme-btn-block {
		display: block;
	}
}
