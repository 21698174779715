.clientForgot {
	min-height: 85vh;
	background: #fcfcfc;
	padding: 60px 0;
	display: flex;
	align-items: center;
	margin-top: -80px;
	// background-image: url('https://dev.junomoney.org/images/junoclient/reset-password-bg.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top right;
	.theme-modal-title {
		text-align: left;
	}
	.MuiPaper-root  {
		margin: 0 auto;
	}
}
