.whyChoose {
	background-color: rgba(3, 5, 21, 1);
	background-image: url('https://dev.junomoney.org/images/upd/stars.webp');
	background-size: 170px 170px;
	background-repeat: repeat;
	background-attachment: fixed;
	&__grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 16px;
	}
	&__card {
		background: rgba(255, 255, 255, 0.05);
		border: 1px solid rgba(255, 255, 255, 0.2);
		border-radius: 16px;
		padding: 32px 35px;
		position: relative;
		&_bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('https://dev.junomoney.org/images/upd/icons/grid.svg');
			background-size: 40px 40px;
			background-repeat: repeat;
			opacity: .1;
		}
		&_img {
			height: 146px;
			width: 100%;
			object-fit: contain;
			margin-bottom: 36px;
			position: relative;
		}
		&_title {
			font-size: 24px;
			line-height: 130%;
			text-align: center;
			margin-bottom: 12px;
			font-family: "Inter", sans-serif;
		}
		.section-text {
			opacity: .7;
			text-align: center;
		}
	}
}

@media screen and (max-width: 1300px) {
	.whyChoose__card_title {
		font-size: 21px;
	}
	.whyChoose__card {
		padding: 25px;
	}
}

@media screen and (max-width: 991px) {
	.whyChoose__grid {
		grid-template-columns: 1fr;
	}
	.whyChoose__card {
		grid-template-columns: 1fr 1.4fr;
		grid-gap: 45px;
		align-items: center;
		display: grid;
	}
	.whyChoose__card_img {
		height: auto;
		margin-bottom: 0;
	}
	.whyChoose__card .section-text,
	.whyChoose__card_title {
		text-align: left;
	}
	.whyChoose__card:nth-child(2) {
		grid-template-columns: 1.5fr 1fr;
		.whyChoose__card_img {
			order: 2;
		}
	}
}

@media screen and (max-width: 740px) {
	.whyChoose__card_title {
		font-size: 19px;
		margin-bottom: 8px;
	}
}

@media screen and (max-width: 590px) {
	.whyChoose__card:nth-child(2) {
		grid-template-columns: 1.3fr 1fr;
	}
	.whyChoose__card {
		grid-gap: 30px;
	}
	.whyChoose__card {
		grid-template-columns: 1fr 1.3fr;
	}
}

@media screen and (max-width: 465px) {
	.whyChoose__card {
		display: block;
		padding: 25px 20px;
	}
	.whyChoose__card_img {
		height: 140px;
		margin-bottom: 20px;
	}
	.whyChoose__card .section-text,
	.whyChoose__card_title {
		text-align: center;
	}
}