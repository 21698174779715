.jnAm-contact {
	background: #141D29;
	position: relative;
	&-grid {
		display: grid;
		grid-template-columns: 1.3fr 1fr;
		grid-gap: 100px;
		align-items: center;
		height: 100%;
		padding: 85px 0;
		min-height: 100vh;
		@media screen and (min-height: 1370px) {
			min-height: initial;
			height: 1200px;
		}
	}
	&-form {
	}
	&-title {
		display: flex;
		align-items: center;
		margin-bottom: 70px;
		img {
			width: 61px;
			height: auto;
			margin-right: 12px;
		}
		p {
			color: #FFF;
			font-size: 40px;
			font-weight: 600;
			line-height: 100%;
		}
	}
	&-label {
		width: 100%;
		position: absolute;
		top: 10px;
		transition: all .2s;
		left: 0;
		transform-origin: 0 100%;
		transform: matrix(1, 0, 0, 1, 0, 0);
		color: rgba(255, 255, 255, 0.80);
		font-family: "Times New Roman";
		font-size: 22px;
	}
	&-field {
		position: relative;
		padding-bottom: 20px;
		&:not(:last-child) {
			margin-bottom: 60px;
		}
		&:before {
			content: '';
			position: absolute;
			left: calc((100vw - 1480px) / -2);
			width: calc(100% + (100vw - 1480px) / 2);
			height: 1px;
			background: rgba(255, 255, 255, 0.40);
			bottom: 0;
		}
	}
	&-btn {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background: #898E94;
		box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.40);
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		&-block {
			width: 100px;
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
		}
		&.disabled {
			background: #898E94;
			box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.40);
		}
		&.is-valid {
			background: #1B71FF;
			box-shadow: 0 0 0 5px rgba(27, 113, 255, 0.40);
		}
		&.is-success {
			background: #1BFF69;
			box-shadow: 0 0 0 5px rgba(27, 255, 105, 0.40);
		}
	}
	&-block {
		height: 100%;
		display: flex;
		align-items: center;
		.line {
			position: absolute;
			left: 0;
			right: 0;
			top: -85px;
			margin: 0 auto;
			height: calc(100% + 85px * 2);
			width: 1px;
			background: rgba(255, 255, 255, 0.40);
		}
	}
	.jnAm-bottomDeco {
		position: absolute;
		bottom: 0;
		left: 0;
	}
	&-error {
		font-size: 15px;
		color: #FF5E5E;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: 0.3px;
		opacity: 0;
		position: absolute;
		bottom: -30px;
		left: 0;
		transition: .2s;
		&.active {
			opacity: 1;
		}
	}
	&-success {
		color: #1BFF69;
		font-family: "Times New Roman";
		font-size: 20px;
		font-weight: 400;
		line-height: 100%;
		margin-left: 18px;
		opacity: 0;
		transition: .2s;
		&.active {
			opacity: 1;
		}
	}
}


.jnAm-input {
	height: 44px;
	padding: 14px 0;
	background: transparent;
	box-shadow: none;
	outline: none;
	border: 0;
	color: #fff;
	font-family: var(--textFont);
	width: 100%;
	font-size: 18px;
	position: relative;
	z-index: 1;
	&::placeholder {
		color: #EAECF0;
	}
	&:focus, &.has-value {
		outline: none;
		& ~ .jnAm-contact-label {
			transform: translate(0%, -30px) matrix(0.6, 0, 0, 0.6, 0, 0);
			transition: all .2s;
		}
	}
}

.jnAm-textarea {
	height: 100px;
	resize: none;
	padding: 0;
	position: relative;
	top: 14px;
}

@media screen and (max-width: 1550px) {
	.jnAm-contact-field:before {
		left: calc((100vw - 1200px) / -2);
		width: calc(100% + (100vw - 1200px) / 2);
	}
}

@media screen and (max-width: 1300px) {
	.jnAm-contact-field:before {
		left: calc((100vw - 1000px) / -2);
		width: calc(100% + (100vw - 1000px) / 2);
	}
	.jnAm-contact-title p {
		font-size: 30px;
	}
}

@media screen and (max-width: 1090px) {
	.jnAm-contact-field:before {
		left: calc((100vw - 950px) / -2);
		width: calc(100% + (100vw - 950px) / 2);
	}
}

@media screen and (max-width: 991px) {
	.jnAm-contact-grid {
		grid-template-columns: 1fr 340px;
		grid-gap: 60px;
	}
	.jnAm-contact-field:before {
		left: calc((100vw - 92vw) / -2);
		width: calc(100% + (100vw - 92vw) / 2);
	}
	.jnAm-contact-title p {
		font-size: 25px;
	}
	.jnAm-contact-title img {
		width: 40px;
	}
	.jnAm-contact-label {
		font-size: 20px;
	}
	.jnAm-contact-btn {
		width: 80px;
		height: 80px;
	}
}

@media screen and (max-width: 848px) {
	.jnAm-contact-grid {
		grid-template-columns: 1fr;
		padding: 50px 0 80px;
	}
	.jnAm-contact-block {
		justify-content: center;
		flex-direction: column;
	}
	.jnAm-contact-block .line {
		display: none;
	}
	.jnAm-contact-success {
		margin-left: 0;
		margin-top: 18px;
	}
	.jnAm-contact-success {
		text-align: center;
	}
	.jnAm-contact-btn-block {
		justify-content: center;
	}
	.jnAm-contact-field:before {
		left: -4%;
		width: 100vw;
	}
}

@media screen and (max-width: 430px) {
	.jnAm-contact-title img {
		display: none;
	}
	.jnAm-contact-title {
		margin-bottom: 50px;
		p {
			font-size: 23px;
		}
	}
}