@import './_mixins.scss';

.header {
	background: #090b10;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	&__row {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 20px;
		align-items: center;
		height: 75px;
	}
	&__layout {
		height: 75px;
	}
	&__logo {
		width: 213px;
	}
	&__actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		&_start {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 16px;
		}
	}
	&__wallet {
		width: 40px;
		height: 40px;
		margin: 0 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba(255, 255, 255, .2);
		border-radius: 50%;
		cursor: pointer;
		img {
			width: 50%;
			height: 50%;
			object-fit: contain;
		}
	}
	&__profile {
		padding: 0 4px 0 20px;
		height: 40px;
		border: 1px solid rgba(255, 255, 255, .2);
		display: flex;
		align-items: center;
		border-radius: 99px;
		position: relative;
		&.active {
			background: rgba(255, 255, 255, 0.1);
		}
		&_name {
			color: #fff;
			font-weight: 600;
			margin-right: 10px;
		}
		&_block {
			position: relative;
			.divider {
				width: 100%;
				height: 1px;
				background: rgba(255, 255, 255, .1);
			}
		}
		&_icon {
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			color: #fff;
			background: linear-gradient(180deg, #01C4FF 0%, #0192FF 100%);
			border-radius: 50%;
		}
		&_menu {
			position: absolute;
			top: calc(100% + 10px);
			right: 0;
			min-width: 280px;
			width: auto;
			visibility: hidden;
			opacity: 0;
			transition: all .1s;
			background: rgba(9, 11, 16, 0.6);
			backdrop-filter: blur(20px);
			/* Note: backdrop-filter has minimal browser support */
			border-radius: 8px;
			border: 1px solid rgba(255, 255, 255, .2);
			&.active {
				visibility: visible;
				opacity: 1;
			}
			.theme-btn-block {
				padding: 0 16px;
				margin: 16px 0;
			}
		}
		&_email {
			font-weight: 600;
			text-align: center;
			padding: 16px;
		}
		&_item {
			padding: 10px 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: #D0D5DD;
			transition: .15s;
			cursor: pointer;
			svg {
				width: 5px;
				height: auto;
			}
			&:hover {
				color: #fff;
				background: rgba(255, 255, 255, 0.1);
			}
		}
	}
	&__right {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	&__mobileBtn {
		display: none;
		div {
			width: 20px;
			height: 2px;
			background: #fff;
			&:first-child {
				margin-bottom: 6px;
			}
		}
	}
}

.theme-switch {
  display: inline-block;
  height: 26px;
  position: relative;
  width: 55px;
	margin-bottom: 0;
	&-wrapper {
		display: flex;
  	align-items: center;
		padding: 0 16px 16px;
	}
	&-label {
		font-size: 16px;
		margin-right: 15px;
		color: #E0E0E0;
	}
	.slider {
		background-color: #E0E0E0;
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: .4s;
		&:before {
			background-color: #fff;
			bottom: 3px;
			content: "";
			height: 20px;
			left: 4px;
			position: absolute;
			transition: .4s;
			width: 20px;
		}
	}
	&.checked {
		.slider {
			background-color: #0091FF;
			&:before {
				transform: translateX(26px);
			}
		}
	}
	.slider.round {
		border-radius: 34px;
	}
	.slider.round:before {
		border-radius: 50%;
	}
}




@media screen and (max-width: 1058px) {
	.header__row, .header__layout {
		height: 60px;
	}
}

@media screen and (max-width: 991px) {
	.header__mobileBtn {
		display: block;
		margin-left: 16px;
	}
	.header__actions {
		display: none;
	}
	.header__profile_email {
		padding: 20px 0 30px;
		max-width: 100%;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.header__profile_item {
		padding: 20px 0;
	}
	.header__profile_item svg {
		width: 8px;
	}
	.header__profile_item:hover {
		background: transparent;
	}
	.header__profile_icon {
		width: 64px;
		height: 64px;
		font-size: 25px;
		margin: 0 auto;
	}
	.theme-switch-wrapper {
		padding: 0 0 30px;
	}
}

@media screen and (max-width: 530px) {
	.header__logo {
		width: 170px;
	}
}

@media screen and (max-width: 406px) {
	.header__right {
		.theme-btn {
			padding: 10px 18px;
			p {
				font-size: 14px;
			}
		}
	}
	.header__logo {
		width: 140px;
	}
}

@media screen and (max-width: 360px) {
	.header__right .theme-btn {
		padding: 10px 15px;
	}
	.header__logo {
		width: 120px;
	}
}


//----

.img_logo{
  // max-height: 50px !important;
  // height: 26px !important;
  width: 108.19px !important
}

.CP_header_main_bg {
  @include flexBoxSpaceBetween;
  height: 90px;
  width: 100%;
  position: absolute;
  z-index: 10;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
}
.CP_header_main {
  background-image: linear-gradient(to right, rgba(0, 145, 255, 0.03), rgba(255, 0, 0, 0));
  height: 90px;
  background-color: var(--theme-header-background);
  width: 100%;
  position: fixed;
  z-index: 20;
  backdrop-filter: blur(7px);
}
.CP_header_main_animation {
  animation-name: headerAnimation;
  animation-duration: 1s;
}
@keyframes headerAnimation {
  0% {
    transform: translateY(-90px);
    opacity: 0;
    height: 0px;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    height: 90px;
  }
}
.CP_nav_main {
  @include flexBoxSpaceBetween;
}
.CP_nav_main_animation {
  animation-name: headerContent;
  animation-duration: 2s;
}
@keyframes headerContent {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CP_logo {
  @include flexBoxJustifyCenter;
  height: auto;
  width: 220px;
  margin-top: -13px;
  margin-left: -10px;
}
.CP_logo:hover {
  transform: scale(1.02);
}
.CP_nav_main_wrap {
  @include flexBox;
  .CP_nav_wrap {
    @include flexBox;
    .navItemAndSeparatorWrap {
      display: flex;
      .navSaparator {
        height: 42px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5px;
        margin-right: 25px;
        width: 1px;
        border: 1px solid #4c4c4c;
      }
      .navItemWrap {
        padding: 0px 25px;
        text-decoration: none;
        @include flexBoxJustifyCenter;
        flex-direction: column;
        min-width: 90px;
        font-weight: bold;
        button {
          white-space: nowrap;
          width: fit-content;
          border: none;
          cursor: pointer;
          background: transparent;
          color: var(--content-text-color-1);
          font-size: 16px;
          letter-spacing: 0.04px;
          line-height: 40px;
          font-weight: bold;
          padding: 0;
        }
        button:focus,
        button:focus-visible {
          outline: transparent;
        }
      }
      .navItemWrap:hover {
        button {
          color: #{$mainColor};
        }
      }
      .navItemWrapActive {
        button {
          color: #{$mainColor};
          border-bottom: 1px solid #{$mainColor};
        }
      }
    }
  }
  .CP_LoginSignup_wrap {
    @include flexBox;
    margin-left: 10px;
    .CP_signoutButton,
    .CP_loginButton {
      @include flexBoxJustifyCenter;
      height: 42px;
      width: 106px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.04px;
      line-height: 42px;
      text-align: center;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      border: 2px solid #{$mainColor};
      border-radius: 25px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .CP_signoutButton {
      color: #{$mainColor};
      background-color: #ffffff;
    }
    .CP_loginButton {
      color: #{$mainTextColor};
      background-color: #{$mainColor};
      margin-left: 19.5px;
    }
    .CP_loginButton:hover,
    .CP_loginButton:focus {
      background-color: #{$mainHoverColor};
      box-shadow: 0 0 24px 0 rgba(0, 145, 255, 0.5);
      text-shadow: 0px 2px 17px #ffffff;
      border-color: #{$mainHoverColor};
    }
  }
}
.CP_nav_profileOption_wrap {
  height: 70px;
  width: fit-content;
  @include flexBoxJustifyCenter;
  margin-left: 30px;
  position: relative;
  .profileNav {
    width: 30px;
    height: 30px;
    border: 1px solid #{$mainColor};
    border-radius: 4px;
    cursor: pointer;
    @include flexBoxJustifyCenter;
    i {
      font-size: 20px;
      color: var(--content-text-color);
    }
  }
}
.CP_profile_modal_main {
  position: absolute;
  right: 0px;
  top: 27px;
}
.CP_profile_modal > .modal-dialog,
.CP_profile_modal > .modal-dialog > .modal-content {
  max-width: 223px !important;
  border: none !important;
  background-color: transparent !important;
  margin-left: auto;
  margin-right: 15px;
  margin-top: 150px;
}
.CP_profile_modal > .modal-dialog {
  max-width: 1700px !important;
  margin-left: auto;
  margin-right: auto;
}
.CP_profile_modal_container {
  padding: 18px 12px;
  margin-top: -76px;
  width: 223px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
}
.profileDropdownWrap {
  @include flexBoxJustifyCenter;
  color: transparent;
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 1060;
}
.profileDropdownWrap:hover {
  transform: scale(1.1);
}
.CP_profileitem_wrap {
  box-sizing: border-box;
  margin-bottom: 4px;
  padding: 15px 5px;
  border-radius: 4px;
  height: 19px;
  width: 100%;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 19px;
  @include flexBoxSpaceBetween;
}
.CP_profileitem_wrap:hover {
  background-color: #eceff3;
}

.CP_menu_toggler {
  height: 30px;
  width: 30px;
  @include flexBoxJustifyCenter;
  margin-left: 18px;
  color: #{$mainTextColor};
  font-size: 25px;
  cursor: pointer;
}
.CP_menu_toggler:hover {
  transform: scale(1.1);
}
.CP_menu_wrap {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  background: radial-gradient(circle, #0f1029 0%, #000000 100%);
  z-index: 30;
  .CP_menu_header {
    height: 70px;
    @include flexBoxSpaceBetween;
  }
  .CP_menu_list_wrapper {
    margin-top: 30px;
    width: 100%;
    .CP_menu_ItemWrap {
      @include flexBoxJustifyStart;
      align-items: flex-start;
      text-decoration: none;
      flex-direction: column;
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid #131729;
      button {
        white-space: nowrap;
        width: fit-content;
        border: none;
        cursor: pointer;
        background: transparent;
        color: #{$mainTextColor};
        min-height: 66px;
        font-size: 22px;
        letter-spacing: 0.33px;
        line-height: 26px;
        font-weight: 500;
        padding: 0;
      }
      button:focus,
      button:focus-visible {
        outline: transparent;
      }
    }
    .CP_menu_ItemWrap:hover {
      button {
        color: #{$mainColor};
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .CP_header_main {
    height: 70px;
  }
  @keyframes headerAnimation {
    0% {
      transform: translateY(-70px);
      opacity: 0;
      height: 0px;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
      height: 70px;
    }
  }
  .CP_header_main_bg {
    height: 70px;
  }
  .CP_logo {
    width: 149px;
    margin-top: -8px;
    margin-left: 0px;
  }
  .CP_nav_main_wrap .CP_LoginSignup_wrap .CP_loginButton {
    margin-left: 0px;
  }
  .hideOnMobile {
    display: none;
  }
}
.signUp__button {
  border: 2px solid #0091ff;
  border-radius: 25px;
  padding: 0px 17px !important;
}

.logo__dark {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 26px;
  // width: 108.19px;
  margin-top: -3px;
  margin-left: 16px;
}
