.jn-fx {
	padding-top: 35px;
	&-block {
		padding: 48px;
		border-radius: 16px;
		background: rgba(255, 255, 255, 0.02);
	}
	&-title {
		color: #fff;
		font-size: 32px;
		font-weight: 700;
		line-height: 30px; 
		margin-bottom: 24px;
	}
	&-heading {
		max-width: 740px;
		width: 100%;
		margin-bottom: 60px;
	}
	&-table {
		&-select {
			background: transparent;
			color: #98A2B3;
			border: 0;
			&:focus {
				outline: none;
			}
		}
		&-row {
			display: grid;
			grid-template-columns: 480px 1fr 1fr 1fr;
			grid-gap: 24px;
			align-items: center;
			padding: 20px 24px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.12);
			color: #FFF;
			font-size: 16px;
			b {
				font-weight: 500;
			}
			.grey-span {
				color: #98A2B3;
			}
		}
		.rate-amount {
			display: flex;
			align-items: center;
		}
		.rate-currency {
			display: flex;
			align-items: center;
			&-icon {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				margin-right: 12px;
				flex-shrink: 0;
				flex-grow: 0;
				display: flex;
				overflow: hidden;
				span {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
		.rate-value {
			font-size: 14px;
			display: flex;
			align-items: center;
			margin-left: 12px;
			svg {
				width: 12px;
				height: 12px;
			}
			&.up {
				color: #12B76A;
				svg path {
					stroke: #12B76A;
				}
			}
			&.down {
				color: #F04438;
				svg {
					transform: rotate(180deg);
					path {
						stroke: #F04438;
					}
				}
				
			}
		}
		&-th {
			color: #98A2B3;
			font-size: 14px;
			&.on-desktop {
				@media screen and (max-width: 840px) {
					display: none;
				}
			}
			&.on-mobile {
				@media screen and (min-width: 841px) {
					display: none;
				}
			}
		}
		&-showAll {
			margin-top: 48px;
		}
	}
	.data-loader {
		width: 40px;
		height: 40px;
		border-width: 2px;
		border-color: #3f3f3f;
		border-top-color: var(--btn-background);
	}
}

@media screen and (max-width: 1300px) {
	.jn-fx-table-row {
		grid-template-columns: 350px 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 991px) {
	.jn-fx-table-row {
		font-size: 14px;
		grid-template-columns: 250px 1fr 1fr 1fr;
		padding: 15px 0;
	}
	.jn-fx-table-th {
		font-size: 12px;
	}
	.jn-fx-heading {
		margin-bottom: 40px;
	}
	.jn-fx-table-showAll {
		margin-top: 24px;
	}
}

@media screen and (max-width: 840px) {
	.jn-fx-block {
		padding: 30px;
	}
	.jn-fx-title {
		margin-bottom: 20px;
	}
	.jn-fx-table .rate-amount {
		display: block;
	}
	.jn-fx-table .rate-value {
		margin-left: 0;
	}
	.jn-fx-table-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		grid-gap: 0;
		.grey-span {
			display: none;
		}
	}
	.jn-fx-table .rate-amount {
		display: none;
		text-align: right;
		&.active {
			display: block;
		}
	}
}

@media screen and (max-width: 420px) {
	.jn-fx-block {
		background: transparent;
		padding: 0;
	}
	.jn-fx-title {
		text-align: center;
	}
	.jn-fx-heading .junoClient-text {
		text-align: center;
	}
	.jn-fx .data-loader {
		margin: 0 auto;
	}
}