.juno-money-theme {  
	--modal-body-background: #fff;
	--input-label-color: #344054;
	--icon-color: #667085;
	--input-background: #fff;
	--input-border-color: #D0D5DD;
	--input-placeholder-color: #667085;
	--modal-text-color: #344054;
	--modal-light-text-color: #667085;
	--input-border-radius: 8px;
	--input-font-size: 16px;
	--modal-outer-background: rgba(255, 255, 255, 0.1);
}

.paysu-theme {
	--modal-body-background: #090b10;
	--input-label-color: #D0D5DD;
	--icon-color: #fff;
	--input-background: #1D2939;
	--input-border-color: #344054;
	--input-placeholder-color: #667085;
	--modal-text-color: #fff;
	--modal-light-text-color: #D0D5DD;
	--input-border-radius: 8px;
	--input-font-size: 16px;
}

.paysu-b2c-theme {
	--modal-body-background: #fff;
	--input-label-color: #667085;
	--icon-color: #344054;
	--input-background: #F9FAFB;
	--input-border-color: #D0D5DD;
	--input-placeholder-color: #98A2B3;
	--modal-text-color: #344054;
	--modal-light-text-color: #344054;
	--input-border-radius: 8px;
	--input-font-size: 16px;
}


.theme-modal {
	.alert {
		font-size: 14px;
	}
	&-authenticator {
		max-width: 290px;
		width: 100%;
		display: flex;
		margin: 0 auto 30px;
		img {
			width: 100%;
			display: block;
		}
	}
	.MuiPaper-root {
		background: var(--modal-outer-background);
		border-radius: 24px;
		padding: 32px;
		max-width: 500px;
		width: 100%;
	}
	&-body {
		background: var(--modal-body-background);
		padding: 24px;
		border-radius: 12px;
		border: 1px solid rgba(255, 255, 255, .3);
		&-forgot {
			min-height: 571px;
			display: grid;
			grid-template-rows: auto auto 1fr;
		}
	}
	&-close {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		cursor: pointer;
		.icon-close {
			color: var(--icon-color) !important;
		}
		svg path {
			fill: var(--icon-color);
		}
	}
	&-logo {
		width: 200px;
		height: 50px;
		margin: 0 auto 10px;
		img {
			height: 100%;
			object-fit: contain;
		}
	}
	&-title {
		font-size: 20px;
		margin-bottom: 30px;
		text-align: center;
		color: var(--modal-text-color);
	}
	&-field {
		&-label {
			color: var(--input-label-color);
			font-size: 14px;
			margin-bottom: 7px;
			letter-spacing: 0.6px;
		}
		&.mb {
			margin-bottom: 30px;
		}
		&-input {
			background: var(--input-background);
			border: 1px solid var(--input-border-color);
			border-radius: var(--input-border-radius);
			padding: 11px 14px;
			font-size: var(--input-font-size);
			color: var(--modal-text-color);
			width: 100%;
			&::placeholder {
				color: var(--input-placeholder-color);
			}
		}
	}
	&-forgot {
		margin-top: 16px;
		color: var(--btn-background);
		font-size: 14px;
		cursor: pointer;
	}
	&-submit {
		margin-top: 100px;
	}
	&-account {
		margin-top: 16px;
		text-align: center;
		font-size: 14px;
		color: var(--modal-light-text-color);
		&-btn {
			color: var(--btn-background);
		}
	}
	&-thanks {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		&-title {
			color: var(--modal-text-color);
			font-size: 20px;
			text-align: center;
			margin-bottom: 10px;
		}
		&-icon {
			margin: 0 auto 16px;
		}
	}
	&-checkbox {
		cursor: pointer;
		color: var(--modal-text-color);
		font-size: 16px;
		width: 100%;
		text-align: left;
		display: flex;
		input {
			transform: scale(1.3);
			margin-right: 12px;
			height: 100%;
			position: relative;
			top: 6px;
		}
	}
	&-text {
		color: var(--modal-text-color);
		font-size: 14px;
		&.mb {
			margin-bottom: 15px;
		}
	}
	&-captcha {
		margin-top: 15px;
	}
}

.errorMessage {
	color: rgb(223, 68, 68);
	font-size: 14px;
	margin: 10px 0;
}

.successMessage, .fetchingMessage {
	font-size: 14px;
	margin: 10px 0;
	color: var(--modal-text-color);;
}

@media screen and (max-height: 850px) {
	.theme-modal-submit {
		margin-top: 40px;
	}
	.theme-modal-body-forgot {
		min-height: initial;
	}
}

@media screen and (max-width: 600px) {
	.theme-modal .MuiPaper-root {
		padding: 20px;
	}
	.theme-modal-body-forgot {
		min-height: initial;
	}
	.theme-modal-field-input, .theme-modal-checkbox {
		font-size: 14px;
	}
	.theme-modal-checkbox input {
		top: 4px;
	}
	.theme-modal-submit {
		margin-top: 60px;
	}
	.theme-modal-field.mb {
		margin-bottom: 18px;
	}
}

@media screen and (max-width: 450px) {
	.theme-modal .MuiPaper-root {
		padding: 0;
		background: transparent;
		border-radius: 0;
	}
	.theme-modal-body {
		border-radius: 15px;
	}
	.theme-modal .MuiPaper-root {
		max-width: 100%;
		width: 93%;
		margin: 0 auto;
	}
}

@media screen and (max-width: 400px) {
	.theme-modal-body {
		padding: 24px 20px;
	}
}

.has-aux .rbt-close-content {
    display: none !important
}

.jn-acc-verification-delete{
	width: 422px !important;
}

.jn-acc-verification-message-sent{
	width: 422px !important;
}

.jn-acc-verification {
	&-delete{
		border-top:3px solid #F04438;
	}
	&-message-sent{
		border-top:3px solid #000000;
	}
}

@media screen and (max-width: 768px) {
	.jn-acc-verification-delete {
		width: 422px !important;
		height: 366px !important;
		margin: 10px 10px !important;
	}
	.jn-acc-verification-message-sent {
		width: 422px !important;
		height: 366px !important;
		margin: 10px 10px !important;
	}
	.jnc-acc-verificatio-btn{
		width: 100%;
	}
}