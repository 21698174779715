@import './_mixins.scss';

.sideToolsMenuLayout {
    @include flexBox;
    width: 100%;
    min-height: 100vh;
    color: #{$mainTextColor};
    .sideToolsMenuMain {
        min-height: 100vh;
        width: 300px;
        min-width: 200px;
        background-color: #030409; 
        padding: 112px 0;
        margin-bottom: auto;
        .sideToolsMenu_heading {
            min-height: 27px;
            width: 100%;
            @include flexBoxJustifyStart;
            color: #{$mainTextColor};
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 1.35px;
            line-height: 27px;
            margin-bottom: 27px;
            text-transform: uppercase;
            padding-left: 20px;
        }
        .sideToolsMenu_item {
            box-sizing: border-box;
            min-height: 40px;
            width: 100%;
            @include flexBoxJustifyStart;
            border-right: 3px solid transparent;
            opacity: 0.65;
            margin-bottom: 12px;
            cursor: pointer;
            padding-left: 20px;
        }
        .sideToolsMenu_item_active {
            border-right: 3px solid #{$mainColor};
            background-color: rgba(53, 53, 53, 0.6);
            opacity: 1;
        }
        .sideToolsMenu_item:hover {
            background-color: #353535;
        }
    }
    .merchantToolsContent_main {
        flex-grow: 1;
        width: 100%;
        background-repeat: no-repeat;
        background-image: linear-gradient(to right, rgba(0,145,255,0.05), rgba(255,0,0,0));
    }
    .APIGuide_main {
        background-position-x: 560px;
        background-position-y: -185px;
        background-repeat: no-repeat;
    }
    .tool_main {
        padding: 112px 60px;
        min-height: 100vh;
        width: 100%;
        color: var(--content-text-color);
        .tool_heading {
            width: 100%;
            @include flexBoxJustifyStart;
            min-height: 38px;
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 1.08px;
            line-height: 38px;
            max-width: 1440px;
            margin-right: auto;
            margin-left: auto;
            text-transform: uppercase;
            animation-name: headingAnimation;
            animation-duration: 1s;
        }
        @keyframes headingAnimation {
            0%, 10%{
                color: transparent;
                transform: translateX(30px)
            }
            100% {
                color: var(--content-text-color);
                transform: translateX(0px)
            }
            
        }
        .tools_EH_tableWrap {
            width: 100%;
            max-width: 1440px;
            margin-left: auto;
            margin-right: auto;
            @include flexBox;
            border-bottom: 1px solid #363636;
            .codeText {
                height: 60px;
                width: 100%;
                color: var(--content-text-color);
                font-size: 16px;
                letter-spacing: 1.35px;
                line-height: 27px;
                @include flexBoxJustifyStart;
            }
            .codeTextHeading {
                font-weight: bold;
            }
        }
        .tools_content_wrap {
            width: 100%;
            max-width: 1440px;
            margin-top: 28px;
            margin-left: auto;
            margin-right: auto;
            @include flexBoxSpaceBetween;
            align-items: flex-start;
            .tools_left_part, .tools_right_part {
                max-width: 600px;
                width: 100%;
                .tools_sub_heading {
                    width: 100%;
                    min-height: 50px;
                    color: var(--content-text-color);
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 1.8px;
                    line-height: 27px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #363636;
                }
                .tools_content_text {
                    width: 100%;
                    font-size: 16px;
                    letter-spacing: 1.35px;
                    line-height: 27px;
                    margin-bottom: 30px;
                    .tools_content_link {
                        color: #4A90E2;
                    }
                }
                .SingleParameterWrap {
                    min-height: 90px;
                    width: 100%;
                    @include flexBoxJustifyCenter;
                    flex-direction: column;
                    border-bottom: 1px solid #363636;
                    .SingleParameter_title, .SingleParameter_description {
                        width: 100%;
                        @include flexBoxJustifyStart;
                        color: #888888;
                        font-size: 14px;
                        letter-spacing: 1.35px;
                        line-height: 25px;
                    }
                    .SingleParameter_title {
                        color: var(--content-text-color);
                        font-weight: bold;
                    }
                }
                .tools_code_card {
                    padding: 20px 30.5px;
                    width: 100%;
                    border-radius: 10px;
                    background-color: #000000;
                    margin-top: 50px;
                    .tools_code_card_url {
                        min-height: 50px;
                        width: 100%;
                        color: #{$mainTextColor};
                        font-size: 16px;
                        letter-spacing: 1.35px;
                        line-height: 27px;
                    }
                }
            }
            .tools_left_part {
                margin-right: 30px;
            }
            .tools_right_part {
                margin-left: 30px;
            }
        }
    }
}

.sideToolsMobileMenuMain {
    display: none;
}
.sideToolsMenuLayout .tool_main .tools_content_wrap_margin {
    margin-top: 89.5px;
}

@media only screen and (max-width: 480px) {
    .sideToolsMenuMain {
        display: none;
    }
    .sideToolsMobileMenuMain {
        display: flex;
    } 
    .sideToolsMenuLayout {
        flex-direction: column;
        .tool_main {
            padding: 31px 16px;
            background-image: none !important;
        }
    }
    .sideToolsMobileMenuMain {
        padding-top: 91px;
        width: 100%;
        padding: 91px 16px 0px;
        .sideToolsMobileMenu_Selector {
            padding: 0 14px;
            @include flexBoxSpaceBetween;
            min-height: 40px;
            width: 100%;
            border-radius: 20px;
            background-color: #252525;
            cursor: pointer;
        }
        .sideToolsMobileMenu_Selector:hover {
            background-color: #353535;
        }
    }
    .menuOptionsList {
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 1060;
        min-height: 100vh;
        position: fixed;
        background: #FFFFFF;
        .toolsOverview {
            height: 70px;
            width: 100%;
            background-color: #26293C;
            @include flexBoxSpaceBetween;
            padding-left: 16px;
            padding-right: 16px;
            margin-bottom: 25px;
            color: #{$mainTextColor};
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0.06px;
            line-height: 26px;
            .CloseMenuListIcon {
                font-size: 24px;
                color: #{$mainTextColor};
                cursor: pointer;
            }
        }
        .sideToolsMobileMenu_listWrap {
            padding-left: 16px;
            padding-right: 21px;
            .sideToolsMobileMenu_item {
                @include flexBoxSpaceBetween;
                padding-left: 14px;
                padding-right: 22px;
                min-height: 40px;
                width: 100%;
                border-radius: 20px;
                color: #000000;
                background-color: #F4F4F4;
                border: 1px solid transparent;
                margin-bottom: 10px;
                cursor: pointer;
            }
            .sideToolsMenuMobile_item_active {
                background-color: #FFFFFF;
                color: #{mainColor};
                border: 1px solid #{$mainColor};
            }
            .sideToolsMobileMenu_arrowIcon {
                font-size: 14px;
            }
        }
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_left_part, .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_right_part {
        max-width: initial;
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_left_part {
        margin-right: 0px;
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_right_part {
        margin-left: 0px;
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap {
        flex-direction: column;
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap_margin {
        margin-top: 40px;
    }
    .sideToolsMenuLayout .tool_main .tool_heading {
        height: 24px;
        font-size: 20px;
        letter-spacing: 0.05px;
        line-height: 24px;
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_left_part .tools_content_text, .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_right_part .tools_content_text {
        line-height: 22px;
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_left_part .tools_sub_heading, .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_right_part .tools_sub_heading {
        height: 19px;
        font-size: 16px;
        letter-spacing: 0.04px;
        line-height: 19px;
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_left_part .tools_code_card, .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_right_part .tools_code_card {
        padding: 20px 18px;
    }
    .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_left_part .tools_code_card, .sideToolsMenuLayout .tool_main .tools_content_wrap .tools_right_part .tools_code_card {
        margin-top: 40.5px;
    }
}