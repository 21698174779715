.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .container {
        color: black;
        width: 100%;
        padding: 20px 20px;
        box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;

        h2 {
            font-size: 128px;

            span {
                color: rgb(93, 93, 233);
            }
        }

        .subtext {
            color: black;
            font-size: 24px;
            margin-top: -20px;
        }

        .normal-text {
            color: rgb(44, 44, 44);
            font-size: 14px;
            margin-top: 10px;
        }

        button {
            border-radius: 5px;
            background: rgb(93, 93, 233);
            padding: 16px 20px 16px 20px;
            color: white;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

.maintaincePage {
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: center;
    color: white;
    background: #0C1432;
    height: 100vh;
    width: 100%;
    background-image: url('https://dev.cryptonyte.orghttps://dev.junomoney.org/images/maintenance/backgroundGlow.png');
    background-repeat: no-repeat;
    background-origin: content-box;

    .maintainceContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        margin: auto;

        .maintainceLogo {
            margin-bottom: 95px;
            padding-top: 20px;
        }

        .maintainceContent {
            backdrop-filter: blur(25px) saturate(127%);
            -webkit-backdrop-filter: blur(25px) saturate(127%);
            background-color: rgba(255, 255, 255, 0.04);
            border-radius: 12px;
            border: 1px solid rgba(255, 255, 255, 0.24);
            border-radius: 32px;
            max-width: 600px;
            width: 100%;
            min-height: 500px;
            max-height: 644px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;
            text-align: center;

            .maintainceImage {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .maintainceText {
                color: var(--base-white, #FFF);
                text-align: center;
                font-family: Inter;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 142.023%;
            }

            .maintainceSubtext {
                color: var(--base-white, #FFF);
                font-family: Inter;
                font-size: 26px;
                font-style: normal;
                font-weight: 400;
                line-height: 142.023%;
            }

            @media only screen and (max-width: 600px) {
            
                .maintainceText {
                    font-size: 20px;
                }
    
                .maintainceSubtext {
                    font-size: 18px;
                }
            }
        }
    }
}