.jn-account {
	background: #081428;
	position: relative;
	z-index: 1;
	padding-top: 35px;
	.pd-x {
		padding-left: 64px;
	}
	&-grid {
		display: grid;
		grid-template-columns: 1fr 1.2fr;
		grid-gap: 58px;
		align-items: center;
	}
	&-tablet {
		width: 100%;
		height: auto;
		position: relative;
		align-self: flex-end;
		&.min-601 {
			@media screen and (max-width: 600px) {
				display: none;
			}
		}
		&.max-600 {
			@media screen and (min-width: 601px) {
				display: none;
			}
		}
	}
	&-block {
		padding-top: 58px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		overflow: hidden;
		position: relative;
    border: 1px solid rgba(255, 255, 255, .24);
		border-radius: 20px;
	}
	&-title {
		color: #FFF;
		font-size: 56px;
		font-weight: 700;
		line-height: 120%;
		letter-spacing: -1.6px;
		margin-bottom: 24px;
	}
	&-text {
		font-size: 20px;
		color: #EAECF0;
		line-height: 125%;
	}
	&-heading {
		padding-bottom: 58px;
		&.has-buttons {
			padding-bottom: calc(58px + 79px);
		}
	}
	&-btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background: rgba(0, 0, 0, 0.32);
		backdrop-filter: blur(2px);		
		display: flex;
		align-items: center;
		padding-top: 12px;
		padding-bottom: 12px;
		z-index: 2;
		border-top: 1px solid rgba(255, 255, 255, .32);
		button {
			&:not(:last-child) {
				margin-right: 12px;
			}
		}
	}
	&-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		position: relative;
		align-items: center;
		background: #081428;
		margin-top: 24px;
		&-num {
			color: #FFF;
			text-align: center;
			font-size: 40px;
			font-weight: 700;
			margin-bottom: 32px;
			line-height: 1;
			span {
				color: #28E7C5;
			}
		}
		.junoClient-text {
			text-align: center;
		}
		&-item {
			padding: 56px;
			position: relative;
			z-index: 2;
			height: 258px;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			&:first-child {
				position: relative;
				&:before {
					background-image: url('https://dev.junomoney.org/images/junoclient/account-border-1.svg');
					background-size: 100% 100%;
					left: 1px;
				}
			}
			&:nth-child(3) {
				&:before {
					background-image: url('https://dev.junomoney.org/images/junoclient/account-border-1.svg');
					background-size: 100% 100%;
					transform: scale(-1, 1);
					left: -2px;
				}
			}
			&:nth-child(5) {
				&:before {
					background-image: url('https://dev.junomoney.org/images/junoclient/account-border-2.svg');
					background-size: 100% 100%;
					top: -1px;
				}
			}
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url('https://dev.junomoney.org/images/junoclient/account-grid.svg');
			background-size: 79px 79px;
			background-repeat: repeat;
			opacity: .06;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: radial-gradient(50.00% 50.00% at 50.00% 50.00%, rgba(8, 20, 40, 0.40) 0%, rgba(8, 20, 40, 0.80) 64.80%, #081428 100%);
			z-index: 1;
		}
	}
}

.fade-enter .transition-el {
  opacity: 0;
}
.fade-enter-active .transition-el {
  opacity: 1;
}
.fade-exit .transition-el {
  opacity: 1;
}
.fade-exit-active .transition-el {
  opacity: 0;
}
.fade-enter-active .transition-el,
.fade-exit-active .transition-el {
  transition: opacity 500ms, transform 500ms;
}

@media screen and (max-width: 1550px) {
	.jn-account-title {
		font-size: 48px;
	}
	.jn-account-list:before {
		background-size: 69px 69px;
	}
	.jn-account-list-item:first-child:before {
		left: -1px;
	}
	.jn-account-list-item:nth-child(5):before {
		left: -2px;
	}
	.jn-account-list-item:nth-child(3):before {
		left: -4px;
	}
}

@media screen and (max-width: 1300px) {
	.jn-account .pd-x {
		padding-left: 40px;
	}
	.jn-account-title {
		font-size: 40px;
	}
	.jn-account-block {
		padding-top: 50px;
	}
	.jn-account-heading {
		padding-bottom: 50px;
	}
	.jn-account-list-item {
		height: auto;
		padding: 44px;
	}
	.jn-account-list {
		align-items: initial;
	}
	.jn-account-list:before {
		background-size: 68px 68px;
	}
	.jn-account-list-item:nth-child(3):before {
		left: -3px;
	}
	.jn-account-text {
		font-size: 18px;
	}
}

@media screen and (max-width: 1058px) {
	.jn-account-grid {
		grid-template-columns: 1fr 1.4fr;
	}
}

@media screen and (max-width: 991px) {
	.jn-account-list {
		grid-template-columns: repeat(2, 1fr);
		position: relative;
		margin-top: 0;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 1px;
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.24) 50%, rgba(255,255,255,0) 100%);
		}
	}
	.jn-account-list-item:first-child:before,
	.jn-account-list-item:nth-child(3):before,
	.jn-account-list-item:nth-child(5):before,
	.jn-account-list:before,
	.jn-account-list-item:before {
		display: none;
	}
	.jn-account-list-item {
		display: grid;
		grid-template-columns: 50px 1fr;
		grid-gap: 24px;
		align-items: center;
		padding: 50px 4vw;
		min-height: 156px;
		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(5),
		&:nth-child(4) {
			border-bottom: 1px solid rgba(255, 255, 255, 0.24);
		}
	}
	.jn-account-list-num {
		margin-bottom: 0;
		font-size: 32px;
	}
	.jn-account-list-container {
		width: 100%;
	}
	.jn-account-list .junoClient-text {
		text-align: left;
	}
	
	.jn-account-btns {
		margin-top: -80px;
		justify-content: center;
		
	}
	.jn-account-block {
		border-radius: 16px;
	}
	.jn-account {
		padding-bottom: 0;
	}
	.jn-account-grid {
		grid-template-columns: 1fr;
		grid-gap: 20px;
	}
	.jn-account-heading {
		padding-bottom: 0;
		padding-right: 40px;
	}
	.jn-account-heading.has-buttons {
		padding-bottom: 0;
	}
}

@media screen and (max-width: 720px) {
	.jn-account-block {
		padding-top: 32px;
	}
	.jn-account .pd-x {
		padding-left: 24px;
	}
	.jn-account-heading {
		padding-right: 24px;
	}
	.jn-account-title {
		font-size: 32px;
	}
	.jn-account-text {
		font-size: 16px;
	}
}

@media screen and (max-width: 600px) {
	.jn-account-list {
		grid-template-columns: 1fr;
		&:after {
			display: none;
		}
	}
	.jn-account-list-item {
		min-height: initial;
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.jn-account-heading {
		padding-right: 24px;
		padding-left: 24px;
	}
	.jn-account .pd-x {
		padding-left: 0;
		padding-right: 0;
	}
}

@media screen and (max-width: 379px) {
	.jn-account-btns .junoClient-btn {
		padding-left: 27px;
		padding-right: 27px;
	}
}