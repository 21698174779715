.jnAm-header {
	height: 100px;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 2;
	&-logo {
		width: auto;
		height: 44px;
	}
	&-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
@media screen and (max-width: 430px) {
	.jnAm-header-logo {
		width: auto;
		height: 36px;
	}
}