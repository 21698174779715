@import './_mixins.scss';

.address-container {
    margin-top:75px;
    padding-top:34.5px;
    padding-left:76px;
    padding-right: 76px;
  }
  
  .address-book {
    height: 51px;
    width: 330px;
    color: var(--address-button-color);;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.06px;
    line-height: 30px;
  }
  
  .address-table{
    width:1289px;
  }
  
  .address-name-layout {
    @include flexBox;
    flex-direction:row;
    align-items: baseline;
  }
  
  .address-no-address {
     color: white;
     @include flexBox;
      height: 344.5px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }
  
  .no-address-info-container {
    width:335px;
    @include flexBox;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .nothing-to-display-h {
    height: 19px;
    width: 181px;
    color: var(--address-button-color);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    margin-top: 5px;
  }
  
  .nothing-to-show-header-container {
    @include flexBox;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .save-addresses-you-u {
    height: 57px;
    width: 335px;
    color: var(--address-button-color);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
  .add-address-btn {
    @include flexBox;
      height: 50px;
      width: 401px;
      border-radius: 25px;
      background-color: #{$mainColor};
      flex-direction: row;
      justify-content: space-around;
  }
  
  .add-new-address-btn-text {
    height: 19px;
    width: 133px;
    color: #{$mainTextColor};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
  
  .carbon-add {
    height: 20px;
    width: 20px;
  }
  
  .carbon-next {
    height: 16px;
    width: 16px;
    transform: scaleX(-1);
  }
  
  .add-new-small {
      height: 40px;
      width: 125px;
      border-radius: 25px;
      background-color: #{$mainColor};
      color: #{$mainTextColor};
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .edit-address-btn {
    height: 60px;
    width: 71px;
    color: #0091FF;
    font-size: 15px;
    letter-spacing: 0.04px;
    line-height: 20px;
    text-align: center;
  }
  
  
  .add-address-modal-button {
      height: 50px;
      width: 401px;
      border-radius: 30px;
      background-color: #{$mainColor};
      border:none;
      color:var(--address-modal-submit)
  }

  .remove-address-go-back-btn {
      height: 50px;
      width: 340px;
      border-radius: 30px;
      color: white;
      background-color: #191A23;
  }

  .remove-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  .remove-address-modal {
    height: 391px;
    width: 460px;
    border-radius: 10px;
    background-color: #191A23;
  }
  .remove-ip-address-model {
    height: fit-content;
  }
  
  .remove-address-header {
    height: 30px;
    width: 182px;
    color: #{$mainTextColor};
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    width:100%;
    margin-top: 23px;
  }
  
  .remove-address-header-child {
    height: 44px;
    width: 324px;
    color: #{$mainTextColor};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    margin-top: 11px;
  }
  
  
  .remove-btn-image {
      width:80px;
      height:80px;
      position: absolute;
      top: -13%;
      margin-left: 37%;
  }

  .make-overflow-vis {
      overflow: visible;
  }

  .remove-address-model-dialog {
    .css-uhb5lp {
      background-color: var(--signup-model-bg);
      overflow: visible;
    }
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
      max-height: none;
      background-color: var(--signup-model-bg);
      overflow: visible;
    }
  }

  .remove-button-margin {
    margin-top: 40px;
  }

  .align-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .remove-address-modal-input {
    box-sizing: border-box;
    height: 60px;
    width: 340px;
    border: 1px solid #3E3E3E;
    border-radius: 30px;
    background-color: #21232E;
  }

  .remove-flex-input {
    display: flex;
    justify-content: center;
  }

  .remove-container-width {
    width: 340px;
  }

  .edit-address-btn:hover {
    cursor: pointer;
}

.add-new-small {
  cursor: pointer;
}

.add-left-margin-to-heading {
  margin-left: 32%;
}

.center-edit-submit-button {
    display: flex;
    justify-content: center;
}


.remove-button-bitcoin-ctr {
  box-sizing: border-box;
  height: 60px;
  width: 340px;
  border: 1px solid #3E3E3E;
  border-radius: 30px;
  background-color: #21232E;
  color:white;
  font-size: 15px;
  letter-spacing: 1.16px;
  line-height: 20px;

}

.set-button-width {
  width: 401px;
}

.remove-button_border {
  border:none
}

.center-add-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copy-button-margin {
  margin-left: 12px;
}


.add-address-btn:hover {
  cursor: pointer;
}

.rotate-back {
  transform: rotateY(360deg);
}

.set-dropdown-width {
  width: 401px;
}

.address-input-background {
  background-color: var(--address-input-background);
}

.delete-button-dynamic-color{
  color: var(--address-button-color);
}

.nothing-to-show-dynamic-background {
  color: var(--no-address-image-background);
}

.remove-button-width {
  width: 340px;
}

.add-new-address-empty-width {
  width: 335px;
}



.copy-text.address::before {
  left: -30px;
  right: unset;
}
.copy-text.address::after {
  left: 5px;
  right: unset;
}
.copy__icon:hover {
  cursor: pointer;
}


  @media screen and (min-width: 1200px) {
    .address-table {
      width: auto;
    }
  }
