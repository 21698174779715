@import './_mixins.scss';

.footer {
	background: radial-gradient(98.63% 98.68% at 50% 0%, #0F1029 0%, #000000 100%);
	padding: 60px 0;
	&__buy {
		&_buttons {
			margin-top: 20px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			position: relative;
			left: -15px;
			.theme-btn {
				margin: 6px;
			}
		}
	}
	&__divider {
		width: 100%;
		height: 1px;
		background: rgba(255, 255, 255, 0.1);
		margin: 60px 0;
	}
	&__bottom {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		grid-gap: 70px;
		margin-bottom: 24px;
		align-items: center;
	}
	&__logo {
		width: 190px;
		height: auto;
	}
	&__copyright {
		color: #E0E0E0;
		font-size: 14px;
		line-height: 150%;
	}
	&__socials {
		display: flex;
		align-items: center;
		a {
			width: 40px;
			height: 40px;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px;
			border-radius: 50%;
			transition: .15s;
			&:hover {
				background: rgba(255, 255, 255, 0.1);
			}
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
}

@media screen and (max-width: 670px) {
	.footer {
		padding: 40px 0;
	}
	.footer__buy .section-text {
		text-align: center;
	}
	.footer__buy_buttons {
		left: 0;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 10px;
		.theme-btn {
			margin: 0;
			padding: 12px 10px;
		}
	}
	.footer__divider {
		margin: 40px 0;
	}
	.footer__bottom {
		grid-template-columns: 1fr;
		grid-gap: 10px;
		justify-items: center;
	}
	.footer__copyright {
		text-align: center;
	}
}

@media screen and (max-width: 537px) {
	.footer__buy_buttons {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px 6px;
		p {
			font-size: 12px;
		}
	}
	.footer__buy_buttons .theme-btn {
		padding: 10px;
	}
	.footer__socials a:not(:last-child) {
		margin-right: 20px;
	}
}



//---
.b2c-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  opacity: 0.99;
  background: var(--footer-bg-color);
  box-shadow: 0 -2px 29px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 0px 20px;
}
.b2c-footer-logo {
    margin-bottom: 20px;
    img{
      max-height: 55px;
    }
}
.b2c-footer-text {
  color: #626262;
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 22px;
  text-align: center;
}

.b2c-footer-special-text {
  color: #1d60dd;
  text-decoration: underline;
}

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .b2c-footer-logo {
    img{
      height: 40px;
      width: 205px;
    }
  }
  .b2c-footer-text {
    font-size: 14px;
    letter-spacing: 0.04px;
    line-height: 19px;
  }

}

