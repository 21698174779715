.jncTable {
	// border-radius: 15px;
	overflow: hidden;
	.jzrolw {
		// border-bottom-left-radius: 0;
		// border-bottom-right-radius: 0;
	}
	&:not(.no-data) {
		// min-height: 500px;
		.jncTable-minHeight {
			border-radius: 15px;
			overflow: hidden;
		}
		// @media screen and (max-width: 991px) {
		// 	min-height: initial;
		// }
	}
	&.no-data {
		.jncTable-minHeight {
			padding: 24px;
			background: #fff;
			border-radius: 15px;
			min-height: 400px;
			@media screen and (max-width: 767px) {
				border-radius: 0;
			}
		}
		@media screen and (max-width: 991px) {
			padding: 24px;
			background: #fff;
		}
		@media screen and (max-width: 767px) {
			padding: 0 0 20px 0;
		}
	}
	&.is-dashboard {
		background: #fff;
		padding: 24px;
		.rdt_TableHeadRow {
			min-height: auto;
			padding-bottom: 10px;
		}
		@media screen and (max-width: 991px) {
			padding: 5px;
		}		
	}
	.rdt_TableHeadRow {
		flex-grow: 0 !important;
		min-width: 200px;
		justify-content: space-between;
	}
	.rdt_TableCell {
		font-size: 14px;
		flex-grow: 0 !important;
		min-width: 200px;
		&:first-of-type {
			padding-left: 16px;
		}
	}
	&-repeat {
		display: flex;
	}
	&-amount {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 10px;
		align-items: center;
		width: 100%;
	}
	&-pdLeft {
		padding-left: 16px;
	}
	&-minHeight {
		// min-height: 323px;
		min-height: auto;
		// border-radius: 15px;
	}
	&-holding {
		width: 220px;
		&-bar {
			background: #D0D5DD;
			border-radius: 8px;
			width: 100%;
			height: 6px;
			position: relative;
			margin-top: 4px;
		}
		&-progress {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background: #12B76A;
			border-radius: 8px;
			width: 0;
		}
	}
	&-currency {
		display: flex;
		align-items: center;
		img {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			object-fit: cover;
			margin-right: 10px;
		}
	}
	&-actions {
		display: flex;
		align-items: center;
		&-btn {
			width: 40px;
			height: 40px;
			// border: 1px solid #D0D5DD;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			transition: .15s;
			&:hover {
				background: #eeeff2;
				span {
					opacity: 1;
					visibility: visible;
				}
			}
			span {
				display: block;
				background: #344054;
				pointer-events: none;
				border-radius: 8px;
				top: -40px;
				position: absolute;
				height: 36px;
				font-size: 12px;
				color: #fff !important;
				text-align: center;
				padding: 8px 12px;
				white-space: nowrap;
				transition: .15s;
				opacity: 0;
				visibility: hidden;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					bottom: -6px;
					margin: 0 auto;
					width: 0;
					height: 0;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 6px solid #344054;
				}
			}
			svg {
				height: 15px;
				width: auto;
			}
			p {
				text-transform: uppercase;
				color: #344054;
				font-weight: 600;
				font-size: 14px;
			}
			&:not(:last-child) {
				margin-right: 8px;
			}
		}
	}
	&-priceChange {
		color: #12B76A;
		font-size: 14px;
	}
	.kubBPN {
		svg {
			fill: #344054;
		}
		&:hover:not(:disabled) {
			background: #eeeff2;
		}
		&:focus {
			background: transparent;
		}
	}
	&-status {
		padding: 4px 12px;
		border-radius: 16px;
		text-align: center;
		font-weight: 500;		
		font-size: 14px;
		&.na, &.created {
			color: #344054;
			background: rgb(236, 236, 236);
		}
		&.success, &.updated , &.completed {
			background: #ECFDF3;
			color: #027A48;
		}
		&.pending , &.processing {
			background: #F2F4F7;
			color: #344054;
		}
		&.witheld {
			color: #B54708;
			background: #FFFAEB;
		}
		&.rejected, &.error ,&.failed{
			background: #FEF3F2;
			color: #B42318;
		}
	}
	&-inputs {
		display: flex;
		// align-items: center;
		min-height: 16px;
		margin-bottom: 10px;
		position: relative;
		z-index: 2;
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 20px;
		input {
			// max-width: 225px;
			width: 100%;
		}
		&-search {
			background: #F2F4F7;
			border: 1px solid #EAECF0;
			color: #667085;
			min-width: 300px;
		}
		.rbt-input {
			box-shadow: none !important;
			background: #fff !important;
			border: 1px solid #D0D5DD !important;
		}
		&-btn {
			text-align: left;
			padding-right: 36px;
			background: #fff !important;
			border: 1px solid #D0D5DD;
			box-shadow: none !important;
			span {
				color: var(--input-placeholder-color);
			}
		}
		&-btnGroup {
			// min-width: 225px;
			z-index: 2;
		}
		&-group {
			position: relative;
			&:not(.no-limit) {
				max-width: 320px;
			}
			input {
				padding-right: 36px;
			}
			&-icon {
				position: absolute;
				width: 14px;
				height: 14px;
				top: 0;
				bottom: 0;
				margin: auto 0;
				right: 15px;
				&.clear-icon {
					width: 17px !important;
					height: 17px !important;
					cursor: pointer;
					right: 13px;
					path {
						fill: #667085;
					}
				}
			}
		}
	}
	&-type {
		display: flex;
		align-items: center;
		.svg-block {
			background: #EAECF0;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 10px;
			border-radius: 50%;
			flex-shrink: 0;
			flex-grow: 0;
			svg {
				width: 50%;
				height: 50%;
				object-fit: contain;
			}
		}
	}
}

.jncEmptyData {
	display: flex;
	flex-direction: column;
	align-items: center;
	svg {
		max-width: 153px;
		width: 100%;
		height: auto;
	}
	&__text {
		font-size: 16px;
		text-align: center;
		@media screen and (max-width: 767px) {
			font-size: 14px;
		}
	}
}

.jncTableM {
	background: #fff;
	.transactions-style {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 15px;
		align-items: center;
		&-amount {
			text-align: right;
			.d-flex {
				justify-content: flex-end;
			}
		}
	}
	&-btn {
		width: 100%;
		text-align: left;
		padding-top: 13px;
		padding-bottom: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&.active {
			.jncTableM-Nstatus-text {
				opacity: 0;
			}
		}
	}
	
	&-Nstatus {
		&-left {
			display: flex;
			align-items: center;
			margin-right: 15px;
			img {
				width: 30px;
				height: 30px;
				object-fit: cover;
				border-radius: 50%;
				margin-right: 15px;
			}
		}
		&-right {
			display: flex;
			align-items: center;
			text-align: right;
		}
	}
	
	&-text {
		font-size: 14px;
		color: #344054;
		span {
			color: #98A2B3;
			&.transaction-date {
				font-size: 13px;
			}
		}
		&.address {
			max-width: 120px;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-left: auto;
		}
	}
	&-expand {
		display: none;
		&.active {
			display: block;
		}
		&-icon {
			svg {
				margin-left: 25px;
				width: 10px;
				height: 10px;
				object-fit: contain;
			}
			.close {
				display: none;
			}
			&.active {
				.close {
					display: block;
				}
				.arrow {
					display: none;
				}
			}
		}
		.jncTable-actions {
			margin: 12px auto;
			justify-content: center;
		}
	}
	&-group {
		display: grid;
		padding: 12px 0;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 15px;
		align-items: center;
		&:first-child {
			padding-top: 0;
		}
		&:not(:last-child) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		}
		&:last-child {
			padding-bottom: 0;
		}
 		p {
			@extend .jncTableM-text;
			&:last-child {
				text-align: right;
			}
		}
	}
}

//Only table component mobile
.jncTable-custom-heading {
	button {
		color: #667085;
		font-size: 12px;
		font-weight: 500;
		padding-right: 5px;
	}
	select {
		border: none;
		background: #fff;
		color: #667085;
		font-size: 12px;
		font-weight: 500;
	}
}

@media screen and (max-width: 991px) {
	.jncTable {
		.jNIkof {
			overflow: auto;
		}
		&-status {
			margin-bottom: 3px;
		}
	}
	.jncTable-noData {
		font-size: 14px;
	}
	
	
}

@media screen and (max-width: 767px) {
	.jncTable-noData {
		margin-top: 10px;
	}
	.jncTable {
		border-radius: 0;
	}
}