$fontFamily: "Open Sans";
$mainColor: #0091ff;
$mainHoverColor: #006fc4;
$mainTextColor: #ffffff;
$mainColorLight: #9ec0ff;
$mainBgColor: #171723;
$contentTextColor: #23262f;
$merchentTextColorDark: #000000;
$signupModelbackGroundDark: #191a23;

@mixin flexBox {
  display: flex;
  align-items: center;
}
@mixin flexBoxSpaceBetween {
  @include flexBox;
  justify-content: space-between;
}
@mixin flexBoxJustifyCenter {
  @include flexBox;
  justify-content: center;
}
@mixin flexBoxJustifyStart {
  @include flexBox;
  justify-content: flex-start;
}
@mixin flexBoxJustifyEnd {
  @include flexBox;
  justify-content: flex-end;
}
@mixin flexBoxJustifySpaceEvenly {
  @include flexBox;
  justify-content: space-evenly;
}

.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.text-center {
  text-align: center;
}