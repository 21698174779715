$actionBtnTransition: all 0.4s;

.jncSidebar {
    width: 80px;
    transition: width 0.3s ease-in-out;
    padding: 20px 15px;
    overflow-x: hidden;
    &.hide-on-profile {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    &.opened {
        @media screen and (min-width: 992px) {
            width: 210px;
            .jncSidebar__menu-link {
                justify-content: flex-start;
                p {
                    opacity: 1;
                }
            }
            .jncSidebar__currs-bottom {
                opacity: 0;
            }
            .jncSidebar__currs-img p {
                opacity: 1;
            }
        }
        @media screen and (min-width: 1201px) {
            width: 240px;
        }
    }
    &__menu {
        &-link {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            height: 50px;
            border-radius: 6px;
            border: 0;
            border-left: 2px solid transparent;
            color: black !important;
            overflow: hidden;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            &.active {
                background: #eaecf0;
                border-left-color: var(--theme-color);
                svg {
                    path {
                        &:not(.fill) {
                            stroke: var(--theme-color);
                        }
                        &.fill {
                            fill: var(--theme-color);
                        }
                    }
                }
            }
            &:hover {
                background: #eaecf0;
            }
            svg {
                width: 24px;
                height: auto;
                flex-shrink: 0;
                flex-grow: 0;
            }
            p {
                margin-left: 8px;
                position: absolute;
                left: calc(36px + 8px);
                display: flex;
                align-items: center;
                opacity: 0;
                transition: 0.3s;
                white-space: nowrap;
            }
        }
    }
    &__currs {
        display: flex;
        flex-direction: column;
        margin-top: 13px;
        &-btn {
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-radius: 6px;
            overflow: hidden;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            img {
                width: 32px;
                height: 32px;
                object-fit: cover;
                margin-left: 9px;
            }
        }
        &-img {
            position: relative;
            width: 100%;
            p {
                position: absolute;
                top: 0;
                left: calc(36px + 19px);
                bottom: 0;
                margin: auto 0;
                font-size: 16px;
                opacity: 0;
                transition: 0.3s;
                width: 100%;
                text-align: left;
                display: flex;
                align-items: center;
                white-space: nowrap;
            }
        }
        &-bottom {
            text-align: center;
            margin-top: 4px;
            width: 100%;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    &__actions {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;

        .last-child {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        &-btn {
            padding: 32px;
            border-radius: 12px;
            border: 1px solid #efefef;
            background: #f9fafb;
            text-align: left;
            position: relative;
            overflow: hidden;
            &:last-child {
                grid-column-start: 1;
                grid-column-end: 3;
            }
            &:before {
                content: '';
                position: absolute;
                width: 50%;
                // height: 100%;
                padding-bottom: 50%;
                transform: scale(0); //1.5
                border-radius: 50%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                transform-origin: 50% 50%;
                background: #6366f1;
                transition: $actionBtnTransition;
                opacity: 0;
            }
            &:hover {
                @media (hover: hover) {
                    &::before {
                        transform: scale(2.2);
                        opacity: 1;
                        background: var(--theme-color);
                    }
                    .jncActions__icon {
                        background: #fff;
                    }
                    .jncSidebar__actions-title,
                    .jncSidebar__actions-text {
                        color: #fff;
                    }
                }
            }
            > * {
                position: relative;
                z-index: 1;
            }
        }
        &-title {
            font-size: 18px;
            font-weight: 500;
            margin-top: 16px;
            transition: $actionBtnTransition;
            color: #000;
        }
        &-text {
            color: #475467;
            margin-top: 16px;
            position: relative;
            transition: all 0.3s;
        }
    }
    &__currHeader {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 24px;
        align-items: center;
        font-family: var(--textFont);
        line-height: 1;
        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 50%;
        }
        &-balance {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        &-percent {
            color: #475467;
            font-weight: 400;
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 991px) {
	.jncSidebar {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 70px;
		padding: 6px;
		background: #fff;	
		z-index: 10;	
	}
	.jncSidebar__menu {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		height: 100%;
		
		.jncSidebar__menu-link:not(:last-child) {
			margin-bottom: 0;
		}
		p {
			display: none;
		}
		&-link {
			border-left: 0;
			border-bottom: 2px solid transparent;
			height: 100%;
			display: flex;
			justify-content: center;
			&.active {
				border-left-color: initial;
				border-bottom-color: var(--theme-color);
			}
		}
		
	}
	.crypto-menu{
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 767px) {
    .jncSidebar__currHeader img {
        width: 40px;
        height: 40px;
    }
    .jncSidebar__currHeader {
        grid-gap: 14px;
    }
    .jncSidebar__actions {
        display: flex;
        grid-gap: 0;
        overflow-y: auto;
    }
    .jncSidebar__actions-btn {
        width: 20%;
        min-width: 120px;
        padding: 16px;
        min-height: 144px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:not(:last-child) {
            margin-right: 4px;
        }
    }
    .jncSidebar__actions-title {
        font-size: 14px;
        margin-top: 24px;
    }
    .jncSidebar__actions .last-child {
        justify-content: flex-start;
        align-items: flex-start;
    }
    .jncSidebar__currHeader-balance {
        font-size: 18px;
    }
}
