.jncStatements { 
	&:not(.is-dashboard) {
		.jncTable-pdLeft,
		.hRpacz:first-of-type .rdt_TableCol_Sortable {
			padding-left: 0;
		}
		.rdt_TableCol {
			flex-grow: 0 !important;
			min-width: 200px;
		}
		.rdt_TableCol[data-column-id="1"],
		.rdt_TableCell[data-column-id="1"] {
			padding-left: 0;
		}
	}
	
	&.is-dashboard {
		border-radius: 15px;
		overflow: hidden;
		@media screen and (max-width: 767px) {
			border-radius: 0;
		}
		.rdt_TableCol {
			flex-grow: 0 !important;
			min-width: 200px;
			flex-shrink: 1 !important;
			flex-basis: 1 !important;
		}
		.rdt_TableCol[data-column-id="1"],
		.rdt_TableCell[data-column-id="1"] {
			padding-left: 0;
		}
		.jncTable-minHeight {
			border-radius: 0;
		}
		.jncTable {
			min-height: auto;
		}
	}
	
}

.jncStatementsModal {
	.jncModal__field-currency {
		margin-bottom: 50px !important;
	}
}

@media screen and (max-width: 991px) {
	.jncStatements.is-dashboard {
		.jncTableM-block:last-of-type {
			border-bottom: 0;
			.jncTableM-btn {
				padding-bottom: 0;
			}
		}
		.jncTableM-block:first-of-type {
			.jncTableM-btn {
				padding-top: 0;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.jncStatements.is-dashboard .jncTableM-wrap {
		padding: 0;
	}
}
